import {BodyParameters, FeedbackMessage, QueryParameters, UUID} from "../ui_general/GeneralInterfaces";
import {ApiEndPoints, BackendURLPaths, FeedbackMessageTypes, HttpMethods, LabelTextMessageTypes} from "../Settings";
import {
    buildApiUrl, createErrorFeedbackMessage
} from "./GeneralUtilities";
import {getBaseTokenNonce, getCredTokenNonce, getXSRFToken} from "./Security";


/**
 * This class defines a fetch controller that is used to issue fetch commands to the backend. It mainly provides
 * the processFetchRequest routine which handles such requests. Moreover, this controller provides functionality to
 * cancel all pending fetch requests emitted under this controller, for instance, in case that a user navigates away
 * from a given view that issued fetch requests.
 */
export class FetchControl {
    /** Holds an instance of an AbortController which allows to abort all pending fetch requests. */
    private readonly abort_controller: AbortController;
    /** Holds a record of pending fetch requests identified by a running number together with the request time*/
    private readonly pending_fetch_requests: Record<number, Date>;
    /** Holds the number of fetch requests that have been emitted. */
    private running_fetch_requests_identifier: number;


    /**
     * Constructor. Initializes abort controller that can be used to abort all pending fetch requests. Also, the
     * constructor initializes the control variables of pending fetch requests.
     */
    constructor() {
        this.abort_controller = new AbortController();
        this.pending_fetch_requests = {};
        this.running_fetch_requests_identifier = 0;
    }


    /**
     * This routine cancels all pending fetch requests.
     */
    cancelAllPendingFetchRequests() {
        this.abort_controller.abort();
    }


    /**
     * Appends the record of pending fetch requests with a new entry using private variable
     * running_fetch_requests_identifier to construct a consecutive series of identifiers. The record also contains
     * the time of the emitted fetch request.
     * @returns The identifier of the new record entry.
     */
    registerPendingFetchRequest(): number {
        const fetch_request_nr = this.running_fetch_requests_identifier + 1;
        this.running_fetch_requests_identifier = this.running_fetch_requests_identifier + 1;
        this.pending_fetch_requests[fetch_request_nr] = new Date();
        return fetch_request_nr;
    }


    /**
     * Removes the entry with the given identifier from the record of pending fetch requests.
     * @param fetch_request_identifier Identifier of the pending fetch request.
     */
    removePendingFetchRequest(fetch_request_identifier: number) {
        if (fetch_request_identifier in this.pending_fetch_requests) {
            delete this.pending_fetch_requests[fetch_request_identifier];
        }
    }


    /**
     * Returns the number of pending fetch requests.
     */
    getNumberPendingFetchRequests(): number {
        return Object.entries(this.pending_fetch_requests).length;
    }


    /**
     * This routine is used whenever a fetch request needs to be invoked to communicate with the API-server.
     * It gathers the xsrf token and the relevant token nonce (if available) and includes these in every request in the
     * request header. The function allows to include query-string parameters, request body parameters and a single file
     * (optionally). The content-type of the request is application/json unless a file is specified. In that case, the
     * content-type is not explicitly set - resulting in multipart/form-data. The routine catches all errors and
     * throws a standardized FeedbackMessage in case of exception.
     *
     * @param api_endpoint End-point address of rest API, for instance: /api/login
     * @param query_parameters Optional query-string parameters to be included in request URL, for instance:
     * {model_id: 1}
     * @param rollback_point_id Identifier of the rollback point of the requested data. If specified, the UUID of the
     * rollback point is added to the query string. This is a convenience functionality. Alternatively, the rollback
     * point can also be provided directly in the query_parameters parameter. If not specified or undefined,
     * no rollback point is not added to the query string.
     * @param method HTTP request method to be used in restful API call, for instance: "GET"
     * @param body Optional parameters to be included in the request body of API call, for instance: {selected: true}.
     * UUIDs are automatically encoded as strings.
     * @param file Optional file to be uploaded along with the restful API call.
     * @returns Returns a Promise object that can be used to process the server response after it has been received.
     */
    requestFetchPromise(api_endpoint: ApiEndPoints,
                        method: HttpMethods,
                        query_parameters: QueryParameters,
                        rollback_point_id?: UUID,
                        body?: BodyParameters,
                        file?: File): Promise<any> {
        // Fetch XSRF token and token nonce
        const xsrf_token = getXSRFToken();
        let token_nonce = getBaseTokenNonce();
        if (api_endpoint.startsWith(BackendURLPaths.CRED)) token_nonce = getCredTokenNonce();

        // Initialization request with desired http method
        let request_init: RequestInit = {method: method};

        // Set request headers (for file uploads, form data is sent instead of application/json)
        let request_headers: Record<string, string> = {'accept': 'application/json'}
        if (xsrf_token) request_headers['xsrf-token'] = xsrf_token;
        if (token_nonce) request_headers['token-nonce'] = token_nonce;
        if (!file) request_headers['Content-Type'] = 'application/json';
        request_init.headers = new Headers(request_headers);

        // Set the cookie sending policy. In production, only send cookies to same origin, in development, the cookie
        // is always sent.
        request_init.credentials = (process.env.REACT_APP_DEBUGGING ? 'include' : 'same-origin')

        // Set abort controller signal
        // TODO: Test and repair abort signal mechanism
        // request_init.signal = this.abort_controller.signal;

        // Construct api URL including query-parameters. If rollback_point_id is specified, it is incorporated.
        const url: string = buildApiUrl(
            api_endpoint,
            !rollback_point_id ? query_parameters : {
                ...query_parameters,
                rollback_point_id: rollback_point_id
            }
        );

        // Construct request body. If file is specified, form data is sent.
        if (file) {
            const formData = new FormData();
            if (body) {
                for (const [key, value] of Object.entries(body)) {
                    if (typeof value !== 'string')  {
                        throw createErrorFeedbackMessage(LabelTextMessageTypes.MALFORMED_FETCH_REQUEST);
                    }
                    formData.append(`${key}`, value);
                }
            }
            formData.append('file', file);
            request_init.body = formData;
        } else {
            if (body) request_init.body = JSON.stringify(body);
        }

        // Variable used to transfer the http status code from one promise to the following.
        let response_status_code: number | undefined = undefined;

        // Instantiating and returning the fetch request
        const fetch_request_identifier = this.registerPendingFetchRequest();
        return fetch(
            url,
            request_init
        ).then(response => {
            response_status_code = response.status;
            return response.json();
        }).then(response_body => {
            this.removePendingFetchRequest(fetch_request_identifier);
            if (!response_status_code || response_status_code < 200 || response_status_code >= 300) {
                let fetch_error: FeedbackMessage = {...response_body, message_type: FeedbackMessageTypes.ERROR };
                fetch_error.http_code = response_status_code;
                throw fetch_error;
            }

            return response_body;
        }).then(response_body => response_body,
                reason => {
                    this.removePendingFetchRequest(fetch_request_identifier);
                    // This is the error handling for any exception thrown in the fetch request. It is checks if reason
                    // is a feedback message. In this case, the feedback message is thrown. Otherwise, a generic network
                    // issue is assumed.
                    if ('message_type' in reason && 'message_text' in reason) throw reason;
                    if ('message_type' in reason && 'detail' in reason) {
                        reason = {...reason,
                            message_type: FeedbackMessageTypes.ERROR,
                            message_text: JSON.stringify(reason.detail)
                        }
                        throw reason;
                    }
                    throw createErrorFeedbackMessage(LabelTextMessageTypes.UNKNOWN_NETWORK_ERROR);
                })
    }

}
