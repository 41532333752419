/**
 * Defines the supported user languages.
 */
export enum UserLanguages {
    EN = "EN",
    DE = "DE",
    ES = "ES",
    FR = "FR",
}


/**
 * Defines the supported model types that can be handled by the system.
 */
export enum RiskModelTypes {
    PD = "PD",
    LGD = "LGD",
    CCF = "CCF",
}


/**
 * Defines the colors used in the system
 */
export enum ColorPalette {
    PD_MODEL = "rgb(200, 200, 255)",
    LGD_MODEL = "rgb(255, 200, 200)",
    CCF_MODEL = "rgb(255, 245, 200)",
    IS_BACKTESTING = "rgb(80, 80, 255)",
    OOS_BACKTESTING = "rgb(255, 80, 80)",
    IS_BACKTESTING_TRANSPARENT = "rgba(80, 80, 255, 0.5)",
    OOS_BACKTESTING_TRANSPARENT = "rgba(255, 80, 80, 0.5)",
    DISTRIBUTION = "rgb(80, 80, 255)",
    DEFAULT_RATE = "rgb(255, 80, 80)",
    DISTRIBUTION_TRANSPARENT = "rgba(80, 80, 255, 0.5)",
    DEFAULT_RATE_TRANSPARENT = "rgba(255, 80, 80, 0.5)",
    ROC_CURVE = "rgb(255, 80, 80)",
    ROC_REFERENCE_LINE = "rgb(200, 200, 200)",
}


/**
 * Defines token types, such as access token, token for sign-up, etc...
 * */
export enum TokenTypes {
    ADMIN_BASE = 'M',
    INTERNAL_BASE = 'I',
    BASE = 'E',
    SIGNUP = 'S',
    PASSWORD_RESET = 'P',
    OTP_GENERATOR_RESET = 'O',
    ADMIN_ACCESS = 'A',
    ACCESS = 'T',
    REFRESH = 'R',
    XSRF = 'X',
}


/**
 * Holds URL paths relative to base URL for relevant services on the backend.
 */
export enum BackendURLPaths {
    ADMIN = '/api/admin',
    CRED = '/api/cred',
    LOGIN = '/api/login',
    INTERNAL = '/api/internal',
    EXTERNAL = '/api/external',
}


/**
 * Holds the api routes available on the backend server
 */
export enum ApiEndPoints {
    LOGIN = '/api/login/login',
    RISK_MODEL = '/api/external/risk_model',
    RISK_MODEL_FILE_UPLOAD = '/api/external/risk_model_file_upload',
    RISK_MODEL_FILE_UPLOAD_ASSOCIATION = '/api/external/risk_model_file_upload_association',
    RISK_MODEL_FILE_COLUMN_MAPPING = '/api/external/risk_model_file_column_mapping',
    RISK_MODEL_RDS_DEFINITION = '/api/external/risk_model_rds_definition',
    STANDARD_CHOICES_INT = "/api/external/standard_choices_int",
    STANDARD_CHOICES_STR = "/api/external/standard_choices_str",
    ANALYSIS_UNIVARIATE_METRICS = "/api/external/analysis_univariate_metrics",
    ML_MODEL_LEARNERS = "/api/external/ml_model_learners",
    ML_MODEL_ANALYSIS = "/api/external/ml_model_analysis",
}


/**
 * Holds the url paths of the different views on the frontend.
 */
export enum FrontendURLPaths {
    LANDING_PAGE = '/',
    LOGIN = "/login",
    MODEL_SELECTION = '/model_selection/overview',
    FILE_UPLOAD = "/file_upload/overview",
    RDS_CONSTRUCTION = "/rds_construction/overview",
    UNIVARIATE_ANALYSIS = "/univariate_analysis/overview",
    MULTIVARIATE_ANALYSIS = "/multivariate_analysis/overview",
}


/**
 * Holds the url paths of the images used in the frontend.
 */
export enum ImageURLs {
    CREATE_PD_MODEL = '/logo512.png',
    CREATE_LGD_MODEL = '/logo512.png',
    CREATE_CCF_MODEL = '/logo512.png',
    LOAD_MODEL = '/logo512.png',
    FILE_UPLOAD = '/logo512.png',
}


/**
 * This enumerator list the types of feedback messages to be displayed on screen to provide feedback to the user.
 */
export enum FeedbackMessageTypes {
    ERROR = 'Error',
    WARNING = 'Warning',
    INFO = 'Info',
    SUCCESS = 'Success',
}


/**
 * This enumerator list the time spans in seconds for which feedback messages shall be displayed on screen to provide
 * feedback to the user. The display times can depend on the type of the feedback message as detailed here.
 */
export enum FeedbackMessageDisplayTimes {
    ERROR = 12,
    WARNING = 8,
    INFO = 12,
    SUCCESS = 4,
}


/**
 * This enumerator lists the http methods that are used for communicating with the backend api server.
 */
export enum HttpMethods {
    GET = 'GET',
    PUT = 'PUT',
    POST = 'POST',
    DELETE = 'DELETE',
}


/**
 * This enumerator lists all available id types that can be used for navigating to views on the frontend.
 */
export enum IDTypes {
    RISK_MODEL = 'risk_model_id',
    ROLLBACK_POINT = 'rollback_point_id',
    FILE = 'file_id',
}


export enum ValidationSchemes {
    REQUIRED = "Required",
    NUMBER = "Number",
    INTEGER = "Integer",
    DATE = "DATE",
    NON_NEGATIVE = "MIN_0",
    NON_POSITIVE = "MAX_0",
    MIN_1 = "MIN_1",
    MAX_LENGTH_6 = "MAXLENGTH_6",
    MIN_LENGTH_6 = "MINLENGTH_6",
}


export enum TextfieldInputTypes {
    DATE = "date",
    EMAIL = "email",
    PASSWORD = "password",
    TEXT = "text",
    NUMBER = "number",
}


/**
 * Holds possible choices for mime types of files that can be processed by the system.
 */
export enum MimeTypesEnum {
    /** Mime Type for Excel */
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    /** Mime Type for CSV files */
    CSV = 'text/csv',
    /** Mime Type for SAS files */
    SAS = 'application/x-sas-data',
}


/**
 * This enumerator lists all available categories of standard choices of type int that can be requested from the
 * backend.
 */
export enum StandardChoiceCategoriesInt {
}


/**
 * This enumerator lists all available categories of standard choices of type str that can be requested from the
 * backend.
 */
export enum StandardChoiceCategoriesStr {
    RiskModelTypesEnum = "RiskModelTypesEnum",
    CSVSeparatorsEnum = "CSVSeparatorsEnum",
    CSVDecimalSeparatorEnum = "CSVDecimalSeparatorEnum",
    CSVThousandSeparatorEnum = "CSVThousandSeparatorEnum",
    CSVDateFormatEnum = "CSVDateFormatEnum",
}


/**
 * Holds possible types of the target variable of the ML models.
 */
export enum TargetVariableTypes {
    BINARY = 'B',
    MULTI_CATEGORICAL = 'M',
    NON_NEGATIVE = 'N',
    UNBOUNDED = 'U',
}


/**
 * Holds possible types of the input variables of the ML models.
 */
export enum InputVariableTypes {
    NUMERICAL = 'N',
    CATEGORICAL = 'C',
}


/**
 * Holds possible choices for the input column types.
 */
export enum InputColumnTypes {
    NUMERIC = 'N',
    DATE = 'D',
    STRING = 'S',
}


/**
 * This enumerator provides the full string of the BuiltInPermissions
 */
export enum BuiltInPermissionNames {
    AdministratorRights = 'Administrator Rights',
    CreateTransaction = 'Create Transaction',
    ReadTransaction = 'Read Transaction',
    EditTransaction = 'Edit Transaction',
    DeleteTransaction = 'Delete Transaction',
    CreateEditRollbackPoint = 'Create/Edit Rollback Point',
    StartSimulation = 'Start Simulation',
    AbortSimulation = 'Abort Simulation',
    CreateEditDeleteBatchJob = 'Create/Edit/Delete Batch Job',
    MonitorAllSimulationJobs = 'Monitor All Simulation Jobs',
    CreateEditDeletePortfolio = 'Create/Edit/Delete Portfolio',
    CreateEditDeleteTimeseriesAndMatrix = 'Create/Edit/Delete Timeseries & Matrix',
    CreateEditDeleteVariables = 'Create/Edit/Delete Variables',
    CreateEditDeleteWaterfall = 'Create/Edit/Delete Waterfall',
    CreateEditDeleteWaterfallWML = 'Create/Edit/Delete WML',
    AssignRemoveTag = 'Assign/Remove Tags',
    AssignRemoveOwnership = 'Assign/Remove Ownership',
    AssignRemoveFirstApproval = 'Assign/Remove First Approval',
    AssignRemoveSecondApproval = 'Assign/Remove Second Approval',
    AssignRemoveCertification = 'Assign/Remove Certification',
    AssignRemoveTransactionScopeRelation = 'Assign/Remove Transaction-Scope Relation',
    CreateEditDeleteScope = 'Create/Edit/Delete Scope',
}



/**
 * This enumerator assigns MaterialDesign icons to the specified labels. This allows to assign the same icon to the same
 * information
 */
export enum IconRelation {
    RATING = 'thumbs_up_down',
    IFRS = 'account_balance',
    TRANSACTION = 'sync_alt',
    PORTFOLIO = 'diamond',
    RB_POINT = 'history',
    USER = 'person',
    USER_TRANSACTION_OWNER = 'account_circle',
    GROUP = 'group',
    TAG = 'sell',
    TAG_MANAGE = 'new_label',
    USER_AND_GROUPS = 'manage_accounts',
    SIMULATION_JOB_QUEUED = 'restore',
    SIMULATION_JOB_COMPLETED = 'check_circle_outline',
    SIMULATION_JOB_ERROR = 'report_gmailerrorred',
    SIMULATION_JOB_ABORTED = 'highlight_off',
    LOGOUT = 'logout',
    CLOSE = 'close'
}


/**
 * Defines the keys of the label-text-messages available in the system.
 */
export enum LabelTextMessageTypes {
    HOME,
    CONTACTS,
    LANGUAGE,
    ENGLISH,
    GERMAN,
    SPANISH,
    FRENCH,
    UNKNOWN_NETWORK_ERROR,
    MALFORMED_FETCH_REQUEST,
    LOGIN,
    LOGIN_HEADER,
    LOGIN_BUTTON_LABEL,
    LOGIN_SUCCESSFUL,
    CREATE,
    YES,
    NO,
    FILE_TYPE,
    FILE_SIZE,
    TIMESTAMP,
    CSV_SEPARATOR,
    CSV_DECIMAL_SEPARATOR,
    CSV_THOUSAND_SEPARATOR,
    CSV_DATE_FORMAT,
    CSV_MISSING_PARAMETER_PLACEHOLDERS,
    COLUMN_HEADERS_INCLUDED,
    NAME,
    DESCRIPTION,
    RISK_MODEL_NAME,
    ACTIONS,
    SELECT_ALL,
    UNSELECT_ALL,
    LOAD,
    DROP_ZONE_HINT,
    MORE_DETAILS,
    PD_MODEL,
    LGD_MODEL,
    CCF_MODEL,
    ANY_MODEL,
    ORIG_COLUMN_NAME,
    MAPPED_COLUMN_NAME,
    COLUMN_TYPE,
    MACHINE_LEARNER_COMPARISON,
    BEST_OBSERVED_GINI,
    DEGREES_OF_FREEDOM,
    GINI_COEFFICIENT,
    MODEL_PERFORMANCE_VS_COMPLEXITY,
    PROGRESS,
    DISTRIBUTION,
    ROC_CURVE,
    TRUE_POSITIVE_RATE,
    FALSE_POSITIVE_RATE,
    OCCURRENCE_DENSITY,
    CONDITIONAL_PD,
    OCCURRENCE_RATE,
    PROBABILITY_SLASH_DENSITY,
    PROBABILITY_SLASH_RATE,
    PD_PER_CATEGORY,
    RDS_VARIABLE,
    TARGET_VAR_BINARY,
    TARGET_VAR_MULTI_CATEGORICAL,
    TARGET_VAR_NON_NEGATIVE,
    TARGET_VAR_UNBOUNDED,
    INPUT_VAR_NUMERICAL,
    INPUT_VAR_CATEGORICAL,
    LP_CREATE_OR_LOAD_CAPTION,
    LP_CREATE_OR_LOAD_EXPLANATION,
    LP_CREATE_PD_MODEL,
    LP_CREATE_LGD_MODEL,
    LP_CREATE_CCF_MODEL,
    LP_LOAD_MODEL,
    LP_CREATE_PD_MODEL_EXPLANATION,
    LP_CREATE_LGD_MODEL_EXPLANATION,
    LP_CREATE_CCF_MODEL_EXPLANATION,
    LP_LOAD_MODEL_EXPLANATION,
    FU_FILE_UPLOAD_CAPTION,
    FU_FILE_UPLOAD_EXPLANATION,
    RC_RDS_CONSTRUCTION_CAPTION,
    RC_RDS_CONSTRUCTION_EXPLANATION,
    UA_UNIVARIATE_ANALYSIS_CAPTION,
    UA_UNIVARIATE_ANALYSIS_EXPLANATION,
    MA_MULTIVARIATE_ANALYSIS_CAPTION,
    MA_MULTIVARIATE_ANALYSIS_EXPLANATION,
    MS_MODEL_SELECTION_CAPTION,
    MS_MODEL_SELECTION_EXPLANATION,
}


/**
 * Defines the keys of the html-formatted texts available in the system.
 */
export enum HTMLFormattedTextTypes {
    LP_CREATE_OR_LOAD_DETAILS,
    FU_FILE_UPLOAD_DETAILS,
    RC_RDS_CONSTRUCTION_DETAILS,
    UA_UNIVARIATE_ANALYSIS_DETAILS,
    MA_MULTIVARIATE_ANALYSIS_DETAILS,
    MS_MODEL_SELECTION_DETAILS,
}

