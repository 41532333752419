/**
 * This module is a selection of function components used for rendering the general purpose state-less react components.
 * @module
 */

import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


/**
 * This is the set of properties for the centering margins component.
 */
type CenteringMarginsProps = {
    /** Specifies the width of the element. Required such that corresponding centering margins can be calculated. */
    width: number | string;
    /** The element's children to be included in the element's body */
    children: React.ReactNode;
}


/**
 * This function component centers the contained elements by automatically calculating and applying adequate margins.
 * @param props Properties containing children and desired width.
 * @returns React element
 */
export const CenteringMargins = (props: CenteringMarginsProps): React.JSX.Element => {
    return (
        <div className="mx-auto" style={{width: props.width}}>{props.children}</div>
    );
}


/**
 * This is the set of properties for the middle column component.
 */
type MiddleColumnProps = {
    /** Specifies the number of columns to be grouped together for the middle column component. */
    cols: number;
    /** Extra CSS styling for the Row element */
    extra_style?: React.CSSProperties;
    /** The element's children to be included in the element's body */
    children: React.ReactNode;
}


/**
 * This function component starts a new bootstrap row and places the children in a centered column. To center this
 * column additional columns are inserted before and after this middle column.
 * @param props Properties containing children and desired width (as number of bootstrap grid columns).
 * @returns React element
 */
export const MiddleColumn = (props: MiddleColumnProps): React.JSX.Element => {
    const wrapping_columns = Math.round((12 - props.cols) / 2 - 0.1);
    const middle_columns = 12 - 2 * wrapping_columns;
    return (
        <Row style={props.extra_style}>
            {wrapping_columns > 0 ? <Col sm={wrapping_columns}/> : undefined}
            <Col sm={middle_columns}>
                {props.children}
            </Col>
            {wrapping_columns > 0 ? <Col sm={wrapping_columns}/> : undefined}
        </Row>
    );
}


/**
 * This function component returns a div element with 20 points of top padding. It is meant to provide vertical spacing
 * for cosmetic reasons where desired.
 * @returns React element
 */
export const VerticalSpacing = (): React.JSX.Element => {
    return <div style={{paddingTop: 20}}/>
}

