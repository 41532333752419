/**
 * This module provides functions in relation to calling API routes on the backend server and processing the incoming
 * responses. The functions in this module refer to the API endpoint ApiEndPoints.ML_MODEL_LEARNERS.
 * @module
 */

import {ApiEndPoints, HttpMethods} from "../base/Settings";
import {FetchRequest} from "../base/ui_general/GeneralInterfaces";


/**
 * Creates a FetchRequest object populated with the parameters necessary for receiving a list of machine learning
 * algorithms implemented on the server. This list reflects the specification of each learner.
 * @returns A FetchRequest object containing the generated fetch request.*/
export function getRequest_get_ml_model_learners(): FetchRequest {
    return {
        api_endpoint: ApiEndPoints.ML_MODEL_LEARNERS,
        method: HttpMethods.GET,
        query_parameters: {},
    }
}
