/**
 * This module provides functions in relation to calling API routes on the backend server and processing the incoming
 * responses. The functions in this module refer to the API endpoint ApiEndPoints.ML_MODEL_ANALYSIS.
 * @module
 */

import {ApiEndPoints, HttpMethods} from "../base/Settings";
import {FetchRequest, UUID} from "../base/ui_general/GeneralInterfaces";


/**
 * Creates a FetchRequest object populated with the parameters necessary for receiving the list of machine learning
 * results
 * @param risk_model_id UUID of the risk model to query.
 * @param ml_class_name String holding the name of the class implementing the machine learner.
 * @param hyper_parameters_json JSON string encoding the dictionary holding the hyperparameters.
 * @returns A FetchRequest object containing the generated fetch request.*/
export function getRequest_get_ml_model_analysis(risk_model_id: UUID,
                                                 ml_class_name:string,
                                                 hyper_parameters_json: string): FetchRequest {
    return {
        api_endpoint: ApiEndPoints.ML_MODEL_ANALYSIS,
        method: HttpMethods.GET,
        query_parameters: {
            risk_model_id: risk_model_id,
            ml_class_name: ml_class_name,
            hyper_parameters_json: hyper_parameters_json,
        },
    }
}
