/**
 * This module provides general utility functions.
 * @module
 */

import {FeedbackMessage, QueryParameters, UUID} from "../ui_general/GeneralInterfaces";
import {saveBaseTokenAsCookie, saveCredTokenAsCookie, uuidValidate} from "./Security";
import {FeedbackMessageTypes, HTMLFormattedTextTypes, IDTypes, LabelTextMessageTypes, UserLanguages} from "../Settings";
import {LabelTextMessages} from "../LabelTextMessages";
import React from "react";
import {HTMLFormattedTexts} from "../HTMLFormattedTexts";


/**
 * This routine returns the current query string of the active url in the browser window.
 * @returns The current query string of the active url in the browser window. Encoded using encodeURI.
 */
export function getQueryString(): string {
    return encodeURI(window.location.search);
}


/**
 * This routine returns the URL path of the active url in the browser window.
 * @returns The URL path of the active url in the browser window. Encoded using encodeURI.
 */
export function getURLPath(): string {
    return encodeURI(window.location.pathname);
}


/**
 * This routine parses the query-string provided in the URL bar in the browser and searches for the specified parameter.
 * It extracts and returns its value as a string. If no such parameter is provided, undefined is returned.
 * @param parameter_name Name of parameter to search for in query-string.
 * @returns Value of parameter as string, encoded using encodeURI. If parameter not available, it returns undefined.
 */
export function getQueryStringParameter(parameter_name: string): string | undefined {
    const query_string = getQueryString();
    const params = new URLSearchParams(query_string);
    const value_str: null | string = params.get(parameter_name);
    if (value_str === null) return undefined;
    return encodeURI(value_str);
}


/**
 * This routine parses the query-string provided in the URL bar in the browser and searches for the specified parameter.
 * It extracts its value and converts it to a number. If no such parameter is provided or the conversion fails, the
 * return value is undefined.
 * @param parameter_name Name of parameter to search for in query-string.
 * @returns Value of parameter as number. If parameter not available or conversion fails, it returns undefined.
 */
export function getQueryStringParameterAsNumber(parameter_name: string): number | undefined {
    const value_str = getQueryStringParameter(parameter_name);
    if (value_str === undefined) return undefined;
    let value = Number(value_str);
    if (isNaN(value)) return undefined;
    return value;
}


/**
 * This routine parses the query-string provided in the URL bar in the browser and searches for the specified parameter.
 * It extracts its value and converts it to a UUID. If no such parameter is provided or the conversion fails, the
 * return value is undefined.
 * @param id_type Name of parameter to search for in query-string.
 * @returns Value of parameter as UUID. If parameter not available or conversion fails, it returns undefined.
 */
export function getQueryStringParameterAsUUID(id_type: IDTypes): UUID | undefined {
    const value_str = getQueryStringParameter(id_type);
    if (value_str === undefined) return undefined;
    if (!uuidValidate(value_str)) return undefined;
    return value_str;
}


/**
 * Tries to extract the base token from the URL in the browser URL field. If found, the base token is saved as a cookie
 * in the browser's cookie storage.
 */
export function saveBaseTokenFromUrlAsCookie() {
    const base_token = getQueryStringParameter("base_token");
    if (base_token) saveBaseTokenAsCookie(base_token);
}


/**
 * Tries to extract the cred-token from the URL in the browser URL field. If found, the cred-token is saved as a cookie
 * in the browser's cookie storage.
 */
export function saveCredTokenFromUrlAsCookie() {
    const cred_token = getQueryStringParameter("cred_token");
    if (cred_token) saveCredTokenAsCookie(cred_token);
}


/**
 * This functions extracts the URL, removes the rollback_point_id from the string and returns a new URL string without
 * the rollback_point_id.
 * @returns New URL string without the rollback_point_id.
 */
export function dropRollbackPointFromUrl(): string {
    const path = getURLPath();
    const query_string = getQueryString();

    let index = query_string.indexOf("&rollback_point_id");
    if (index >= 0) {
        let following_ampersand_index = query_string.substring(index + 1).indexOf("&");
        return path + query_string.substring(0, index) + (following_ampersand_index >= 0 ? query_string.substring(following_ampersand_index) : '');
    }

    index = query_string.indexOf("?rollback_point_id");
    if (index >= 0) {
        let following_ampersand_index = query_string.substring(index).indexOf("&");
        return path + (following_ampersand_index >= 0 ? '?' + query_string.substring(following_ampersand_index + 1) : '');
    }

    if (query_string.indexOf("?") >= 0) {
        return path + query_string + '&rollback_point_id=-1';
    }
    return path + '?rollback_point_id=-1';
}


/**
 * Transform a QueryParameters object into a string/string record by converting the values in the query parameters,
 * which are not strings yet, into strings.
 * @param query_parameters The QueryParameters object to be converted.
 * @returns The converted object as Record<string, string>
 */
export function transformQueryParametersToStringRecord(query_parameters: QueryParameters): Record<string, string> {
    let result: Record<string, string> = {};

    for (let key in query_parameters) {
        if (typeof query_parameters[key] === 'number') {
            result[key] = query_parameters[key].toString();
        } else {
            result[key] = query_parameters[key] as string;
        }
    }

    return result;
}


/**
 * This routine builds urls for accessing the api server.
 * @param api_endpoint This is the endpoint of the api route without server address, e.g. /api/transaction
 * @param query_parameters Specifies the parameters that should be incorporated into the url as query string
 * parameters.
 * @returns Constructed URL as string.
 */
export const buildApiUrl = (api_endpoint: string, query_parameters: QueryParameters): string => {
    const url = new URL(process.env.REACT_APP_BACKEND_URL + api_endpoint);

    url.search = new URLSearchParams(transformQueryParametersToStringRecord(query_parameters)).toString();

    return url.toString();
};


/**
 * This truncates a given string after a certain number of characters, in case the original string is longer. In that
 * case, three dots are also added at the end of the truncated string to notify about truncation.
 * @param txt Original string to be truncated if too long.
 * @param max_length Maximum length acceptable for truncated string.
 * @returns Truncated string or original string if already short enough.
 */
export function truncateString(txt: string, max_length: number): string {
    return txt.length > max_length ? txt.substring(0, max_length - 3) + '...' : txt;
}


/**
 * This truncates a given string, in case the original string is longer than the specified maximal string length. In
 * that case, three dots are inserted into the middle of the string and the respective number of characters are removed
 * from the middle of the string.
 * @param txt Original string to be truncated if too long.
 * @param max_length Maximum length acceptable for truncated string.
 * @returns Truncated string or original string if already short enough.
 */
export function truncateStringInMiddle(txt: string, max_length: number): string {
    return txt.length > max_length ? txt.substring(0, max_length / 2 - 2) + '...' +
        txt.substring(txt.length - max_length / 2 + 1, txt.length) : txt;
}


/**
 * This routine performs a deep copy of the given object and returns the copy. It works only for objects composed solely
 * of JSON-stringify-able data types.
 * @param obj Object to be copied. Can be of any type that is JSON-stringify-able-
 * @returns Returns the copied object.
 */
export function jsonDeepCopy(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
}


/**
 * This is a helper function that can be used in combination with filter to reduce an array to its unique values.
 * @param value A generic value of any type of element contained in an array.
 * @param index Index of the element.
 * @param self The array upon which the filter is acting.
 * @returns Boolean that indicates whether the occurrence of the value is the first in the array.
 */
export function onlyUnique(value: any, index: number, self: Array<any>): boolean {
  return self.indexOf(value) === index;
}


/**
 * Converts an input string into a technical name. A technical name only consists of lower case letters, has no special
 * characters and no spaces. The provided raw string is converted such that these requirements are met.
 * @param input_str Raw input string to be converted.
 * @returns Clean technical name (small letters, no special characters, no spaces)
 */
export function convert_string_to_technical_name(input_str: string): string {
    const regex = /\W+/g;
    return input_str.toLowerCase().replace(
        / /g, "_"
    ).replace(/:/g, "_").replace(regex, '');
}


/**
 * Helper function for processing enumerations conveniently. Returns an array holding the keys of the provided
 * enumeration which then allows to conveniently loop over these keys by means of the following code example:
 * for (const token_key of enumKeys(TokenTypes)) { TokenTypes[token_key] = ... }
 * @param enumeration Enumeration the keys of which shall be extracted and returned
 * @returns Array of the keys of the given enumeration
 */
export function enumKeys<O extends object, K extends keyof O = keyof O>(enumeration: O): K[] {
    return Object.keys(enumeration).filter(k => !Number.isNaN(k)) as K[]
}


/**
 * Retrieves the user language setting from local storage under the keyword 'user_language'. If no setting can be
 * found, the English language is returned as default.
 */
export function getUserLanguage(): UserLanguages {
    const local_storage_user_language = localStorage.getItem('user_language');
    if (!local_storage_user_language) return UserLanguages.EN;
    return local_storage_user_language as UserLanguages;
}


/**
 * Sets the user language in the browser's local storage under the keyword 'user_language'.
 * @param user_language User language setting to be stored in the browser's local storage.
 */
export function setUserLanguage(user_language: UserLanguages) {
    localStorage.setItem('user_language', user_language);
}


/**
 * Retrieves a label, text, or message as indicated by the message type in the language requested by the user language
 * parameter. The message can be further enriched by providing additional arguments to this function (rest arguments).
 * This, however, requires that the corresponding message text incorporates template expressions such as ${args[0]} or
 * ${args[1]}, etc...
 * @param message_type Type of message text being requested.
 * @param user_language Requested language.
 * @param args Arbitrary number of additional arguments to be incorporated into the message string.
 * @returns The constructed message string in the desired language.
 */
export function getLabelTextMessage(message_type: LabelTextMessageTypes,
                                    user_language: UserLanguages,
                                    ...args: (string | number)[]): string {
    return LabelTextMessages[message_type][user_language]();
}


/**
 * Retrieves a html-formatted text as indicated by the text type in the language requested by the user language
 * parameter.
 * @param text_type Type of text being requested.
 * @param user_language Requested language.
 * @returns The html-formatted text in the desired language.
 */
export function getHTMLFormattedText(text_type: HTMLFormattedTextTypes,
                                     user_language: UserLanguages): React.JSX.Element {
    return HTMLFormattedTexts[text_type][user_language];
}


/**
 * Creates a FeedbackMessage object of type ERROR with specified http-code, if provided. If omitted, http-code 400 is
 * set. The message text is set according to the provided message type.
 * @param type Message type specifying the text of the error message.
 * @param http_code TOptional HTTP code.
 */
export function createErrorFeedbackMessage(type: LabelTextMessageTypes, http_code?: number): FeedbackMessage {
    let feedback_message: FeedbackMessage = {
        message_type: FeedbackMessageTypes.ERROR,
        message_text: getLabelTextMessage(
            type,
            getUserLanguage()
        ),
    }
    feedback_message.http_code = http_code ? http_code : 400;
    return feedback_message;
}


/**
 * Transforms the given UTC timestamp provided as string and referring to UTC timezone to a JavaScript Date object
 * carrying the UTC timezone information. String representations of dates and times can then be obtained from the date
 * object using the standard routines, such as toLocaleString(), toLocaleDateString(), toLocaleTimeString(), etc.
 * @param utc_timestamp_string String holding the UTC timestamp.
 * @returns JavaScript Date object holding the utc timestamp with utc timezone information.
 */
export function digestUTCTimestamp(utc_timestamp_string: string): Date {
    const raw_date = new Date(utc_timestamp_string);
    return new Date(Date.UTC(
        raw_date.getFullYear(),
        raw_date.getMonth(),
        raw_date.getDate(),
        raw_date.getHours(),
        raw_date.getMinutes(),
        raw_date.getSeconds()
    ));
}


/**
 * Converts a given file size in bytes into a readable string presentation.
 * @param file_size File size in bytes.
 * @returns String representation of file size in readable form.
 */
export function readableFileSize(file_size: number) {
    const file_size_strings = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
    let result = 'EXTREMELY LARGE';
    file_size_strings.forEach((fs, ind) => {
        const scale = 1024**ind;
        if (file_size >= scale) result = (Math.round(10 * file_size / scale) / 10).toString() + ' ' + fs;
    })
    return result;
}