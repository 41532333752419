import React from 'react';
import {MiddleColumn} from "../ui_general/GeneralFunctionComponents";
import {RBSButton, RBSButtonSizes, RBSButtonVariants} from "../rbs_components/RBSButton";
import {LabelTextMessageTypes, UserLanguages} from "../Settings";
import {getLabelTextMessage} from "../utils/GeneralUtilities";
import {RBSOffCanvas, RBSOffCanvasPlacements} from "../rbs_components/RBSOffcanvas";
import Carousel from 'react-bootstrap/Carousel';
import {CallbackNoParams} from "../ui_general/GeneralInterfaces";


/**
 * Defines the properties of the User-Guidance component.
 */
interface UserGuidanceProps {
    /** Caption of the view to be presented to the user. */
    caption: string;
    /** Short Explanation of the required actions of the specific view to give the user the necessary guidance. */
    explanation: string;
    /** Caption of the previous view to scroll in when the user scrolls to the left. */
    previous_caption?: string;
    /** Short Explanation of the required actions of the previous view to scroll in when the user scrolls to the left.*/
    previous_explanation?: string;
    /** Caption of the next view to scroll in when the user scrolls to the right. */
    next_caption?: string;
    /** Short Explanation of the required actions of the next view to scroll in when the user scrolls to the right. */
    next_explanation?: string;
    /** Long Explanation string of the required actions of the specific view to give the user the necessary guidance. */
    details?: string;
    /** Long Explanation html element of the required actions of the specific view to give the user the necessary
     * guidance. */
    details_html?: React.JSX.Element;
    /** Callback function called when user clicks on arrow to navigate to previous slide */
    onPreviousSlide?: CallbackNoParams;
    /** Callback function called when user clicks on arrow to navigate to next slide */
    onNextSlide?: CallbackNoParams;
    /** Indicates the preferred user language. */
    user_language: UserLanguages;
}


/**
 * Defines the state of the User-Guidance component.
 */
interface UserGuidanceState {
    /** Indicates whether the long explanation shall be shown. */
    show_details: boolean;
    /** Indicates the currently selected carousel displayed on screen. Starts with 1 and changes depending on which
     * arrow the user clicks. */
    carousel_index: number;
}


/**
 * This class is a wrapper of the User-Guidance component, which provides instructions for the user in relation to
 * the required actions on the specific view.
 */
export class UserGuidance extends React.Component<UserGuidanceProps, UserGuidanceState> {

    /**
     * Constructor. Initializes states.
     * @param props React properties passed from the calling instance.
     */
    constructor(props: UserGuidanceProps) {
        super(props);

        this.state = {
            show_details: false,
            carousel_index: props.previous_explanation || props.previous_caption ? 1 : 0
        };
    }


    /**
     * This routine is called from React whenever the component needs to be re-rendered. It renders the component on the
     * basis of the current state and properties.
     */
    render() {
        return (
            <div style={{paddingTop: 20}}>
                    <Carousel indicators={false}
                              controls={true}
                              interval={null}
                              wrap={false}
                              activeIndex={this.state.carousel_index} onSelect={(index)=> {
                                  this.setState({carousel_index: index})
                              }}
                              onSlid={(index) => {
                                  if (index === 0 && this.props.onPreviousSlide) this.props.onPreviousSlide();
                                  if (index > 0 && this.props.onNextSlide) this.props.onNextSlide();
                              }}
                              data-bs-theme="dark">
                        {this.props.previous_caption || this.props.previous_explanation ?
                            <Carousel.Item>
                                <MiddleColumn cols={8}>
                                    <p className={'display-6'} style={{textAlign: "center"}}>
                                        {this.props.previous_caption?.toUpperCase()}
                                    </p>
                                    <hr className="hr" />

                                    <p>
                                        {this.props.previous_explanation}
                                    </p>
                                    <hr className="hr" />
                                </MiddleColumn>
                            </Carousel.Item> : undefined
                        }

                        <Carousel.Item>
                            <MiddleColumn cols={8}>
                                <p className={'display-6'} style={{textAlign: "center"}}>
                                    {this.props.caption.toUpperCase()}
                                </p>
                                <hr className="hr" />

                                <p>
                                    {this.props.explanation}
                                    {this.props.details || this.props.details_html ?
                                        <RBSButton
                                            label={'[' + getLabelTextMessage(
                                                LabelTextMessageTypes.MORE_DETAILS,
                                                this.props.user_language
                                            ) + ']'}
                                            variant={RBSButtonVariants.LINK}
                                            size={RBSButtonSizes.SMALL}
                                            onClick={() => {
                                                this.setState({
                                                    show_details: true
                                                })
                                            }}
                                            extraStyle={{
                                                padding:0,
                                                margin:0,
                                                border: 0,
                                                position: 'relative',
                                                top: -1,
                                                paddingLeft: 10}}
                                        />
                                        :
                                        undefined
                                    }
                                </p>
                                <hr className="hr" />
                            </MiddleColumn>
                        </Carousel.Item>

                        {this.props.next_caption || this.props.next_explanation ?
                            <Carousel.Item>
                                <MiddleColumn cols={8}>
                                    <p className={'display-6'} style={{textAlign: "center"}}>
                                        {this.props.next_caption?.toUpperCase()}
                                    </p>
                                    <hr className="hr" />

                                    <p>
                                        {this.props.next_explanation}
                                    </p>
                                    <hr className="hr" />
                                </MiddleColumn>
                            </Carousel.Item> : undefined
                        }
                    </Carousel>


                    {this.props.details || this.props.details_html ?
                        <RBSOffCanvas
                            header_title={this.props.caption}
                            show={this.state.show_details}
                            placement={RBSOffCanvasPlacements.RIGHT}
                            show_close_button={true}
                            onClose={() => {
                                this.setState({
                                    show_details: false
                                })
                            }}
                            extra_style={{background: 'whitesmoke'}}
                        >
                            {this.props.details}
                            {this.props.details_html}

                        </RBSOffCanvas>
                        :
                        undefined
                    }

            </div>
        );
    }
}
