/**
 * This module provides functions in relation to calling API routes on the backend server and processing the incoming
 * responses. The functions in this module refer to the API endpoint ApiEndPoints.RISK_MODEL.
 * @module
 */

import {ApiEndPoints, HttpMethods, RiskModelTypes,} from "../base/Settings";
import {FetchRequest} from "../base/ui_general/GeneralInterfaces";


/**
 * Creates a FetchRequest object populated with the parameters necessary for triggering the creation of a new risk
 * model on the server. Only name, description and risk model type need to be specified for this purpose.
 * @param name Name of the new risk model
 * @param description Description of the new risk model
 * @param risk_model_type Type of the new risk model
 * @returns A FetchRequest object containing the generated fetch request.*/
export function getRequest_create_risk_model(name: string,
                                             description: string,
                                             risk_model_type: RiskModelTypes): FetchRequest {
    return {
        api_endpoint: ApiEndPoints.RISK_MODEL,
        method: HttpMethods.PUT,
        query_parameters: {
            'name': name,
            'description': description,
            'risk_model_type': risk_model_type
        },
    }
}


/**
 * Creates a FetchRequest object populated with the parameters necessary for triggering the query of all risk models
 * on the server.
 * @returns A FetchRequest object containing the generated fetch request.*/
export function getRequest_get_all_risk_models(): FetchRequest {
    return {
        api_endpoint: ApiEndPoints.RISK_MODEL,
        method: HttpMethods.GET,
        query_parameters: {},
    }
}