import React from 'react';
import {BaseView, BaseViewProps, BaseViewState, BaseViewStateInitial} from "../base/ui_general/BaseView";
import {getQueryStringParameterAsUUID} from "../base/utils/GeneralUtilities";
import {
    FileColumnMapping,
    FileUploadAssociation, RDSDefinition,
    UnivariateAnalysisMetrics,
    UUID
} from "../base/ui_general/GeneralInterfaces";
import {ColorPalette, FrontendURLPaths, HTMLFormattedTextTypes, IDTypes, LabelTextMessageTypes} from "../base/Settings";
import {UserGuidance} from "../base/ui_components/UserGuidance";
import Accordion from 'react-bootstrap/Accordion';
import Plot from 'react-plotly.js';
import {MiddleColumn, VerticalSpacing} from "../base/ui_general/GeneralFunctionComponents";
import {boundMethod} from "autobind-decorator";
import {getRequest_get_risk_model_file_upload_associations} from "../api/risk_model_file_upload_association";
import {getRequest_get_risk_model_file_column_mapping} from "../api/risk_model_file_column_mapping";
import {getRequest_get_univariate_analysis_metrics} from "../api/analysis_univariate_metrics";
import {getRequest_get_rds_definitions} from "../api/risk_model_rds_definition";


/** This defines the properties of the UnivariateAnalysisOverView. */
interface UnivariateAnalysisOverViewProps extends BaseViewProps {
}


/** This defines the state of the UnivariateAnalysisOverView. */
interface UnivariateAnalysisOverViewState extends BaseViewState {
    /** UUID of selected model. */
    risk_model_id: UUID | undefined;
    /** UUID of selected rollback point. */
    rollback_point_id: UUID | undefined;
    /** Holds the list of file upload associations of the risk model */
    file_upload_associations: Array<FileUploadAssociation> | undefined;
    /** Holds the list of file column mappings of the risk model */
    file_column_mappings: Array<FileColumnMapping> | undefined;
    /** Holds the list of rds definition entries of the risk model */
    rds_definitions: Array<RDSDefinition> | undefined;
    /** Holds the univariate analysis results for all selected input variables in the reference data set (RDS) */
    univariate_analysis_metrics: Array<UnivariateAnalysisMetrics> | undefined;
}


/**
 * This defines the UnivariateAnalysisOverView, which provides functionality for running univariate analyses on the
 * reference data set (RDS) and presenting the results to the user.
 * Inherits from [[`BaseView`]].
 */
export class UnivariateAnalysisOverView
    extends BaseView<UnivariateAnalysisOverViewProps, UnivariateAnalysisOverViewState> {

    /**
     * Constructor. Initializing the view's state. Loads model id and rollback point id from browser's query string.
     * @param props The view's property object.
     */
    constructor(props: UnivariateAnalysisOverViewProps) {
        super(props);

        this.state = {
            ...BaseViewStateInitial,
            risk_model_id: getQueryStringParameterAsUUID(IDTypes.RISK_MODEL),
            rollback_point_id: getQueryStringParameterAsUUID(IDTypes.ROLLBACK_POINT),
            file_upload_associations: undefined,
            file_column_mappings: undefined,
            rds_definitions: undefined,
            univariate_analysis_metrics: undefined
        };
    }


    /**
     * If the component did mount, the required parameters that have been extracted from the query string in the
     * constructor are passed to the Main Frame.
     */
    componentDidMount() {
        this.props.provideModelIDs(this.state.risk_model_id, this.state.rollback_point_id);
        this.load_data();
    }


    @boundMethod
    load_data() {
        if (this.state.risk_model_id) {
            this.processFetchRequest(
                getRequest_get_risk_model_file_upload_associations(this.state.risk_model_id),
                (data: Array<FileUploadAssociation>) => {
                    this.setState({file_upload_associations: data.sort(
                            (a, b) => (
                                a.name === b.name ? 0 : (a.name < b.name ? -1 : +1)
                            )
                        )})
                },
            );

            this.processFetchRequest(
                getRequest_get_univariate_analysis_metrics(this.state.risk_model_id),
                (data: Array<UnivariateAnalysisMetrics>) => {
                    this.setState({univariate_analysis_metrics: data})
                },
            );

            this.processFetchRequest(
                getRequest_get_rds_definitions(this.state.risk_model_id),
                (data: Array<RDSDefinition>) => {
                    this.setState({rds_definitions: data})
                },
            );

            this.processFetchRequest(
                getRequest_get_risk_model_file_column_mapping(this.state.risk_model_id),
                (data: Array<FileColumnMapping>) => {
                    this.setState({file_column_mappings: data})
                },
            );
        }
    }


    /**
     * This function renders the view.
     */
    render() {
        return(
            <div>
                <UserGuidance
                    caption={this.getLabelTextMessage(LabelTextMessageTypes.UA_UNIVARIATE_ANALYSIS_CAPTION)}
                    explanation={this.getLabelTextMessage(
                        LabelTextMessageTypes.UA_UNIVARIATE_ANALYSIS_EXPLANATION
                    )}
                    previous_caption={this.getLabelTextMessage(LabelTextMessageTypes.RC_RDS_CONSTRUCTION_CAPTION)}
                    previous_explanation={this.getLabelTextMessage(
                        LabelTextMessageTypes.RC_RDS_CONSTRUCTION_EXPLANATION
                    )}
                    next_caption={this.getLabelTextMessage(LabelTextMessageTypes.MA_MULTIVARIATE_ANALYSIS_CAPTION)}
                    next_explanation={this.getLabelTextMessage(
                        LabelTextMessageTypes.MA_MULTIVARIATE_ANALYSIS_EXPLANATION
                    )}
                    onPreviousSlide={() => this.navigate(
                        FrontendURLPaths.RDS_CONSTRUCTION,
                        IDTypes.RISK_MODEL,
                        this.state.risk_model_id,
                        this.state.rollback_point_id)
                    }
                    onNextSlide={() => this.navigate(
                        FrontendURLPaths.MULTIVARIATE_ANALYSIS,
                        IDTypes.RISK_MODEL,
                        this.state.risk_model_id,
                        this.state.rollback_point_id)
                    }
                    details_html={this.getHTMLFormattedText(HTMLFormattedTextTypes.UA_UNIVARIATE_ANALYSIS_DETAILS)}
                    user_language={this.props.user_language}
                />

                <VerticalSpacing/>


                <MiddleColumn cols={8}>
                    <Accordion defaultActiveKey="-1">
                        {this.state.univariate_analysis_metrics?.sort(
                            (a, b) => a.gini_coefficient === b.gini_coefficient ? 0 :
                                (Math.abs(a.gini_coefficient) > Math.abs(b.gini_coefficient) ? -1 : +1)
                        ).map(
                            (univariate_analysis_metrics, index) => {
                                let rds_definition = this.state.rds_definitions?.find(
                                    rd => rd.id === univariate_analysis_metrics.rds_definition_id
                                );
                                let file_column_mapping = this.state.file_column_mappings?.find(
                                    map => map.id === rds_definition?.file_column_mapping_id
                                );

                                return (
                                    <Accordion.Item eventKey={index.toString()}>
                                        <Accordion.Header>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{minWidth: 400}}>
                                                            <b>
                                                                {file_column_mapping?.mapped_col_name}
                                                            </b>
                                                        </td>
                                                        <td>
                                                            {this.getLabelTextMessage(LabelTextMessageTypes.GINI_COEFFICIENT) + " = " + (Math.round(10000 * univariate_analysis_metrics.gini_coefficient) / 100).toString() + '%'}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {/* Plot of ROC curve*/}
                                            <Plot
                                                data={[{
                                                    x: [0, 1],
                                                    y: [0, 1],
                                                    type: 'scatter',
                                                    mode: 'lines',
                                                    marker: {color: ColorPalette.ROC_REFERENCE_LINE},
                                                }, {
                                                    x: univariate_analysis_metrics.gini_fpr.map(x => Math.round(10000 * x) / 10000),
                                                    y: univariate_analysis_metrics.gini_tpr.map(y => Math.round(10000 * y) / 10000),
                                                    type: 'scatter',
                                                    mode: 'lines+markers',
                                                    marker: {color: ColorPalette.ROC_CURVE},
                                                }]}
                                                layout={{
                                                    width: 600,
                                                    height: 400,
                                                    showlegend: false,
                                                    title: '<b>' + this.getLabelTextMessage(LabelTextMessageTypes.ROC_CURVE) + '</b>',
                                                    xaxis: {
                                                        title: {
                                                            text: '<b>' + this.getLabelTextMessage(LabelTextMessageTypes.FALSE_POSITIVE_RATE) + '</b>',
                                                        }
                                                    },
                                                    yaxis: {
                                                        title: {
                                                            text: '<b>' + this.getLabelTextMessage(LabelTextMessageTypes.TRUE_POSITIVE_RATE) + '</b>',
                                                        }
                                                    },
                                                }}
                                            />

                                            {/* Box-Plot of categories in case of categorical variables */}
                                            {!univariate_analysis_metrics.categories ||
                                            !univariate_analysis_metrics.occurrence_rate_per_category ||
                                            !univariate_analysis_metrics.avg_target_per_category ? undefined :
                                                <Plot
                                                    data={[{
                                                        name: this.getLabelTextMessage(LabelTextMessageTypes.OCCURRENCE_RATE),
                                                        x: univariate_analysis_metrics.categories,
                                                        y: univariate_analysis_metrics.occurrence_rate_per_category.map(y => Math.round(10000 * y) / 10000),
                                                        type: 'bar',
                                                        marker: {
                                                            color: ColorPalette.DISTRIBUTION_TRANSPARENT,
                                                            line: {
                                                                color: ColorPalette.DISTRIBUTION,
                                                                width: 1.5
                                                            }
                                                        },
                                                    }, {
                                                        name: this.getLabelTextMessage(LabelTextMessageTypes.PD_PER_CATEGORY),
                                                        x: univariate_analysis_metrics.categories,
                                                        y: univariate_analysis_metrics.avg_target_per_category.map(y => Math.round(10000 * y) / 10000),
                                                        type: 'bar',
                                                        marker: {
                                                            color: ColorPalette.DEFAULT_RATE_TRANSPARENT,
                                                            line: {
                                                                color: ColorPalette.DEFAULT_RATE,
                                                                width: 1.5
                                                            }
                                                        },
                                                    }]}
                                                    layout={{
                                                        autosize: true,
                                                        width: 600,
                                                        height: 400,
                                                        title: '<b>' + this.getLabelTextMessage(LabelTextMessageTypes.DISTRIBUTION) + '</b>',
                                                        yaxis: {
                                                            title: '<b>' + this.getLabelTextMessage(LabelTextMessageTypes.PROBABILITY_SLASH_RATE) + '</b>',
                                                        },
                                                    }}
                                                />
                                            }

                                            {/* Conditional Density Plot of numeric variable */}
                                            {!univariate_analysis_metrics.variable_grid ||
                                            !univariate_analysis_metrics.occurrence_density ||
                                            !univariate_analysis_metrics.conditional_probability ? undefined :
                                                <Plot
                                                    data={[{
                                                        name: this.getLabelTextMessage(LabelTextMessageTypes.OCCURRENCE_DENSITY),
                                                        x: univariate_analysis_metrics.variable_grid,
                                                        y: univariate_analysis_metrics.occurrence_density,
                                                        type: 'bar',
                                                        marker: {color: ColorPalette.DISTRIBUTION_TRANSPARENT},
                                                    }, {
                                                        name: this.getLabelTextMessage(LabelTextMessageTypes.CONDITIONAL_PD),
                                                        x: univariate_analysis_metrics.variable_grid,
                                                        y: univariate_analysis_metrics.conditional_probability,
                                                        type: 'bar',
                                                        marker: {color: ColorPalette.DEFAULT_RATE_TRANSPARENT},
                                                    }]}
                                                    layout={{
                                                        width: 600,
                                                        height: 400,
                                                        title: '<b>' + this.getLabelTextMessage(LabelTextMessageTypes.DISTRIBUTION) + '</b>',
                                                        yaxis: {
                                                            title: '<b>' + this.getLabelTextMessage(LabelTextMessageTypes.PROBABILITY_SLASH_DENSITY) + '</b>',
                                                        },
                                                        legend: {
                                                            orientation: 'h'
                                                        }
                                                    }}
                                                />
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            }
                        )}
                    </Accordion>

                </MiddleColumn>

            </div>

        );
    }
}
