import React from 'react';
import {BaseView, BaseViewProps, BaseViewState, BaseViewStateInitial} from "../base/ui_general/BaseView";
import {getQueryStringParameterAsUUID} from "../base/utils/GeneralUtilities";
import {RiskModel, UUID} from "../base/ui_general/GeneralInterfaces";
import {
    FrontendURLPaths,
    HTMLFormattedTextTypes,
    IDTypes, ImageURLs,
    LabelTextMessageTypes, ColorPalette,
    RiskModelTypes
} from "../base/Settings";
import {UserGuidance} from "../base/ui_components/UserGuidance";
import {RBSCard} from "../base/rbs_components/RBSCard";
import {MiddleColumn, VerticalSpacing} from "../base/ui_general/GeneralFunctionComponents";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {RBSButton} from "../base/rbs_components/RBSButton";
import {getRequest_create_risk_model} from "../api/risk_model";


/** This defines the properties of the LandingPageOverView. */
interface LandingPageOverViewProps extends BaseViewProps {
}


/** This defines the state of the LandingPageOverView. */
interface LandingPageOverViewState extends BaseViewState {
    /** UUID of selected model. */
    risk_model_id: UUID | undefined;
    /** UUID of selected rollback point. */
    rollback_point_id: UUID | undefined;
}


/**
 * This defines the LandingPageOverView, which is presented to the user when not specifying a specific path in the
 * URL query string. Supposed to provide some introduction to the user and guidance to get started.
 * Inherits from [[`BaseView`]].
 */
export class LandingPageOverView extends BaseView<LandingPageOverViewProps, LandingPageOverViewState> {

    /**
     * Constructor. Initializing the view's state. Loads model id and rollback point id from browser's query string.
     * @param props The view's property object.
     */
    constructor(props: LandingPageOverViewProps) {
        super(props);

        this.state = {
            ...BaseViewStateInitial,
            risk_model_id: getQueryStringParameterAsUUID(IDTypes.RISK_MODEL),
            rollback_point_id: getQueryStringParameterAsUUID(IDTypes.ROLLBACK_POINT),
        };
    }


    /**
     * If the component did mount, the required parameters that have been extracted from the query string in the
     * constructor are passed to the Main Frame.
     */
    componentDidMount() {
        this.props.provideModelIDs(this.state.risk_model_id, this.state.rollback_point_id);
    }


    triggerNewModelCreation(model_type: RiskModelTypes) {
        this.processFetchRequest(
            getRequest_create_risk_model(
                model_type + '-RiskModel',
                'Model Description',
                model_type
                ),
            (risk_model: RiskModel) => {
                this.navigate(FrontendURLPaths.FILE_UPLOAD, IDTypes.RISK_MODEL, risk_model.id);
            })
    }


    /**
     * This function renders the view.
     */
    render() {
        return(
            <div>
                <UserGuidance
                    caption={this.getLabelTextMessage(LabelTextMessageTypes.LP_CREATE_OR_LOAD_CAPTION)}
                    explanation={this.getLabelTextMessage(
                        LabelTextMessageTypes.LP_CREATE_OR_LOAD_EXPLANATION
                    )}
                    details_html={this.getHTMLFormattedText(HTMLFormattedTextTypes.LP_CREATE_OR_LOAD_DETAILS)}
                    user_language={this.props.user_language}
                />

                <VerticalSpacing/>

                <MiddleColumn cols={8}>
                    <Row>
                        {/* Creation of PD Model */}
                        <Col sm={3}>
                            <RBSCard
                                header={this.getLabelTextMessage(LabelTextMessageTypes.PD_MODEL)}
                                header_extra_style={{backgroundColor: ColorPalette.PD_MODEL}}
                                caption={this.getLabelTextMessage(LabelTextMessageTypes.LP_CREATE_PD_MODEL)}
                                image_src={ImageURLs.CREATE_PD_MODEL}
                                body_text={this.getLabelTextMessage(
                                    LabelTextMessageTypes.LP_CREATE_PD_MODEL_EXPLANATION
                                )}
                                body_text_extra_style={{minHeight:100}}
                            >
                                <RBSButton
                                    label={this.getLabelTextMessage(LabelTextMessageTypes.CREATE)}
                                    onClick={() => {
                                        this.triggerNewModelCreation(RiskModelTypes.PD);
                                    }}
                                />
                            </RBSCard>
                        </Col>

                        {/* Creation of LGD Model */}
                        <Col sm={3}>
                            <RBSCard
                                header={this.getLabelTextMessage(LabelTextMessageTypes.LGD_MODEL)}
                                header_extra_style={{backgroundColor: ColorPalette.LGD_MODEL}}
                                caption={this.getLabelTextMessage(LabelTextMessageTypes.LP_CREATE_LGD_MODEL)}
                                image_src={ImageURLs.CREATE_LGD_MODEL}
                                body_text={this.getLabelTextMessage(
                                    LabelTextMessageTypes.LP_CREATE_LGD_MODEL_EXPLANATION
                                )}
                                body_text_extra_style={{minHeight:100}}
                            >
                                <RBSButton
                                    label={this.getLabelTextMessage(LabelTextMessageTypes.CREATE)}
                                    onClick={() => {
                                        this.triggerNewModelCreation(RiskModelTypes.LGD);
                                    }}
                                />
                            </RBSCard>
                        </Col>

                        {/* Creation of CCF Model */}
                        <Col sm={3}>
                            <RBSCard
                                header={this.getLabelTextMessage(LabelTextMessageTypes.CCF_MODEL)}
                                header_extra_style={{backgroundColor: ColorPalette.CCF_MODEL}}
                                caption={this.getLabelTextMessage(LabelTextMessageTypes.LP_CREATE_CCF_MODEL)}
                                image_src={ImageURLs.CREATE_CCF_MODEL}
                                body_text={this.getLabelTextMessage(
                                    LabelTextMessageTypes.LP_CREATE_CCF_MODEL_EXPLANATION
                                )}
                                body_text_extra_style={{minHeight:100}}
                            >
                                <RBSButton
                                    label={this.getLabelTextMessage(LabelTextMessageTypes.CREATE)}
                                    onClick={() => {
                                        this.triggerNewModelCreation(RiskModelTypes.CCF);
                                    }}
                                />
                            </RBSCard>
                        </Col>

                        {/* Loading of Model */}
                        <Col sm={3}>
                            <RBSCard
                                header={this.getLabelTextMessage(LabelTextMessageTypes.ANY_MODEL)}
                                caption={this.getLabelTextMessage(LabelTextMessageTypes.LP_LOAD_MODEL)}
                                image_src={ImageURLs.LOAD_MODEL}
                                body_text={this.getLabelTextMessage(
                                    LabelTextMessageTypes.LP_LOAD_MODEL_EXPLANATION
                                )}
                                body_text_extra_style={{minHeight:100}}
                            >
                                <RBSButton
                                    label={this.getLabelTextMessage(LabelTextMessageTypes.LOAD)}
                                    onClick={() => {
                                        this.navigate(FrontendURLPaths.MODEL_SELECTION)
                                    }}
                                />
                            </RBSCard>
                        </Col>

                    </Row>
                </MiddleColumn>
            </div>
        );
    }
}
