/**
 * This module provides functions in relation to calling API routes on the backend server and processing the incoming
 * responses. The functions in this module refer to the API endpoint ApiEndPoints.ANALYSIS_UNIVARIATE_METRICS.
 * @module
 */

import {FetchRequest, UUID} from "../base/ui_general/GeneralInterfaces";
import {ApiEndPoints, HttpMethods} from "../base/Settings";


/**
 * Creates a FetchRequest object populated with the parameters necessary for triggering univariate analyses on the
 * server and obtaining the respective results.
 * @param risk_model_id UUID of the risk model to query.
 * @returns A FetchRequest object containing the generated fetch request. */
export function getRequest_get_univariate_analysis_metrics(risk_model_id: UUID): FetchRequest {
    return {
        api_endpoint: ApiEndPoints.ANALYSIS_UNIVARIATE_METRICS,
        method: HttpMethods.GET,
        query_parameters: {
            'risk_model_id': risk_model_id,
        },
    }
}
