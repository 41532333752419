import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {CallbackNoParams} from "../ui_general/GeneralInterfaces";


/**
 * Defines the properties of the React-Bootstrap Modal component wrapper.
 */
interface RBSModalProps {
    /** Holds header title of modal */
    header_title?: string;
    /** Holds label of the primary dialog button. */
    primary_button_label?: string;
    /** Holds label of the secondary dialog button. */
    secondary_button_label?: string;
    /** Extra CSS styling for the modal */
    extra_style?: React.CSSProperties;
    /** Callback for processing clicks on the primary button */
    onClickPrimary?: CallbackNoParams;
    /** Callback for processing clicks on the primary button */
    onClickSecondary?: CallbackNoParams;
    /** Callback for processing user requests to close the modal (via close button or clicks outside of modal) */
    onClose?: CallbackNoParams;
    /** Boolean indicating whether the modal shall be displayed on screen */
    show: boolean;
    /** Boolean indicating whether the primary button shall be disabled */
    primary_button_disabled?: boolean;
    /** Boolean indicating whether the secondary button shall be disabled */
    secondary_button_disabled?: boolean;
    /** Indicates whether the modal is static, which means that clicks outside the modal will not hide the modal */
    static_modal?: boolean;
    /** Indicates whether a window close button shall be added in the upper right corner of the modal */
    show_close_button?: boolean;
    /** The modal's children to be included in the modal's body */
    children?: React.ReactNode;
}


/**
 * Defines the state of the React-Bootstrap Modal component wrapper.
 */
interface RBSModalState {
}


/**
 * This class is a wrapper of the React-Bootstrap Modal component.
 */
export class RBSModal extends React.Component<RBSModalProps, RBSModalState> {

    /**
     * Constructor. Initializes states.
     * @param props React properties passed from the calling instance.
     */
    constructor(props: RBSModalProps) {
        super(props);

        this.state = {
        };
    }


    /**
     * This routine is called from React whenever the component needs to be re-rendered. It renders the component on the
     * basis of the current state and properties.
     */
    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onClose}
                backdrop={this.props.static_modal ? 'static' : undefined}
                keyboard={!this.props.static_modal}
                style={this.props.extra_style}
            >
                {this.props.header_title ?
                    <Modal.Header closeButton={this.props.show_close_button}>
                        <Modal.Title>{this.props.header_title}</Modal.Title>
                    </Modal.Header>
                    :
                    undefined
                }

                {this.props.children ?
                    <Modal.Body>
                        {this.props.children}
                    </Modal.Body>
                    :
                    undefined
                }

                {this.props.onClickSecondary || this.props.onClickPrimary ?
                    <Modal.Footer>
                        {this.props.onClickSecondary ?
                            <Button variant="secondary"
                                    onClick={this.props.onClickSecondary}
                                    disabled={this.props.secondary_button_disabled}>
                                {this.props.secondary_button_label}
                            </Button>
                            :
                            undefined
                        }
                        {this.props.onClickPrimary ?
                            <Button variant="primary"
                                    onClick={this.props.onClickPrimary}
                                    disabled={this.props.primary_button_disabled}>
                                {this.props.primary_button_label}
                            </Button>
                            :
                            undefined
                        }
                    </Modal.Footer>
                    :
                    undefined
                }
            </Modal>
        );
    }
}
