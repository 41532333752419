import {BaseComponent, BaseComponentProps, BaseComponentState, BaseComponentStateInitial} from "./BaseComponent";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {UserAccessPrivilege, UUID} from "./GeneralInterfaces";
import {
    BuiltInPermissionNames,
    FrontendURLPaths,
    HTMLFormattedTextTypes,
    IDTypes,
    LabelTextMessageTypes,
    UserLanguages
} from "../Settings";
import {
    checkUserCompetence,
    checkUserTagPermission,
    checkUserCompetenceAndTagPermission,
    checkUserOwnsModel
} from "../utils/Security";
import {getHTMLFormattedText, getLabelTextMessage} from '../utils/GeneralUtilities';
import React from "react";


/**
 * This interface defines the properties of the class [[`BaseView`]]. All classes deriving from this class must
 * provide properties that extend this interface.
 */
export interface BaseViewProps extends BaseComponentProps {
    /** Allows navigating within the react-router-dom without reloading the website. */
    navigate: NavigateFunction;
    /** Allows passing model id and rollback point id resolved from query string parameters and possibly further
     * API calls for resolution purposes to the MainFrame. */
    provideModelIDs: (model_id: UUID | undefined, rollback_point_id: UUID | undefined) => void;
    /** Holds the user access privileges, i.e. string lists reflecting competences and tag-level permissions, if any */
    user_access_privilege: UserAccessPrivilege | undefined;
    /** Indicates the preferred user language. */
    user_language: UserLanguages;
}


/**
 * This interface defines the state of the class [[`BaseView`]]. All classes deriving from this class must
 * use a state interface that derives from this interface.
 */
export interface BaseViewState extends BaseComponentState {
}


/**
 * This constant holds the initialization for all state attributes of the class [[`BaseView`]]. It can be used in the
 * child classes for initializing the state.
 */
export const BaseViewStateInitial: BaseViewState = {
    ...BaseComponentStateInitial,
}


/**
 * This class is the base class for all React components in the frontend that serve as views, i.e. fill the main content
 * area in the main frame. Its main purpose is to provide infrastructure for passing model id and rollback point ids
 * resolved from query-string parameters to the main frame. Furthermore, it provides helper functions for conveniently
 * navigating to other views and checking user access permissions.
 */
export class BaseView<PropsInterface extends BaseViewProps, StateInterface extends BaseViewState>
    extends BaseComponent<PropsInterface, StateInterface> {


    /**
     * This wrapper function can be used to trigger a routing request inside the React-Router-DOM without reloading the
     * entire page. It translates the given parameters into a relative URL that is then passed to the navigate function
     * given in the view component's properties.
     * @param to Target path such as '/time_series/overview'. This basis will then be appended with the provided
     * query-string parameters.
     * @param id_type Type of identifier such as 'model_id' or 'file_id'.
     * @param id UUID of requested resource
     * @param rollback_point_id UUID of rollback point.
     */
    navigate(to: FrontendURLPaths, id_type?: IDTypes, id?: UUID, rollback_point_id?: UUID) {
        let url: string = to;
        if (id_type !== undefined && id !== undefined && rollback_point_id !== undefined) {
            url += '?' + id_type + '=' + id + '&rollback_point_id=' + rollback_point_id
        } else if (id_type !== undefined && id !== undefined) {
            url += '?' + id_type + '=' + id
        }

        this.props.navigate(url, { replace: false });
    }


    /**
     * Convenience function to getLabelTextMessage from utils. Retrieves a label, text, or message as indicated by the
     * message type in the language requested by the user language props provided in the view. The message can be
     * further enriched by providing additional arguments to this function (rest arguments).This, however, requires
     * that the corresponding message text incorporates template expressions such as ${args[0]} or
     * ${args[1]}, etc...
     * @param message_type Type of message text being requested.
     * @param args Arbitrary number of additional arguments to be incorporated into the message string.
     * @returns The constructed message string in the desired language.
     */
    getLabelTextMessage(message_type: LabelTextMessageTypes, ...args: (string | number)[]): string {
        return getLabelTextMessage(message_type, this.props.user_language, ...args);
    }


    /**
     * Convenience function to getHTMLFormattedText from utils. Retrieves a html-formatted text as indicated by the
     * text type in the language requested by the user language props provided in the view.
     * @param text_type Type of text being requested.
     * @returns The html-formatted text in the desired language.
     */
    getHTMLFormattedText(text_type: HTMLFormattedTextTypes): React.JSX.Element {
        return getHTMLFormattedText(text_type, this.props.user_language);
    }


    /**
     * This routine checks whether the user has the given competence. If that is the case, it returns true, otherwise
     * false. This check is performed against the user_access_privilege property of this view. If the user has
     * Administrator-Rights as a competence, the user is always granted all access privileges.
     * @param competence String naming the competence to check.
     * @returns True if user has this competence.
     */
    checkUserCompetence(competence: BuiltInPermissionNames): boolean {
        return checkUserCompetence(this.props.user_access_privilege, competence);
    }


    /**
     * This routine checks whether the user has the given tag permission. If that is the case, it returns true,
     * otherwise false. This check is performed against the user_access_privilege property of this view. If the user
     * has Administrator-Rights as a competence, the user is always granted all access privileges.
     * @param tag_permission String naming the tag permission to check.
     * @returns True if user has this tag permission.
     */
    checkUserTagPermission(tag_permission: BuiltInPermissionNames): boolean {
        return checkUserTagPermission(this.props.user_access_privilege, tag_permission);
    }


    /**
     * This routine checks whether the user has the given competence / permission as a competence and a tag permission.
     * If that is the case, it returns true, otherwise false. This check is performed against the user_access_privilege
     * property of this view. If the user has Administrator-Rights as a competence, the user is always granted all
     * access privileges.
     * @param competence_permission String naming the competence / tag permission to check.
     * @returns True if user has this competence / permission as a competence and a tag permission.
     */
    checkUserCompetenceAndTagPermission(competence_permission: BuiltInPermissionNames): boolean {
        return checkUserCompetenceAndTagPermission(this.props.user_access_privilege, competence_permission);
    }


    /**
     * This routine checks whether the user is the owner of the currently active model. If that is the case, it
     * returns true, otherwise false. This check is performed against the user_access_privilege property of this view.
     * @returns True if user owns the currently active model, if any.
     */
    checkUserOwnsModel(): boolean {
        return checkUserOwnsModel(this.props.user_access_privilege);
    }

}
