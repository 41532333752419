import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {CallbackNoParams} from "../ui_general/GeneralInterfaces";


/**
* Definition of RBSOffCanvas Placements
*/
export enum RBSOffCanvasPlacements {
    LEFT = 'start',
    RIGHT = 'end',
    TOP = 'top',
    BOTTOM = 'bottom',
}


/**
 * Defines the properties of the React-Bootstrap OffCanvas component wrapper.
 */
interface RBSOffCanvasProps {
    /** Holds header title of the OffCanvas */
    header_title?: string;
    /** Extra CSS styling for the OffCanvas */
    extra_style?: React.CSSProperties;
    /** Callback for processing user requests to close the OffCanvas (via close button or clicks outside) */
    onClose?: CallbackNoParams;
    /** Boolean indicating whether the OffCanvas shall be displayed on screen */
    show: boolean;
    /** Indicates where the OffCanvas elements shall be placed on screen. */
    placement?: RBSOffCanvasPlacements;
    /** Indicates whether the backdrop shall be disabled. The backdrop is a shaded area on top of the original
     * screen content which has a blurring effect. Clicking on this area also closes the OffCanvas. With active
     * backdrop, the original content cannot be operated. */
    disable_backdrop?: boolean;
    /** Indicates whether scrolling off the original content shall be allowed. */
    allow_scrolling?: boolean;
    /** Indicates whether the OffCanvas shall not close on keyboard escape button pressed  */
    disable_close_on_escape?: boolean;
    /** Indicates whether a window close button shall be added in the upper right corner of the OffCanvas */
    show_close_button?: boolean;
    /** The element's children to be included in the OffCanvas body */
    children?: React.ReactNode;
}


/**
 * Defines the state of the React-Bootstrap OffCanvas component wrapper.
 */
interface RBSOffCanvasState {
}


/**
 * This class is a wrapper of the React-Bootstrap OffCanvas component.
 */
export class RBSOffCanvas extends React.Component<RBSOffCanvasProps, RBSOffCanvasState> {

    /**
     * Constructor. Initializes states.
     * @param props React properties passed from the calling instance.
     */
    constructor(props: RBSOffCanvasProps) {
        super(props);

        this.state = {
        };
    }


    /**
     * This routine is called from React whenever the component needs to be re-rendered. It renders the component on the
     * basis of the current state and properties.
     */
    render() {
        return (
            <Offcanvas
                show={this.props.show}
                style={this.props.extra_style}
                onHide={this.props.onClose ? this.props.onClose : undefined}
                placement={this.props.placement}
                scroll={this.props.allow_scrolling}
                backdrop={!this.props.disable_backdrop}
                keyboard={!this.props.disable_close_on_escape}
            >
                <Offcanvas.Header closeButton={this.props.show_close_button}>
                    <Offcanvas.Title>
                        {this.props.header_title}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {this.props.children}
                </Offcanvas.Body>
            </Offcanvas>
        );
    }
}
