import React from 'react';
import Card from 'react-bootstrap/Card';
import {CallbackNoParams} from "../ui_general/GeneralInterfaces";


/**
* Definition of RBSCard Variants
*/
export enum RBSCardVariants {
    Primary = 'primary',
    Secondary = 'secondary',
    Success = 'success',
    Danger = 'danger',
    Light = 'light',
}


/**
 * Defines the properties of the React-Bootstrap Card component wrapper.
 */
interface RBSCardProps {
    /** Holds the caption of the Card */
    caption?: string;
    /** Holds the header title of the Card */
    header?: string;
    /** Holds the footer of the Card */
    footer?: string;
    /** Holds the background variant of the Card */
    background?: RBSCardVariants;
    /** Holds the border variant of the Card */
    border?: RBSCardVariants;
    /** Extra CSS styling for the Card */
    extra_style?: React.CSSProperties;
    /** Extra CSS styling for the Card-Header */
    header_extra_style?: React.CSSProperties;
    /** Extra CSS styling for the Card-Text */
    body_text_extra_style?: React.CSSProperties;
    /**  Holds the source location of an image to include in the card */
    image_src?: string;
    /** Callback for processing user clicks on the Card */
    onClick?: CallbackNoParams;
    /** Text to be shown in the body of the Card */
    body_text?: string;
    /** The element's children to be included in the Cards body */
    children?: React.ReactNode;
}


/**
 * Defines the state of the React-Bootstrap Card component wrapper.
 */
interface RBSCardState {
}


/**
 * This class is a wrapper of the React-Bootstrap Card component.
 */
export class RBSCard extends React.Component<RBSCardProps, RBSCardState> {

    /**
     * Constructor. Initializes states.
     * @param props React properties passed from the calling instance.
     */
    constructor(props: RBSCardProps) {
        super(props);

        this.state = {
        };
    }


    /**
     * This routine is called from React whenever the component needs to be re-rendered. It renders the component on the
     * basis of the current state and properties.
     */
    render() {
        return (
            <Card
                style={this.props.extra_style}
                onClick={this.props.onClick}
                bg={this.props.background}
                border={this.props.border}
                text={!this.props.background || this.props.background === RBSCardVariants.Light ? 'dark' : 'white'}
            >
                {this.props.header ?
                    <Card.Header style={this.props.header_extra_style}>{this.props.header}</Card.Header>
                    :
                    undefined
                }

                {this.props.image_src ?
                    <Card.Img variant="top" src={this.props.image_src}/>
                    :
                    undefined
                }

                <Card.Body>
                    <Card.Title>{this.props.caption}</Card.Title>
                    <Card.Text style={this.props.body_text_extra_style}>
                        {this.props.body_text}
                    </Card.Text>

                    {this.props.children}
                </Card.Body>

                {this.props.footer ?
                    <Card.Header>{this.props.footer}</Card.Header>
                    :
                    undefined
                }
            </Card>
        );
    }
}
