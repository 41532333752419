import React from 'react';
import {BaseView, BaseViewProps, BaseViewState, BaseViewStateInitial} from "../base/ui_general/BaseView";
import {getQueryStringParameterAsUUID} from "../base/utils/GeneralUtilities";
import {MachineLearnerResult, MachineLearnerSpecification, UUID} from "../base/ui_general/GeneralInterfaces";
import {
    ColorPalette,
    FrontendURLPaths,
    HTMLFormattedTextTypes,
    IDTypes,
    LabelTextMessageTypes,
    ValidationSchemes
} from "../base/Settings";
import {UserGuidance} from "../base/ui_components/UserGuidance";
import Accordion from 'react-bootstrap/Accordion';
import {CenteringMargins, MiddleColumn, VerticalSpacing} from "../base/ui_general/GeneralFunctionComponents";
import {boundMethod} from "autobind-decorator";
import {getRequest_get_ml_model_learners} from "../api/ml_model_learners";
import {InputStateControl} from "../base/utils/InputStateControl";
import {RBSTextField} from "../base/rbs_components/RBSTextField";
import {getRequest_get_ml_model_analysis} from "../api/ml_model_analysis";
import Plot from "react-plotly.js";


/** This defines the properties of the MultivariateAnalysisOverView. */
interface MultivariateAnalysisOverViewProps extends BaseViewProps {
}


/** This defines the state of the MultivariateAnalysisOverView. */
interface MultivariateAnalysisOverViewState extends BaseViewState {
    /** UUID of selected model. */
    risk_model_id: UUID | undefined;
    /** UUID of selected rollback point. */
    rollback_point_id: UUID | undefined;
    /** Holds the list of machine learner specifications implemented on the server. */
    machine_learner_specifications: Array<MachineLearnerSpecification> | undefined;
    /** Holds the current results of all queried machine learners. */
    machine_learner_results: Record<string, Array<MachineLearnerResult>>;
    /** Indicates the progress of the machine learning */
    machine_learner_results_progress: Record<string, number>;
}


/**
 * This defines the MultivariateAnalysisOverView, which provides functionality to running multivariate analyses on the
 * reference data set (RDS) and applying different machine learning models.
 * Inherits from [[`BaseView`]].
 */
export class MultivariateAnalysisOverView
    extends BaseView<MultivariateAnalysisOverViewProps, MultivariateAnalysisOverViewState> {
    /** Holds the input state controller for the user configuration of the machine learner hyperparameters */
    private readonly hyperParameterInputStateControl: InputStateControl;
    /** Indicates whether there is a pending API request for the specified machine learner */
    private readonly machine_learner_results_requested: Record<string, boolean>;

    /**
     * Constructor. Initializing the view's state. Loads model id and rollback point id from browser's query string.
     * @param props The view's property object.
     */
    constructor(props: MultivariateAnalysisOverViewProps) {
        super(props);

        this.state = {
            ...BaseViewStateInitial,
            risk_model_id: getQueryStringParameterAsUUID(IDTypes.RISK_MODEL),
            rollback_point_id: getQueryStringParameterAsUUID(IDTypes.ROLLBACK_POINT),
            machine_learner_specifications: undefined,
            machine_learner_results: {},
            machine_learner_results_progress: {}
        };

        this.hyperParameterInputStateControl = new InputStateControl();
        this.machine_learner_results_requested = {};
    }


    /**
     * If the component did mount, the required parameters that have been extracted from the query string in the
     * constructor are passed to the Main Frame. Initial data load is triggered.
     */
    componentDidMount() {
        this.props.provideModelIDs(this.state.risk_model_id, this.state.rollback_point_id);
        this.load_learners();
    }


    /**
     * Triggers the load of the machine learner specifications and receives the results from the server.
     */
    @boundMethod
    load_learners() {
        if (this.state.risk_model_id) {
            this.processFetchRequest(
                getRequest_get_ml_model_learners(),
                (data: Array<MachineLearnerSpecification>) => {
                    this.setState({machine_learner_specifications: data})
                },
            );
        }
    }


    /**
     * Checks whether there are pending requests. If yes, no requests are triggered. Otherwise, the server is queried
     * for current machine learner results.
     */
    load_machine_learner_results() {
        this.state.machine_learner_specifications?.forEach(machine_learner_specification => {
            if (this.machine_learner_results_requested[machine_learner_specification.class_name]) return;
            if (this.state.machine_learner_results_progress[machine_learner_specification.class_name] &&
                this.state.machine_learner_results_progress[machine_learner_specification.class_name] >= 1) return;
            if (this.state.risk_model_id) {
                let hyperparameters: Record<string, number> = {}
                let hyperparameters_valid = true;
                machine_learner_specification.hyper_parameter_specifications.forEach(input_spec => {
                    const input_value = this.hyperParameterInputStateControl.getValueForKey(
                        machine_learner_specification.class_name + '//' + input_spec.variable_name
                    )
                    const input_valid = this.hyperParameterInputStateControl.getValidityForKey(
                        machine_learner_specification.class_name + '//' + input_spec.variable_name
                    )
                    if (input_value !== undefined && input_valid) {
                        hyperparameters[input_spec.variable_name] = Number(input_value)
                    } else hyperparameters_valid = false;
                })

                if (hyperparameters_valid) {
                    this.update_ml_results_request_status(machine_learner_specification.class_name, true);

                    this.processFetchRequest(
                        getRequest_get_ml_model_analysis(
                            this.state.risk_model_id,
                            machine_learner_specification.class_name,
                            machine_learner_specification.hyper_parameter_specifications.length > 0 ? JSON.stringify(hyperparameters) : ""
                        ),
                        (data: Array<MachineLearnerResult>) => {
                            this.update_ml_results_request_status(machine_learner_specification.class_name, false);
                            this.update_ml_results_request_progress(machine_learner_specification.class_name,
                                data.reduce((prev_run_count, current_ml_results) => prev_run_count + current_ml_results.degrees_of_freedom.length, 0) / 200
                            );
                            this.setState(state => {
                                let new_machine_learner_results = {
                                    ...state.machine_learner_results
                                }
                                new_machine_learner_results[machine_learner_specification.class_name] = data;
                                return {
                                    machine_learner_results: new_machine_learner_results
                                }
                            })
                        },
                    );
                }
            }
        })
    }


    /**
     * Updates the request status of the ml results for a given machine learner.
     * @param ml_class_name Name of the machine learner implementing class
     * @param requested Updated request status.
     */
    update_ml_results_request_status(ml_class_name: string, requested: boolean) {
        this.machine_learner_results_requested[ml_class_name] = requested;
    }


    /**
     * Updates the request progress of the ml result calculation for a given machine learner.
     * @param ml_class_name Name of the machine learner implementing class
     * @param progress Updated machine learning progress.
     */
    update_ml_results_request_progress(ml_class_name: string, progress: number) {
        this.setState(state => {
            let new_machine_learner_results_progress = {...state.machine_learner_results_progress}
            new_machine_learner_results_progress[ml_class_name] = progress
            return {
                machine_learner_results_progress: new_machine_learner_results_progress
            }
        })
    }



    /**
     * This function renders the view.
     */
    render() {
        this.load_machine_learner_results();

        return(
            <div>
                <UserGuidance
                    caption={this.getLabelTextMessage(LabelTextMessageTypes.MA_MULTIVARIATE_ANALYSIS_CAPTION)}
                    explanation={this.getLabelTextMessage(
                        LabelTextMessageTypes.MA_MULTIVARIATE_ANALYSIS_EXPLANATION
                    )}
                    previous_caption={this.getLabelTextMessage(LabelTextMessageTypes.UA_UNIVARIATE_ANALYSIS_CAPTION)}
                    previous_explanation={this.getLabelTextMessage(
                        LabelTextMessageTypes.UA_UNIVARIATE_ANALYSIS_EXPLANATION
                    )}
                    onPreviousSlide={() => this.navigate(
                        FrontendURLPaths.UNIVARIATE_ANALYSIS,
                        IDTypes.RISK_MODEL,
                        this.state.risk_model_id,
                        this.state.rollback_point_id)
                    }
                    details_html={this.getHTMLFormattedText(HTMLFormattedTextTypes.MA_MULTIVARIATE_ANALYSIS_DETAILS)}
                    user_language={this.props.user_language}
                />


                <MiddleColumn cols={8}>
                    <CenteringMargins width={'100%'}>
                        <Plot
                            useResizeHandler={true}
                            style={{width: "100%"}}

                            data={[{
                                name: "IS Backtesting",
                                x: this.state.machine_learner_specifications?.map((ml_spec, index) => "<b>" + ml_spec.name + "</b>"),
                                y: this.state.machine_learner_specifications?.map(ml_spec => this.state.machine_learner_results[ml_spec.class_name] ?
                                    this.state.machine_learner_results[ml_spec.class_name].reduce(
                                        (best, ml_results) => Math.max(best, ml_results.is_gini_values.reduce((prev, curr) => prev + curr, 0) / ml_results.is_gini_values.length), -1
                                    ) : 0).map(y => Math.round(10000 * y) / 10000),
                                type: 'bar',
                                marker: {
                                    color: ColorPalette.IS_BACKTESTING_TRANSPARENT,
                                    line: {
                                        color: ColorPalette.IS_BACKTESTING,
                                        width: 1.5
                                    }
                                }
                            }, {
                                name: "OOS Backtesting",
                                x: this.state.machine_learner_specifications?.map((ml_spec, index) => "<b>" + ml_spec.name + "</b>"),
                                y: this.state.machine_learner_specifications?.map(ml_spec => this.state.machine_learner_results[ml_spec.class_name] ?
                                    this.state.machine_learner_results[ml_spec.class_name].reduce(
                                        (best, ml_results) => Math.max(best, ml_results.oos_gini_values.reduce((prev, curr) => prev + curr, 0) / ml_results.oos_gini_values.length), -1
                                    ) : 0).map(y => Math.round(10000 * y) / 10000),
                                text: this.state.machine_learner_specifications?.map(ml_spec => this.state.machine_learner_results[ml_spec.class_name] ?
                                    this.state.machine_learner_results[ml_spec.class_name].reduce(
                                        (best, ml_results) => Math.max(best, ml_results.oos_gini_values.reduce((prev, curr) => prev + curr, 0) / ml_results.oos_gini_values.length), -1
                                    ) : 0).map(y => (Math.round(100 * y) / 100).toString()),
                                textposition: 'outside',
                                textfont: {
                                    color: ColorPalette.OOS_BACKTESTING,
                                },
                                type: 'bar',
                                marker: {
                                    color: ColorPalette.OOS_BACKTESTING_TRANSPARENT,
                                    line: {
                                        color: ColorPalette.OOS_BACKTESTING,
                                        width: 1.5
                                    }
                                }
                            }]}
                            layout={{
                                autosize: true,
                                title: '<b>' + this.getLabelTextMessage(LabelTextMessageTypes.MACHINE_LEARNER_COMPARISON) + '</b>',
                                xaxis: {
                                },
                                yaxis: {
                                    title: '<b>' + this.getLabelTextMessage(LabelTextMessageTypes.BEST_OBSERVED_GINI) + '</b>',
                                },
                                margin: {b: 150, l: 200},
                            }}
                        />
                    </CenteringMargins>

                    <Accordion defaultActiveKey="-1">
                        {this.state.machine_learner_specifications?.map(
                            (machine_learner_specification, index) => {
                                return (
                                    <Accordion.Item eventKey={index.toString()}>
                                        <Accordion.Header>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{minWidth: 400}}>
                                                            <b>
                                                                {machine_learner_specification.name}
                                                            </b>
                                                        </td>
                                                        <td>
                                                            {this.getLabelTextMessage(LabelTextMessageTypes.PROGRESS) + ' = ' + (Math.round(10000 * (this.state.machine_learner_results_progress[machine_learner_specification.class_name] ?
                                                                this.state.machine_learner_results_progress[machine_learner_specification.class_name] : 0)) / 100).toString() + '%'}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{paddingRight: 50}}>{this.getLabelTextMessage(LabelTextMessageTypes.DESCRIPTION) + ':'}</td>
                                                        <td>{machine_learner_specification.description}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            {machine_learner_specification.hyper_parameter_specifications.length === 0 ? undefined : <VerticalSpacing/> }

                                            {machine_learner_specification.hyper_parameter_specifications.map(
                                                hyper_spec => (
                                                    <RBSTextField
                                                        label={hyper_spec.name}
                                                        title={hyper_spec.description}
                                                        input_key={machine_learner_specification.class_name + '//' + hyper_spec.variable_name}
                                                        prefill={hyper_spec.default_json}
                                                        input_state_control={this.hyperParameterInputStateControl}
                                                        onChange={() => {
                                                            this.update_ml_results_request_progress(machine_learner_specification.class_name, 0);
                                                        }}
                                                        validation_schemes={[
                                                            ValidationSchemes.REQUIRED,
                                                            ValidationSchemes.NON_NEGATIVE
                                                        ].concat(hyper_spec.annotation.includes('int') ? [ValidationSchemes.INTEGER, ValidationSchemes.MIN_1] : ValidationSchemes.NUMBER)}
                                                    />
                                                )
                                            )}

                                            {!this.state.machine_learner_results[machine_learner_specification.class_name] ? undefined :
                                                <div>
                                                    <VerticalSpacing/>
                                                    <Plot
                                                        data={[{
                                                            name: "IS Backtesting",
                                                            x: this.state.machine_learner_results[machine_learner_specification.class_name].map(
                                                                ml_results => ml_results.degrees_of_freedom.reduce((prevSum, currentValue) => prevSum + currentValue, 0) / ml_results.degrees_of_freedom.length
                                                            ),
                                                            y: this.state.machine_learner_results[machine_learner_specification.class_name].map(
                                                                ml_results => ml_results.is_gini_values.reduce((prevSum, currentValue) => prevSum + currentValue, 0) / ml_results.is_gini_values.length
                                                            ),
                                                            type: 'scatter',
                                                            mode: 'lines+markers',
                                                            marker: {color: ColorPalette.IS_BACKTESTING},
                                                        }, {
                                                            name: "OOS Backtesting",
                                                            x: this.state.machine_learner_results[machine_learner_specification.class_name].map(
                                                                ml_results => ml_results.degrees_of_freedom.reduce((prevSum, currentValue) => prevSum + currentValue, 0) / ml_results.degrees_of_freedom.length
                                                            ),
                                                            y: this.state.machine_learner_results[machine_learner_specification.class_name].map(
                                                                ml_results => ml_results.oos_gini_values.reduce((prevSum, currentValue) => prevSum + currentValue, 0) / ml_results.oos_gini_values.length
                                                            ),
                                                            type: 'scatter',
                                                            mode: 'lines+markers',
                                                            marker: {color: ColorPalette.OOS_BACKTESTING},
                                                        }]}
                                                        layout={{
                                                            autosize: true,
                                                            title: '<b>' + this.getLabelTextMessage(LabelTextMessageTypes.MODEL_PERFORMANCE_VS_COMPLEXITY) + '</b>',
                                                            xaxis: {
                                                                title: {
                                                                    text: '<b>' + this.getLabelTextMessage(LabelTextMessageTypes.DEGREES_OF_FREEDOM) + '</b>',
                                                                }
                                                            },
                                                            yaxis: {
                                                                title: '<b>' + this.getLabelTextMessage(LabelTextMessageTypes.GINI_COEFFICIENT) + '</b>',
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            }

                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            }
                        )}
                    </Accordion>

                </MiddleColumn>

            </div>

        );
    }
}
