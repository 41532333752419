import {useNavigate} from "react-router-dom";
import {MainFrame} from "./MainFrame";


import React from 'react';
import './App.css';
import {FeedbackMessage} from "./base/ui_general/GeneralInterfaces";


export function App() {
    const navigate = useNavigate();
    const userID = "getUserID()";

    return (<MainFrame key={"UserID" + userID}
                       navigate={navigate}
                       displayMessage={(feedback_message: FeedbackMessage) => {
                       }}/>);

}
export default App;
