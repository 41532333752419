import React from 'react';
import {BaseView, BaseViewProps, BaseViewState, BaseViewStateInitial} from "../base/ui_general/BaseView";
import {digestUTCTimestamp, getQueryStringParameterAsUUID, readableFileSize} from "../base/utils/GeneralUtilities";
import {FileColumnMapping, FileUpload, FileUploadAssociation, UUID} from "../base/ui_general/GeneralInterfaces";
import {
    FrontendURLPaths,
    HTMLFormattedTextTypes,
    IDTypes,
    LabelTextMessageTypes,
    MimeTypesEnum,
    StandardChoiceCategoriesStr
} from "../base/Settings";
import {UserGuidance} from "../base/ui_components/UserGuidance";
import {RBSCard} from "../base/rbs_components/RBSCard";
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import {MiddleColumn, VerticalSpacing} from "../base/ui_general/GeneralFunctionComponents";
import {FileDropZone} from "../base/ui_components/FileDropZone";
import {boundMethod} from "autobind-decorator";
import {BsCloudUpload} from "react-icons/bs";
import {
    getRequest_get_risk_model_file_uploads,
    getRequest_upload_file_to_risk_model
} from "../api/risk_model_file_upload";
import {getRequest_get_risk_model_file_upload_associations} from "../api/risk_model_file_upload_association";
import {getRequest_get_risk_model_file_column_mapping} from "../api/risk_model_file_column_mapping";


/** This defines the properties of the FileUploadOverView. */
interface FileUploadOverViewProps extends BaseViewProps {
}


/** This defines the state of the FileUploadOverView. */
interface FileUploadOverViewState extends BaseViewState {
    /** UUID of selected model. */
    risk_model_id: UUID | undefined;
    /** UUID of selected rollback point. */
    rollback_point_id: UUID | undefined;
    /** Holds the list of file upload associations of the risk model */
    file_upload_associations: Array<FileUploadAssociation> | undefined;
    /** Holds the list of file uploads of the risk model */
    file_uploads: Array<FileUpload> | undefined;
    /** Holds the list of file column mappings of the risk model */
    file_column_mappings: Array<FileColumnMapping> | undefined;
}


/**
 * This defines the FileUploadOverView, which provides functionality to the user for uploading files that shall
 * be parsed to extract the data to be used in the risk modelling.
 * Inherits from [[`BaseView`]].
 */
export class FileUploadOverView extends BaseView<FileUploadOverViewProps, FileUploadOverViewState> {

    /**
     * Constructor. Initializing the view's state. Loads model id and rollback point id from browser's query string.
     * @param props The view's property object.
     */
    constructor(props: FileUploadOverViewProps) {
        super(props);

        this.state = {
            ...BaseViewStateInitial,
            risk_model_id: getQueryStringParameterAsUUID(IDTypes.RISK_MODEL),
            rollback_point_id: getQueryStringParameterAsUUID(IDTypes.ROLLBACK_POINT),
            file_uploads: undefined,
            file_upload_associations: undefined,
            file_column_mappings: undefined
        };
    }


    /**
     * If the component did mount, the required parameters that have been extracted from the query string in the
     * constructor are passed to the Main Frame.
     */
    componentDidMount() {
        this.props.provideModelIDs(this.state.risk_model_id, this.state.rollback_point_id);
        this.load_standard_choices_str([
            StandardChoiceCategoriesStr.CSVSeparatorsEnum,
            StandardChoiceCategoriesStr.CSVDecimalSeparatorEnum,
            StandardChoiceCategoriesStr.CSVThousandSeparatorEnum,
            StandardChoiceCategoriesStr.CSVDateFormatEnum,
        ]);
        this.load_files();
    }


    @boundMethod
    load_files() {
        if (this.state.risk_model_id) {
            this.processFetchRequest(
                getRequest_get_risk_model_file_upload_associations(this.state.risk_model_id),
                (data: Array<FileUploadAssociation>) => {
                    this.setState({file_upload_associations: data.sort(
                            (a, b) => (
                                a.name === b.name ? 0 : (a.name < b.name ? -1 : +1)
                            )
                        )})
                },
            );

            this.processFetchRequest(
                getRequest_get_risk_model_file_uploads(this.state.risk_model_id),
                (data: Array<FileUpload>) => {
                    this.setState({file_uploads: data})
                },
            );

            this.processFetchRequest(
                getRequest_get_risk_model_file_column_mapping(this.state.risk_model_id),
                (data: Array<FileColumnMapping>) => {
                    this.setState({file_column_mappings: data})
                },
            );
        }
    }


    /**
     * Callback for processing the upload of dropped files to the server.
     * @param files Array of files to upload to the server
     */
    @boundMethod
    upload_files(files: Array<File>) {
        files.forEach((file, ind) => {
            if (this.state.risk_model_id && this.state.file_upload_associations) this.processFetchRequest(
                getRequest_upload_file_to_risk_model(
                    "Uploaded File #" + (this.state.file_upload_associations.length + ind + 1),
                    "This file has been uploaded by the user.",
                    this.state.risk_model_id,
                    file
                ),
                () => {
                    this.load_files();
                },
            )
        })
    }


    /**
     * This function renders the view.
     */
    render() {
        return(
            <div>
                <UserGuidance
                    caption={this.getLabelTextMessage(LabelTextMessageTypes.FU_FILE_UPLOAD_CAPTION)}
                    explanation={this.getLabelTextMessage(
                        LabelTextMessageTypes.FU_FILE_UPLOAD_EXPLANATION
                    )}
                    next_caption={this.getLabelTextMessage(LabelTextMessageTypes.RC_RDS_CONSTRUCTION_CAPTION)}
                    next_explanation={this.getLabelTextMessage(
                        LabelTextMessageTypes.RC_RDS_CONSTRUCTION_EXPLANATION
                    )}
                    onNextSlide={() => this.navigate(
                        FrontendURLPaths.RDS_CONSTRUCTION,
                        IDTypes.RISK_MODEL,
                        this.state.risk_model_id,
                        this.state.rollback_point_id)
                    }
                    details_html={this.getHTMLFormattedText(HTMLFormattedTextTypes.FU_FILE_UPLOAD_DETAILS)}
                    user_language={this.props.user_language}
                />

                <VerticalSpacing/>

                <MiddleColumn cols={4} extra_style={{paddingLeft:250, paddingRight: 250}}>

                    <FileDropZone onDrop={this.upload_files}>
                        <RBSCard
                            header={this.getLabelTextMessage(LabelTextMessageTypes.DROP_ZONE_HINT)}
                        >
                            <MiddleColumn cols={4}>
                                {this.state.pending_fetch_requests ?
                                    <BsCloudUpload size={'100%'} color={'red'}/>
                                    :
                                    <BsCloudUpload size={'100%'} color={'darkblue'}/>
                                }

                            </MiddleColumn>
                        </RBSCard>
                    </FileDropZone>

                </MiddleColumn>

                <VerticalSpacing/>
                <VerticalSpacing/>
                <VerticalSpacing/>

                <MiddleColumn cols={8}>
                    <Accordion defaultActiveKey="-1">
                        {this.state.file_upload_associations ? this.state.file_upload_associations.map(
                            (file_upload_association, index) => {
                                let file_upload = this.state.file_uploads?.find(
                                    f => f.id === file_upload_association.file_upload_id
                                );
                                let file_column_mappings = this.state.file_column_mappings ? this.state.file_column_mappings.filter(
                                    map => map.file_upload_association_id === file_upload_association.id
                                ).sort(
                                    (a, b) => a.orig_col_name === b.orig_col_name ? 0 : (a.orig_col_name < b.orig_col_name ? -1 : +1)
                                ) : []
                                return (
                                    file_upload ? <Accordion.Item eventKey={index.toString()}>
                                        <Accordion.Header>
                                            <b>
                                                {file_upload_association.name}
                                            </b>
                                            <span style={{paddingLeft: 20}}/>
                                            {file_upload.file_name}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{paddingRight: 50}}>{this.getLabelTextMessage(LabelTextMessageTypes.FILE_TYPE) + ':'}</td>
                                                        <td>{file_upload.mime_type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{paddingRight: 50}}>{this.getLabelTextMessage(LabelTextMessageTypes.FILE_SIZE) + ':'}</td>
                                                        <td>{readableFileSize(file_upload.size)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{paddingRight: 50}}>{this.getLabelTextMessage(LabelTextMessageTypes.TIMESTAMP) + ':'}</td>
                                                        <td>{digestUTCTimestamp(file_upload.upload_timestamp).toLocaleString()}</td>
                                                    </tr>
                                                    {file_upload.mime_type === MimeTypesEnum.CSV ?
                                                        <>
                                                            <tr>
                                                                <td style={{paddingRight: 50}}>{this.getLabelTextMessage(LabelTextMessageTypes.CSV_SEPARATOR) + ':'}</td>
                                                                <td>{file_upload_association.csv_separator}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{paddingRight: 50}}>{this.getLabelTextMessage(LabelTextMessageTypes.CSV_DECIMAL_SEPARATOR) + ':'}</td>
                                                                <td>{file_upload_association.csv_decimal_separator}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{paddingRight: 50}}>{this.getLabelTextMessage(LabelTextMessageTypes.CSV_THOUSAND_SEPARATOR) + ':'}</td>
                                                                <td>{file_upload_association.csv_thousand_separator}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{paddingRight: 50}}>{this.getLabelTextMessage(LabelTextMessageTypes.CSV_DATE_FORMAT) + ':'}</td>
                                                                <td>{file_upload_association.csv_date_format}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{paddingRight: 50}}>{this.getLabelTextMessage(LabelTextMessageTypes.CSV_MISSING_PARAMETER_PLACEHOLDERS) + ':'}</td>
                                                                <td>{'[' + file_upload_association.csv_missing_placeholders?.join(', ') + ']'}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{paddingRight: 50}}>{this.getLabelTextMessage(LabelTextMessageTypes.COLUMN_HEADERS_INCLUDED) + ':'}</td>
                                                                <td>{file_upload_association.csv_column_headers_included ? this.getLabelTextMessage(LabelTextMessageTypes.YES) : this.getLabelTextMessage(LabelTextMessageTypes.NO)}</td>
                                                            </tr>

                                                        </>: undefined
                                                    }
                                                    <tr>
                                                        <td style={{paddingRight: 50}}>{this.getLabelTextMessage(LabelTextMessageTypes.DESCRIPTION) + ':'}</td>
                                                        <td>{file_upload_association.description}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            {file_column_mappings.length ?
                                                <div>
                                                    <VerticalSpacing/>
                                                    <VerticalSpacing/>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>{this.getLabelTextMessage(LabelTextMessageTypes.ORIG_COLUMN_NAME)}</th>
                                                                <th>{this.getLabelTextMessage(LabelTextMessageTypes.MAPPED_COLUMN_NAME)}</th>
                                                                <th>{this.getLabelTextMessage(LabelTextMessageTypes.COLUMN_TYPE)}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {file_column_mappings.map(map => (
                                                            <tr>
                                                                <td>{map.orig_col_name}</td>
                                                                <td>{map.mapped_col_name}</td>
                                                                <td>{map.col_type}</td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </Table>
                                                </div> : undefined
                                            }

                                        </Accordion.Body>
                                    </Accordion.Item> : undefined
                                )
                            }
                        ) : undefined }
                    </Accordion>

                </MiddleColumn>

            </div>

        );
    }
}
