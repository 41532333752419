import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import React from "react";
import {InputStateControl} from "../utils/InputStateControl";
import {TextfieldInputTypes, ValidationSchemes} from "../Settings";
import {boundMethod} from "autobind-decorator";
import {CallbackNoParams} from "../ui_general/GeneralInterfaces";


/**
 * Defines the properties of the React-Bootstrap Textfield component wrapper.
 */
interface RBSTextFieldProps {
    /** Label to appear in the floating text field */
    label: string;
    /** List of validation schemes underlying the validation assessment of the component's current value */
    validation_schemes?: Array<ValidationSchemes>;
    /** Key used for uniquely identifying this component. Is used for registering and looking up the component */
    input_key?: string;
    /** Determines the type of the input, thus changing the appearance of the input element */
    type?: TextfieldInputTypes;
    /** Prefill setting of the value state. Is used to initialize the component's value. */
    prefill?: string;
    /** Holds the input state controller, conducting input validation and making component's value accessible outside
     * of this class. */
    input_state_control?: InputStateControl;
    /** Holds the hover-over text to provide additional user guidance. */
    title?: string;
    /** Callback for handling on-change events of the textfield */
    onChange?: CallbackNoParams;
}


/**
 * Defines the state of the React-Bootstrap Textfield component wrapper.
 */
interface RBSTextFieldState {
    /** Holds current value of this component. This is the value that is rendered. */
    current_value: string;
    /** Hols the current validity assessment result. Determines the rendered validity feedback */
    valid: boolean | undefined;
}


/**
 * This class is a wrapper of the React-Bootstrap Textfield component.
 */
export class RBSTextField extends React.Component<RBSTextFieldProps, RBSTextFieldState> {
    /** Holds the component's identifier user for registration and lookup */
    private component_id: number | undefined = undefined;

    /**
     * Constructor. Initializes states.
     * @param props React properties passed from the calling instance. The prefill setting for the current value is set
     * and the validity flag is set as undefined.
     */
    constructor(props: RBSTextFieldProps) {
        super(props);
        this.state = {
            current_value: this.props.prefill ? this.props.prefill : "",
            valid: undefined
        };
    }


    /**
     * Is called from React after this component has been created, rendered and mounted to the DOM tree.
     * Registers the component with the input state controller provided in the props (if any). In that cae, the
     * registration id is saved in the variable component_id. Also, the input state controller's update function is
     * called with the current value setting.
     */
    componentDidMount() {
        if (this.props.input_state_control) {
            this.component_id = this.props.input_state_control.registerComponent(
                this.props.input_key,
                this.props.validation_schemes,
                this.validityUpdate
            );

            if (this.component_id) this.props.input_state_control.updateValue(
                this.component_id,
                this.state.current_value
            );
        }
    }


    /**
     * Is called from React immediately before component will be destroyed and unmounted from the DOM tree. If an
     * input state controller has been provided, the component is deregistered and the component_id variable is cleared.
     */
    componentWillUnmount() {
        if (this.props.input_state_control && this.component_id) {
            this.props.input_state_control.deregisterComponent(this.component_id)
        }
        this.component_id = undefined;
    }


    /**
     * Callback function for receiving updates on the component's validity assessment.
     * @param valid True, if component's value is valid, false otherwise.
     */
    @boundMethod
    validityUpdate(valid: boolean) {
        this.setState({
            valid: valid
        })
    }


    /**
     * Renders the textfield component.
     * @returns An React.JSX.Element providing the textfield component.
     */
    render() {
        return (
            <FloatingLabel
                controlId={this.props.input_key ? this.props.input_key : "floatingInput"}
                label={this.props.label}
                title={this.props.title}
                className="mb-3"
            >
                <Form.Control
                    type={this.props.type}
                    value={this.state.current_value}
                    onChange={change_event => {
                        this.setState({current_value: change_event.target.value});
                        if (this.props.input_state_control && this.component_id) {
                            this.props.input_state_control.updateValue(this.component_id, change_event.target.value);
                        }
                        if (this.props.onChange) this.props.onChange();
                    }}
                    isValid={!this.props.validation_schemes ? undefined : this.state.valid}
                    isInvalid={!this.props.validation_schemes || this.state.valid === undefined ? undefined : !this.state.valid}
                />
            </FloatingLabel>
        );
    }
}
