import React from 'react';
import {BaseView, BaseViewProps, BaseViewState, BaseViewStateInitial} from "../base/ui_general/BaseView";
import {getQueryStringParameterAsUUID} from "../base/utils/GeneralUtilities";
import {RiskModel, UUID} from "../base/ui_general/GeneralInterfaces";
import {UserGuidance} from "../base/ui_components/UserGuidance";
import {FrontendURLPaths, HTMLFormattedTextTypes, IDTypes, LabelTextMessageTypes} from "../base/Settings";
import {MiddleColumn, VerticalSpacing} from "../base/ui_general/GeneralFunctionComponents";
import Table from "react-bootstrap/Table";
import {getRequest_get_all_risk_models} from "../api/risk_model";
import {RBSButton, RBSButtonSizes, RBSButtonVariants} from "../base/rbs_components/RBSButton";


/** This defines the properties of the ModelSelectionOverView. */
interface ModelSelectionOverViewProps extends BaseViewProps {
}


/** This defines the state of the ModelSelectionOverView. */
interface ModelSelectionOverViewState extends BaseViewState {
    /** UUID of selected model. */
    risk_model_id: UUID | undefined;
    /** UUID of selected rollback point. */
    rollback_point_id: UUID | undefined;
    /** Array of risk model objects to be shown in the view as selectable models. */
    risk_models: Array<RiskModel> | undefined;
    /** Array containing the scopes providing a folder like structure for organizing models*/
    //scopes:  Array<Scope>;
    /** Array containing model scope relations */
    //model_scope_relations: Array<TransactionScopeRelationWithRollbackPoint>;
}


/**
 * This defines the ModelSelectionOverView, which is used to display selectable models for loading. It can also be used
 * to create, edit or delete a model. Inherits from [[`BaseView`]].
 */
export class ModelSelectionOverView extends BaseView<ModelSelectionOverViewProps, ModelSelectionOverViewState> {

    /**
     * Constructor. Initializing the view's state. Loads model id and rollback point id from browser's query string.
     * @param props The view's property object.
     */
    constructor(props: ModelSelectionOverViewProps) {
        super(props);

        this.state = {
            ...BaseViewStateInitial,
            risk_model_id: getQueryStringParameterAsUUID(IDTypes.RISK_MODEL),
            rollback_point_id: getQueryStringParameterAsUUID(IDTypes.ROLLBACK_POINT),
            risk_models: undefined
        };
    }


    /**
     * If the component did mount, the required parameters that have been extracted from the query string in the
     * constructor are passed to the Main Frame. Afterward the function {@link load_data} is called initially
     * to request all models, scopes and model scope relations from the API.
     */
    componentDidMount() {
        this.props.provideModelIDs(this.state.risk_model_id, this.state.rollback_point_id);
        this.load_data();
    }


    /**
     * Triggers API calls to the backend server to load all risk models, scopes, and model scope relations.
     */
    load_data() {
        this.processFetchRequest(
            getRequest_get_all_risk_models(),
            (data: Array<RiskModel>) => {
                this.setState({risk_models: data})
            },
        );
    }


    /**
     * This function renders the view.
     */
    render() {
        return(
            <div>
                <UserGuidance
                    caption={this.getLabelTextMessage(LabelTextMessageTypes.MS_MODEL_SELECTION_CAPTION)}
                    explanation={this.getLabelTextMessage(
                        LabelTextMessageTypes.MS_MODEL_SELECTION_EXPLANATION
                    )}
                    details_html={this.getHTMLFormattedText(HTMLFormattedTextTypes.MS_MODEL_SELECTION_DETAILS)}
                    user_language={this.props.user_language}
                />

                <VerticalSpacing/>

                <MiddleColumn cols={8}>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>{this.getLabelTextMessage(LabelTextMessageTypes.RISK_MODEL_NAME)}</th>
                            <th>{this.getLabelTextMessage(LabelTextMessageTypes.DESCRIPTION)}</th>
                            <th>{this.getLabelTextMessage(LabelTextMessageTypes.ACTIONS)}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.risk_models?.map(risk_model => (
                            <tr>
                                <td>{risk_model.name}</td>
                                <td>{risk_model.description}</td>
                                <td>
                                    <RBSButton
                                        label={this.getLabelTextMessage(LabelTextMessageTypes.LOAD)}
                                        size={RBSButtonSizes.SMALL}
                                        variant={RBSButtonVariants.OUTLINE_PRIMARY}
                                        onClick={() => {
                                            this.navigate(
                                                FrontendURLPaths.MULTIVARIATE_ANALYSIS,
                                                IDTypes.RISK_MODEL,
                                                risk_model.id
                                            )
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </MiddleColumn>
            </div>
        );
    }
}
