import React from 'react';
import {BaseView, BaseViewProps, BaseViewState, BaseViewStateInitial} from "../base/ui_general/BaseView";
import {getQueryStringParameterAsUUID} from "../base/utils/GeneralUtilities";
import {FileColumnMapping, FileUploadAssociation, RDSDefinition, UUID} from "../base/ui_general/GeneralInterfaces";
import {
    FrontendURLPaths,
    HTMLFormattedTextTypes,
    IDTypes, InputColumnTypes,
    InputVariableTypes,
    LabelTextMessageTypes,
    TargetVariableTypes
} from "../base/Settings";
import {UserGuidance} from "../base/ui_components/UserGuidance";
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {MiddleColumn, VerticalSpacing} from "../base/ui_general/GeneralFunctionComponents";
import {boundMethod} from "autobind-decorator";
import {getRequest_get_risk_model_file_upload_associations} from "../api/risk_model_file_upload_association";
import {getRequest_get_risk_model_file_column_mapping} from "../api/risk_model_file_column_mapping";
import {
    getRequest_create_rds_definition,
    getRequest_get_rds_definitions,
    getRequest_update_rds_definition
} from "../api/risk_model_rds_definition";
import {RBSButton, RBSButtonSizes, RBSButtonVariants} from "../base/rbs_components/RBSButton";


/** This extended interface holds a file column mapping and the associated rds definition (if any). */
interface FileColumnMappingExtended extends FileColumnMapping {
    rds_definition: RDSDefinition | undefined;
}


/** This defines the properties of the RDSConstructionOverView. */
interface RDSConstructionOverViewProps extends BaseViewProps {
}


/** This defines the state of the RDSConstructionOverView. */
interface RDSConstructionOverViewState extends BaseViewState {
    /** UUID of selected model. */
    risk_model_id: UUID | undefined;
    /** UUID of selected rollback point. */
    rollback_point_id: UUID | undefined;
    /** Holds the list of file upload associations of the risk model */
    file_upload_associations: Array<FileUploadAssociation> | undefined;
    /** Holds the list of file column mappings of the risk model */
    file_column_mappings: Array<FileColumnMappingExtended> | undefined;
    /** Holds the list of rds definition entries of the risk model */
    rds_definitions: Array<RDSDefinition> | undefined;
}


/**
 * This defines the RDSConstructionOverView, which provides functionality to the user for defining the reference data
 * set (RDS) that is underlying the model construction in the risk modelling.
 * Inherits from [[`BaseView`]].
 */
export class RDSConstructionOverView extends BaseView<RDSConstructionOverViewProps, RDSConstructionOverViewState> {

    /**
     * Constructor. Initializing the view's state. Loads model id and rollback point id from browser's query string.
     * @param props The view's property object.
     */
    constructor(props: RDSConstructionOverViewProps) {
        super(props);

        this.state = {
            ...BaseViewStateInitial,
            risk_model_id: getQueryStringParameterAsUUID(IDTypes.RISK_MODEL),
            rollback_point_id: getQueryStringParameterAsUUID(IDTypes.ROLLBACK_POINT),
            file_upload_associations: undefined,
            file_column_mappings: undefined,
            rds_definitions: undefined
        };
    }


    /**
     * If the component did mount, the required parameters that have been extracted from the query string in the
     * constructor are passed to the Main Frame.
     */
    componentDidMount() {
        this.props.provideModelIDs(this.state.risk_model_id, this.state.rollback_point_id);
        this.load_files();
    }


    /**
     * Loads the rds definition for the current risk model from the server.
     */
    @boundMethod
    load_rds_definitions() {
        if (this.state.risk_model_id) {
            this.processFetchRequest(
                getRequest_get_rds_definitions(this.state.risk_model_id),
                (data: Array<RDSDefinition>) => {
                    this.setState({rds_definitions: data})
                },
            );
        }
    }


    /**
     * Loads the file associations, file column mappings, and rds definitions for the current risk model from the
     * server.
     */
    @boundMethod
    load_files() {
        if (this.state.risk_model_id) {
            this.processFetchRequest(
                getRequest_get_risk_model_file_upload_associations(this.state.risk_model_id),
                (data: Array<FileUploadAssociation>) => {
                    this.setState({file_upload_associations: data.sort(
                            (a, b) => (
                                a.name === b.name ? 0 : (a.name < b.name ? -1 : +1)
                            )
                        )})
                },
            );

            this.load_rds_definitions();

            this.processFetchRequest(
                getRequest_get_risk_model_file_column_mapping(this.state.risk_model_id),
                (data: Array<FileColumnMappingExtended>) => {
                    this.setState({file_column_mappings: data})
                },
            );
        }
    }


    /**
     * Updates the rds definitions on the server. The current state of the rds definition is embedded in the provided
     * file column mapping object. The intended state is encoded via the parameters input_variable_type and
     * target_variable_type.
     * @param file_column_mapping_extended The file column mapping that the rds definition entry refers to.
     * @param input_variable_type The input variable type encoded the intended variable type to be selected.
     * @param target_variable_type The target variable type encoded the intended variable type to be selected.
     */
    @boundMethod
    updateRDSDefinition(file_column_mapping_extended: FileColumnMappingExtended,
                        input_variable_type?: InputVariableTypes,
                        target_variable_type?: TargetVariableTypes) {
        if (!file_column_mapping_extended.rds_definition) {
            this.processFetchRequest(
                getRequest_create_rds_definition(
                    file_column_mapping_extended.id,
                    input_variable_type,
                    target_variable_type
                ),
                () => {
                    this.load_rds_definitions();
                },
            );
        } else {
            this.processFetchRequest(
                getRequest_update_rds_definition(
                    file_column_mapping_extended.rds_definition.id,
                    input_variable_type,
                    target_variable_type
                ),
                () => {
                    this.load_rds_definitions();
                },
            );

        }
    }


    /**
     * This function renders the view.
     */
    render() {
        return(
            <div>
                <UserGuidance
                    caption={this.getLabelTextMessage(LabelTextMessageTypes.RC_RDS_CONSTRUCTION_CAPTION)}
                    explanation={this.getLabelTextMessage(
                        LabelTextMessageTypes.RC_RDS_CONSTRUCTION_EXPLANATION
                    )}
                    previous_caption={this.getLabelTextMessage(LabelTextMessageTypes.FU_FILE_UPLOAD_CAPTION)}
                    previous_explanation={this.getLabelTextMessage(
                        LabelTextMessageTypes.FU_FILE_UPLOAD_EXPLANATION
                    )}
                    next_caption={this.getLabelTextMessage(LabelTextMessageTypes.UA_UNIVARIATE_ANALYSIS_CAPTION)}
                    next_explanation={this.getLabelTextMessage(
                        LabelTextMessageTypes.UA_UNIVARIATE_ANALYSIS_EXPLANATION
                    )}
                    onPreviousSlide={() => this.navigate(
                        FrontendURLPaths.FILE_UPLOAD,
                        IDTypes.RISK_MODEL,
                        this.state.risk_model_id,
                        this.state.rollback_point_id)
                    }
                    onNextSlide={() => this.navigate(
                        FrontendURLPaths.UNIVARIATE_ANALYSIS,
                        IDTypes.RISK_MODEL,
                        this.state.risk_model_id,
                        this.state.rollback_point_id)
                    }
                    details_html={this.getHTMLFormattedText(HTMLFormattedTextTypes.RC_RDS_CONSTRUCTION_DETAILS)}
                    user_language={this.props.user_language}
                />

                <VerticalSpacing/>


                <MiddleColumn cols={8}>
                    <Accordion defaultActiveKey="0">
                        {this.state.file_upload_associations ? this.state.file_upload_associations.map(
                            (file_upload_association, index) => {
                                let file_column_mappings = this.state.file_column_mappings ? this.state.file_column_mappings.filter(
                                    map => map.file_upload_association_id === file_upload_association.id
                                ).sort(
                                    (a, b) => a.orig_col_name === b.orig_col_name ? 0 : (a.orig_col_name < b.orig_col_name ? -1 : +1)
                                ) : []
                                file_column_mappings.forEach(map => {
                                    map.rds_definition = this.state.rds_definitions?.find(rds_def => rds_def.file_column_mapping_id === map.id)
                                })
                                return (
                                    <Accordion.Item eventKey={index.toString()}>
                                        <Accordion.Header>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{minWidth: 400}}>
                                                            <b>
                                                                {file_upload_association.name}
                                                            </b>
                                                        </td>
                                                        <td>
                                                            <RBSButton
                                                                label={this.getLabelTextMessage(LabelTextMessageTypes.SELECT_ALL)}
                                                                size={RBSButtonSizes.SMALL}
                                                                variant={RBSButtonVariants.OUTLINE_PRIMARY}
                                                                onClick={() => {
                                                                    file_column_mappings.forEach(map => {
                                                                        if (map.col_type === InputColumnTypes.NUMERIC) this.updateRDSDefinition(
                                                                            map,
                                                                            InputVariableTypes.NUMERICAL,
                                                                            undefined
                                                                        );
                                                                        if (map.col_type === InputColumnTypes.STRING) this.updateRDSDefinition(
                                                                            map,
                                                                            InputVariableTypes.CATEGORICAL,
                                                                            undefined
                                                                        );
                                                                    })
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <RBSButton
                                                                label={this.getLabelTextMessage(LabelTextMessageTypes.UNSELECT_ALL)}
                                                                size={RBSButtonSizes.SMALL}
                                                                variant={RBSButtonVariants.OUTLINE_PRIMARY}
                                                                onClick={() => {
                                                                    file_column_mappings.forEach(map => {
                                                                        this.updateRDSDefinition(map, undefined, undefined)
                                                                    })
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Accordion.Header>
                                        <Accordion.Body>

                                            {file_column_mappings.length ?
                                                <div>
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>{this.getLabelTextMessage(LabelTextMessageTypes.MAPPED_COLUMN_NAME)}</th>
                                                                <th>{this.getLabelTextMessage(LabelTextMessageTypes.COLUMN_TYPE)}</th>
                                                                <th>{this.getLabelTextMessage(LabelTextMessageTypes.RDS_VARIABLE)}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {file_column_mappings.map(map => (
                                                            <tr>
                                                                <td>{map.mapped_col_name}</td>
                                                                <td>{map.col_type}</td>
                                                                <td>
                                                                    <DropdownButton
                                                                        variant={map.rds_definition?.target_variable_type || map.rds_definition?.input_variable_type ? (
                                                                            map.rds_definition.target_variable_type ? 'danger' : (map.rds_definition.input_variable_type === InputVariableTypes.CATEGORICAL ? 'primary' : 'success')
                                                                        ) : 'secondary'}
                                                                        title={map.rds_definition ? (
                                                                            map.rds_definition.target_variable_type === TargetVariableTypes.BINARY ?
                                                                                this.getLabelTextMessage(LabelTextMessageTypes.TARGET_VAR_BINARY) : (
                                                                                    map.rds_definition.target_variable_type === TargetVariableTypes.MULTI_CATEGORICAL ?
                                                                                        this.getLabelTextMessage(LabelTextMessageTypes.TARGET_VAR_MULTI_CATEGORICAL) : (
                                                                                            map.rds_definition.target_variable_type === TargetVariableTypes.NON_NEGATIVE ?
                                                                                                this.getLabelTextMessage(LabelTextMessageTypes.TARGET_VAR_NON_NEGATIVE) : (
                                                                                                    map.rds_definition.target_variable_type === TargetVariableTypes.UNBOUNDED ?
                                                                                                        this.getLabelTextMessage(LabelTextMessageTypes.TARGET_VAR_UNBOUNDED) : (
                                                                                                            map.rds_definition.input_variable_type === InputVariableTypes.NUMERICAL ?
                                                                                                                this.getLabelTextMessage(LabelTextMessageTypes.INPUT_VAR_NUMERICAL) : (
                                                                                                                    map.rds_definition.input_variable_type === InputVariableTypes.CATEGORICAL ?
                                                                                                                        this.getLabelTextMessage(LabelTextMessageTypes.INPUT_VAR_CATEGORICAL) : '---'
                                                                                                                )
                                                                                                        )
                                                                                                )
                                                                                        )
                                                                                )
                                                                        ) : '---'}
                                                                        size="sm"
                                                                    >
                                                                        {map.col_type !== InputColumnTypes.DATE ?
                                                                            <React.Fragment>
                                                                                {map.col_type === InputColumnTypes.NUMERIC ? <Dropdown.Item onClick={() => this.updateRDSDefinition(map, InputVariableTypes.NUMERICAL)}>{this.getLabelTextMessage(LabelTextMessageTypes.INPUT_VAR_NUMERICAL)}</Dropdown.Item> : undefined}
                                                                                <Dropdown.Item onClick={() => this.updateRDSDefinition(map, InputVariableTypes.CATEGORICAL)}>{this.getLabelTextMessage(LabelTextMessageTypes.INPUT_VAR_CATEGORICAL)}</Dropdown.Item>
                                                                                <Dropdown.Divider />
                                                                                <Dropdown.Item onClick={() => this.updateRDSDefinition(map, undefined, TargetVariableTypes.BINARY)}>{this.getLabelTextMessage(LabelTextMessageTypes.TARGET_VAR_BINARY)}</Dropdown.Item>
                                                                                <Dropdown.Item onClick={() => this.updateRDSDefinition(map, undefined, TargetVariableTypes.MULTI_CATEGORICAL)}>{this.getLabelTextMessage(LabelTextMessageTypes.TARGET_VAR_MULTI_CATEGORICAL)}</Dropdown.Item>
                                                                                {map.col_type === InputColumnTypes.NUMERIC ?
                                                                                    <React.Fragment>
                                                                                        <Dropdown.Item onClick={() => this.updateRDSDefinition(map, undefined, TargetVariableTypes.NON_NEGATIVE)}>{this.getLabelTextMessage(LabelTextMessageTypes.TARGET_VAR_NON_NEGATIVE)}</Dropdown.Item>
                                                                                        <Dropdown.Item onClick={() => this.updateRDSDefinition(map, undefined, TargetVariableTypes.UNBOUNDED)}>{this.getLabelTextMessage(LabelTextMessageTypes.TARGET_VAR_UNBOUNDED)}</Dropdown.Item>
                                                                                    </React.Fragment> :
                                                                                    undefined
                                                                                }
                                                                                <Dropdown.Divider />
                                                                                <Dropdown.Item onClick={() => this.updateRDSDefinition(map, undefined, undefined)}>---</Dropdown.Item>
                                                                            </React.Fragment> :
                                                                            <Dropdown.Item onClick={() => this.updateRDSDefinition(map, undefined, undefined)}>---</Dropdown.Item>
                                                                        }
                                                                    </DropdownButton>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </Table>
                                                </div> : undefined
                                            }

                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            }
                        ) : undefined }
                    </Accordion>

                </MiddleColumn>

            </div>

        );
    }
}
