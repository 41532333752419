import {UserLanguages, HTMLFormattedTextTypes} from "./Settings";
import React from "react";


/**
 * Defines all html-formatted texts displayed in the frontend.
 */
export const HTMLFormattedTexts: Record<HTMLFormattedTextTypes, Record<UserLanguages, React.JSX.Element>> = {
    [HTMLFormattedTextTypes.LP_CREATE_OR_LOAD_DETAILS]: {
        [UserLanguages.EN]: <div>
            <p>The CRS Model Builder is a powerful tool that allows building complex credit risk models easily.</p>
            <p>You can choose to create a new model from scratch by clicking on the respective item below:</p>
            <dl>
                <dt>Create PD Model</dt>
                <dd>Choose this option if you would like to create a new PD model from scratch</dd>
                <dt>Create LGD Model</dt>
                <dd>Choose this option if you would like to create a new LGD model from scratch</dd>
                <dt>Create CCF Model</dt>
                <dd>Choose this option if you would like to create a new CCF model from scratch</dd>
            </dl>
            <p>If you are using the system for the first time, this is the choice to take.</p>
            <p>The created model and the modelling choices made by the user are recorded in a database on the server,
                allowing to interrupt and continue the work at any stage. If you have already created a model and want
                to continue working on this already existing model, please choose the following option:</p>
            <dl>
                <dt>Load Model</dt>
                <dd>Choose this option if you would like to continue working on an already existing model</dd>
            </dl>
        </div>,
        [UserLanguages.DE]: <div>
            <p>Der CRS Model Builder ist ein leistungsstarkes Werkzeug, mit dem komplexe Kreditrisikomodelle einfach
                erstellt werden können.</p>
            <p>Sie können wählen, ein neues Modell von Grund auf zu erstellen, indem Sie unten auf den entsprechenden
                Eintrag klicken:</p>
            <dl>
                <dt>Create PD Model</dt>
                <dd>Wählen Sie diese Option aus, wenn Sie ein neues PD-Modell von Grund auf erstellen möchten</dd>
                <dt>Create LGD Model</dt>
                <dd>Wählen Sie diese Option aus, wenn Sie ein neues LGD-Modell von Grund auf erstellen möchten</dd>
                <dt>Create CCF Model</dt>
                <dd>Wählen Sie diese Option aus, wenn Sie ein neues CCF-Modell von Grund auf erstellen möchten</dd>
            </dl>
            <p>Wenn Sie das System zum ersten Mal verwenden, ist dies die richtige Wahl.</p>
            <p>Das erstellte Modell und die Modellierungsentscheidungen des Benutzers werden in einer Datenbank auf dem
                Server aufgezeichnet, sodass die Arbeit an jeder Stelle unterbrochen und fortgesetzt werden kann. Wenn
                Sie bereits ein Modell erstellt haben und die Arbeit an diesem bereits vorhandenen Modell fortsetzen
                möchten, wählen Sie bitte die folgende Option aus:</p>
            <dl>
                <dt>Modell Laden</dt>
                <dd>Wählen Sie diese Option aus, wenn Sie weiterhin an einem bereits vorhandenen Modell arbeiten
                    möchten
                </dd>
            </dl>
        </div>,
        [UserLanguages.ES]: <div>
            <p>El Constructor de Modelos CRS es una herramienta poderosa que permite construir fácilmente modelos de
                riesgo crediticio complejos.</p>
            <p>Puede elegir crear un nuevo modelo desde cero haciendo clic en el elemento correspondiente a
                continuación:</p>
            <dl>
                <dt>Crear Modelo PD</dt>
                <dd>Elija esta opción si desea crear un nuevo modelo PD desde cero</dd>
                <dt>Crear Modelo LGD</dt>
                <dd>Elija esta opción si desea crear un nuevo modelo LGD desde cero</dd>
                <dt>Crear Modelo CCF</dt>
                <dd>Elija esta opción si desea crear un nuevo modelo CCF desde cero</dd>
            </dl>
            <p>Si está utilizando el sistema por primera vez, esta es la opción a elegir.</p>
            <p>El modelo creado y las elecciones de modelado realizadas por el usuario se registran en una base de datos
                en el servidor, lo que permite interrumpir y continuar el trabajo en cualquier etapa. Si ya ha creado un
                modelo y desea continuar trabajando en este modelo ya existente, elija la siguiente opción:</p>
            <dl>
                <dt>Cargar Modelo</dt>
                <dd>Elija esta opción si desea continuar trabajando en un modelo ya existente</dd>
            </dl>
        </div>,
        [UserLanguages.FR]: <div>
            <p>Le constructeur de modèles CRS est un outil puissant qui permet de créer facilement des modèles de risque
                de crédit complexes.</p>
            <p>Vous pouvez choisir de créer un nouveau modèle à partir de zéro en cliquant sur l'élément correspondant
                ci-dessous :</p>
            <dl>
                <dt>Créer un modèle PD</dt>
                <dd>Choisissez cette option si vous souhaitez créer un nouveau modèle PD à partir de zéro</dd>
                <dt>Créer un modèle LGD</dt>
                <dd>Choisissez cette option si vous souhaitez créer un nouveau modèle LGD à partir de zéro</dd>
                <dt>Créer un modèle CCF</dt>
                <dd>Choisissez cette option si vous souhaitez créer un nouveau modèle CCF à partir de zéro</dd>
            </dl>
            <p>Si vous utilisez le système pour la première fois, c'est le choix à prendre.</p>
            <p>Le modèle créé et les choix de modélisation effectués par l'utilisateur sont enregistrés dans une base de
                données sur le serveur, ce qui permet d'interrompre et de continuer le travail à tout moment. Si vous
                avez déjà créé un modèle et souhaitez continuer à travailler sur ce modèle déjà existant, veuillez
                choisir l'option suivante :</p>
            <dl>
                <dt>Charger le modèle</dt>
                <dd>Choisissez cette option si vous souhaitez continuer à travailler sur un modèle déjà existant</dd>
            </dl>
        </div>,
    },
    [HTMLFormattedTextTypes.FU_FILE_UPLOAD_DETAILS]: {
        [UserLanguages.EN]: <div>
            <p>The CRS Model Builder constructs risk models based on data provided by the user.
                This data can be provided by dragging one or several files into the area below.
            </p>
            <p>The following file formats are supported and can be read by the CRS Model Builder: </p>
            <dl>
                <dt>CSV</dt>
                <dd>Data can be provided as csv-text file using a semicolon or comma as column separator.</dd>
                <dt>Excel</dt>
                <dd>Data can be provided as xlsx-Excel file containing one or several sheets.</dd>
                <dt>SAS</dt>
                <dd>Data can be provided as sas7bdat-SAS data file.</dd>
            </dl>
            <p>For automatically processing the data in the proper file format, please use the adequate file extension,
                i.e., .csv, .xlsx, or .sas7bdat.
            </p>
            <p>For csv-text files, the column separator and the decimal separator are automatically detected.
                Please check whether the system has correctly identified these separators for the uploaded csv files
                in the list at the bottom of the page.
            </p>
        </div>,
        [UserLanguages.DE]: <div>
            <p>Der CRS Model Builder erstellt Risikomodelle auf Basis der vom Benutzer bereitgestellten Daten.
                Diese Daten können durch Ziehen einer oder mehrerer Dateien in den unten stehenden Bereich
                bereitgestellt werden.
            </p>
            <p>Folgende Dateiformate werden vom CRS Model Builder unterstützt und können gelesen werden: </p>
            <dl>
                <dt>CSV</dt>
                <dd>Daten können als csv-Textdatei bereitgestellt werden, indem ein Semikolon oder Komma als
                    Spaltentrennzeichen verwendet wird.
                </dd>
                <dt>Excel</dt>
                <dd>Daten können als xlsx-Excel-Datei mit einer oder mehreren Tabellenblättern bereitgestellt werden.
                </dd>
                <dt>SAS</dt>
                <dd>Daten können als sas7bdat-SAS-Datendatei bereitgestellt werden.</dd>
            </dl>
            <p>Für die automatische Verarbeitung der Daten im richtigen Dateiformat verwenden Sie bitte die
                entsprechende Dateierweiterung,
                d.h. .csv, .xlsx oder .sas7bdat.
            </p>
            <p>Bei csv-Textdateien werden das Spaltentrennzeichen und das Dezimaltrennzeichen automatisch erkannt.
                Prüfen Sie bitte, ob das System diese Trennzeichen korrekt für die hochgeladenen CSV-Dateien erkannt
                hat,
                in der Liste am unteren Rand der Seite.
            </p>
        </div>,
        [UserLanguages.ES]: <div>
            <p>El Constructor de Modelos CRS construye modelos de riesgo basados en los datos proporcionados por el
                usuario.
                Estos datos pueden ser proporcionados arrastrando uno o varios archivos al área inferior.
            </p>
            <p>El CRS Model Builder admite y puede leer los siguientes formatos de archivo: </p>
            <dl>
                <dt>CSV</dt>
                <dd>Los datos se pueden proporcionar como un archivo de texto CSV, utilizando un punto y coma o una coma
                    como separador de columnas.
                </dd>
                <dt>Excel</dt>
                <dd>Los datos se pueden proporcionar como un archivo de Excel xlsx que contiene una o varias hojas.</dd>
                <dt>SAS</dt>
                <dd>Los datos se pueden proporcionar como un archivo de datos SAS sas7bdat.</dd>
            </dl>
            <p>Para procesar automáticamente los datos en el formato de archivo correcto, utilice la extensión de
                archivo adecuada,
                es decir, .csv, .xlsx o .sas7bdat.
            </p>
            <p>Para archivos de texto CSV, el separador de columnas y el separador decimal se detectan automáticamente.
                Por favor, compruebe si el sistema ha identificado correctamente estos separadores para los archivos CSV
                cargados
                en la lista en la parte inferior de la página.
            </p>
        </div>,
        [UserLanguages.FR]: <div>
            <p>Le Constructeur de Modèles CRS construit des modèles de risque basés sur les données fournies par
                l'utilisateur.
                Ces données peuvent être fournies en faisant glisser un ou plusieurs fichiers dans la zone ci-dessous.
            </p>
            <p>Le CRS Model Builder prend en charge les formats de fichier suivants qui peuvent être lus : </p>
            <dl>
                <dt>CSV</dt>
                <dd>Les données peuvent être fournies sous forme de fichier texte CSV en utilisant un point-virgule ou
                    une virgule comme séparateur de colonnes.
                </dd>
                <dt>Excel</dt>
                <dd>Les données peuvent être fournies sous forme de fichier Excel xlsx contenant une ou plusieurs
                    feuilles.
                </dd>
                <dt>SAS</dt>
                <dd>Les données peuvent être fournies sous forme de fichier de données SAS sas7bdat.</dd>
            </dl>
            <p>Pour traiter automatiquement les données dans le format de fichier approprié, veuillez utiliser
                l'extension de fichier adéquate,
                c'est-à-dire .csv, .xlsx ou .sas7bdat.
            </p>
            <p>Pour les fichiers texte CSV, le séparateur de colonnes et le séparateur décimal sont automatiquement
                détectés.
                Veuillez vérifier si le système a correctement identifié ces séparateurs pour les fichiers CSV
                téléchargés
                dans la liste en bas de la page.
            </p>
        </div>,
    },
    [HTMLFormattedTextTypes.RC_RDS_CONSTRUCTION_DETAILS]: {
        [UserLanguages.EN]: <div>
            <p>xxx </p>
        </div>,
        [UserLanguages.DE]: <div>
            <p>xxx </p>
        </div>,
        [UserLanguages.ES]: <div>
            <p>xxx </p>
        </div>,
        [UserLanguages.FR]: <div>
            <p>xxx </p>
        </div>,
    },
    [HTMLFormattedTextTypes.UA_UNIVARIATE_ANALYSIS_DETAILS]: {
        [UserLanguages.EN]: <div>
            <p>xxx </p>
        </div>,
        [UserLanguages.DE]: <div>
            <p>xxx </p>
        </div>,
        [UserLanguages.ES]: <div>
            <p>xxx </p>
        </div>,
        [UserLanguages.FR]: <div>
            <p>xxx </p>
        </div>,
    },
    [HTMLFormattedTextTypes.MA_MULTIVARIATE_ANALYSIS_DETAILS]: {
        [UserLanguages.EN]: <div>
            <p>xxx </p>
        </div>,
        [UserLanguages.DE]: <div>
            <p>xxx </p>
        </div>,
        [UserLanguages.ES]: <div>
            <p>xxx </p>
        </div>,
        [UserLanguages.FR]: <div>
            <p>xxx </p>
        </div>,
    },
    [HTMLFormattedTextTypes.MS_MODEL_SELECTION_DETAILS]: {
        [UserLanguages.EN]: <div>
            <p>xxx </p>
        </div>,
        [UserLanguages.DE]: <div>
            <p>xxx </p>
        </div>,
        [UserLanguages.ES]: <div>
            <p>xxx </p>
        </div>,
        [UserLanguages.FR]: <div>
            <p>xxx </p>
        </div>,
    },
}
