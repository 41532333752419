import {UserLanguages, LabelTextMessageTypes} from "./Settings";
import {CallbackNoParamsStringReturn} from "./ui_general/GeneralInterfaces";


/**
 * Defines all texts, labels, and messages displayed in the frontend except feedback messages sent by the
 * backend directly.
 */
export const LabelTextMessages: Record<LabelTextMessageTypes, Record<UserLanguages, CallbackNoParamsStringReturn>> = {
    [LabelTextMessageTypes.HOME]: {
        [UserLanguages.EN]: () => `Home`,
        [UserLanguages.DE]: () => `Startseite`,
        [UserLanguages.ES]: () => `Inicio`,
        [UserLanguages.FR]: () => `Accueil`,
    },
    [LabelTextMessageTypes.CONTACTS]: {
        [UserLanguages.EN]: () => `Contacts`,
        [UserLanguages.DE]: () => `Kontakte`,
        [UserLanguages.ES]: () => `Contactos`,
        [UserLanguages.FR]: () => `Contacts`,
    },
    [LabelTextMessageTypes.LANGUAGE]: {
        [UserLanguages.EN]: () => `Language`,
        [UserLanguages.DE]: () => `Sprache`,
        [UserLanguages.ES]: () => `Idioma`,
        [UserLanguages.FR]: () => `Langue`,
    },
    [LabelTextMessageTypes.ENGLISH]: {
        [UserLanguages.EN]: () => `English`,
        [UserLanguages.DE]: () => `Englisch`,
        [UserLanguages.ES]: () => `Inglés`,
        [UserLanguages.FR]: () => `Anglais`,
    },
    [LabelTextMessageTypes.GERMAN]: {
        [UserLanguages.EN]: () => `German`,
        [UserLanguages.DE]: () => `Deutsch`,
        [UserLanguages.ES]: () => `Alemán`,
        [UserLanguages.FR]: () => `Allemand`,
    },
    [LabelTextMessageTypes.SPANISH]: {
        [UserLanguages.EN]: () => `Spanish`,
        [UserLanguages.DE]: () => `Spanisch`,
        [UserLanguages.ES]: () => `Español`,
        [UserLanguages.FR]: () => `Espagnol`,
    },
    [LabelTextMessageTypes.FRENCH]: {
        [UserLanguages.EN]: () => `French`,
        [UserLanguages.DE]: () => `Französisch`,
        [UserLanguages.ES]: () => `Francés`,
        [UserLanguages.FR]: () => `Français`,
    },
    [LabelTextMessageTypes.UNKNOWN_NETWORK_ERROR]: {
        [UserLanguages.EN]: () => `Unknown Network Error`,
        [UserLanguages.DE]: () => `Unbekannter Netzwerkfehler`,
        [UserLanguages.ES]: () => `Error de red desconocido`,
        [UserLanguages.FR]: () => `Erreur réseau inconnue`,
    },
    [LabelTextMessageTypes.MALFORMED_FETCH_REQUEST]: {
        [UserLanguages.EN]: () => `Malformed Fetch Request`,
        [UserLanguages.DE]: () => `Fehlerhafte Fetch-Anfrage`,
        [UserLanguages.ES]: () => `Solicitud Fetch malformada`,
        [UserLanguages.FR]: () => `Requête Fetch mal formée`,
    },
    [LabelTextMessageTypes.LOGIN]: {
        [UserLanguages.EN]: () => `Log In`,
        [UserLanguages.DE]: () => `Anmelden`,
        [UserLanguages.ES]: () => `Iniciar sesión`,
        [UserLanguages.FR]: () => `Se connecter`,
    },
    [LabelTextMessageTypes.LOGIN_HEADER]: {
        [UserLanguages.EN]: () => `Please provide user login credentials`,
        [UserLanguages.DE]: () => `Bitte geben Sie die Anmeldeinformationen des Benutzers ein`,
        [UserLanguages.ES]: () => `Por favor, proporcione las credenciales de inicio de sesión del usuario`,
        [UserLanguages.FR]: () => `Veuillez fournir les identifiants de connexion de l'utilisateur`,
    },
    [LabelTextMessageTypes.LOGIN_BUTTON_LABEL]: {
        [UserLanguages.EN]: () => `Log In`,
        [UserLanguages.DE]: () => `Anmelden`,
        [UserLanguages.ES]: () => `Iniciar sesión`,
        [UserLanguages.FR]: () => `Se connecter`,
    },
    [LabelTextMessageTypes.LOGIN_SUCCESSFUL]: {
        [UserLanguages.EN]: () => `Login successful`,
        [UserLanguages.DE]: () => `Anmeldung erfolgreich`,
        [UserLanguages.ES]: () => `Inicio de sesión exitoso`,
        [UserLanguages.FR]: () => `Connexion réussie`,
    },
    [LabelTextMessageTypes.CREATE]: {
        [UserLanguages.EN]: () => `Create`,
        [UserLanguages.DE]: () => `Erstellen`,
        [UserLanguages.ES]: () => `Crear`,
        [UserLanguages.FR]: () => `Créer`,
    },
    [LabelTextMessageTypes.YES]: {
        [UserLanguages.EN]: () => `Yes`,
        [UserLanguages.DE]: () => `Ja`,
        [UserLanguages.ES]: () => `Sí`,
        [UserLanguages.FR]: () => `Oui`,
    },
    [LabelTextMessageTypes.NO]: {
        [UserLanguages.EN]: () => `No`,
        [UserLanguages.DE]: () => `Nein`,
        [UserLanguages.ES]: () => `No`,
        [UserLanguages.FR]: () => `Non`,
    },
    [LabelTextMessageTypes.FILE_TYPE]: {
        [UserLanguages.EN]: () => `File Type`,
        [UserLanguages.DE]: () => `Dateityp`,
        [UserLanguages.ES]: () => `Tipo de archivo`,
        [UserLanguages.FR]: () => `Type de fichier`,
    },
    [LabelTextMessageTypes.FILE_SIZE]: {
        [UserLanguages.EN]: () => `File Size`,
        [UserLanguages.DE]: () => `Dateigröße`,
        [UserLanguages.ES]: () => `Tamaño del archivo`,
        [UserLanguages.FR]: () => `Taille du fichier`,
    },
    [LabelTextMessageTypes.TIMESTAMP]: {
        [UserLanguages.EN]: () => `Timestamp`,
        [UserLanguages.DE]: () => `Zeitstempel`,
        [UserLanguages.ES]: () => `Marca de tiempo`,
        [UserLanguages.FR]: () => `Horodatage`,
    },
    [LabelTextMessageTypes.CSV_SEPARATOR]: {
        [UserLanguages.EN]: () => `CSV Separator`,
        [UserLanguages.DE]: () => `CSV-Trennzeichen`,
        [UserLanguages.ES]: () => `Separador CSV`,
        [UserLanguages.FR]: () => `Séparateur CSV`,
    },
    [LabelTextMessageTypes.CSV_DECIMAL_SEPARATOR]: {
        [UserLanguages.EN]: () => `CSV Decimal Separator`,
        [UserLanguages.DE]: () => `CSV-Dezimaltrennzeichen`,
        [UserLanguages.ES]: () => `Separador decimal CSV`,
        [UserLanguages.FR]: () => `Séparateur décimal CSV`,
    },
    [LabelTextMessageTypes.CSV_THOUSAND_SEPARATOR]: {
        [UserLanguages.EN]: () => `CSV Thousand Separator`,
        [UserLanguages.DE]: () => `CSV-Tausendertrennzeichen`,
        [UserLanguages.ES]: () => `Separador de miles CSV`,
        [UserLanguages.FR]: () => `Séparateur de milliers CSV`,
    },
    [LabelTextMessageTypes.CSV_DATE_FORMAT]: {
        [UserLanguages.EN]: () => `CSV Date Format`,
        [UserLanguages.DE]: () => `CSV-Datumsformat`,
        [UserLanguages.ES]: () => `Formato de fecha CSV`,
        [UserLanguages.FR]: () => `Format de date CSV`,
    },
    [LabelTextMessageTypes.CSV_MISSING_PARAMETER_PLACEHOLDERS]: {
        [UserLanguages.EN]: () => `CSV Missing Placeholders`,
        [UserLanguages.DE]: () => `CSV fehlende Platzhalter`,
        [UserLanguages.ES]: () => `Marcadores de posición faltantes en CSV`,
        [UserLanguages.FR]: () => `Placeholders manquants dans le CSV`,
    },
    [LabelTextMessageTypes.COLUMN_HEADERS_INCLUDED]: {
        [UserLanguages.EN]: () => `Column Headers Included`,
        [UserLanguages.DE]: () => `Spaltenüberschriften eingefügt`,
        [UserLanguages.ES]: () => `Encabezados de columna incluidos`,
        [UserLanguages.FR]: () => `En-têtes de colonne inclus`,
    },
    [LabelTextMessageTypes.NAME]: {
        [UserLanguages.EN]: () => `Name`,
        [UserLanguages.DE]: () => `Name`,
        [UserLanguages.ES]: () => `Nombre`,
        [UserLanguages.FR]: () => `Nom`,
    },
    [LabelTextMessageTypes.DESCRIPTION]: {
        [UserLanguages.EN]: () => `Description`,
        [UserLanguages.DE]: () => `Beschreibung`,
        [UserLanguages.ES]: () => `Descripción`,
        [UserLanguages.FR]: () => `Description`,
    },
    [LabelTextMessageTypes.RISK_MODEL_NAME]: {
        [UserLanguages.EN]: () => `Name of Risk Model`,
        [UserLanguages.DE]: () => `Name des Risikomodells`,
        [UserLanguages.ES]: () => `Nombre del modelo de riesgo`,
        [UserLanguages.FR]: () => `Nom du modèle de risque`,
    },
    [LabelTextMessageTypes.ACTIONS]: {
        [UserLanguages.EN]: () => `Actions`,
        [UserLanguages.DE]: () => `Aktionen`,
        [UserLanguages.ES]: () => `Acciones`,
        [UserLanguages.FR]: () => `Actions`,
    },
    [LabelTextMessageTypes.SELECT_ALL]: {
        [UserLanguages.EN]: () => `Select All`,
        [UserLanguages.DE]: () => `Alle auswählen`,
        [UserLanguages.ES]: () => `Seleccionar todo`,
        [UserLanguages.FR]: () => `Tout sélectionner`,
    },
    [LabelTextMessageTypes.UNSELECT_ALL]: {
        [UserLanguages.EN]: () => `Unselect All`,
        [UserLanguages.DE]: () => `Alle abwählen`,
        [UserLanguages.ES]: () => `Deseleccionar todo`,
        [UserLanguages.FR]: () => `Tout désélectionner`,
    },
    [LabelTextMessageTypes.LOAD]: {
        [UserLanguages.EN]: () => `Load`,
        [UserLanguages.DE]: () => `Laden`,
        [UserLanguages.ES]: () => `Cargar`,
        [UserLanguages.FR]: () => `Charger`,
    },
    [LabelTextMessageTypes.DROP_ZONE_HINT]: {
        [UserLanguages.EN]: () => `Drag files here or click to open file browser`,
        [UserLanguages.DE]: () => `Dateien hierher ziehen oder klicken, um den Dateibrowser zu öffnen`,
        [UserLanguages.ES]: () => `Arrastra archivos aquí o haz clic para abrir el explorador de archivos`,
        [UserLanguages.FR]: () => `Déposez des fichiers ici ou cliquez pour ouvrir le navigateur de fichiers`,
    },
    [LabelTextMessageTypes.MORE_DETAILS]: {
        [UserLanguages.EN]: () => `More details`,
        [UserLanguages.DE]: () => `Weitere Details`,
        [UserLanguages.ES]: () => `Más detalles`,
        [UserLanguages.FR]: () => `Plus de détails`,
    },
    [LabelTextMessageTypes.PD_MODEL]: {
        [UserLanguages.EN]: () => `PD Model`,
        [UserLanguages.DE]: () => `PD-Modell`,
        [UserLanguages.ES]: () => `Modelo PD`,
        [UserLanguages.FR]: () => `Modèle PD`,
    },
    [LabelTextMessageTypes.LGD_MODEL]: {
        [UserLanguages.EN]: () => `LGD Model`,
        [UserLanguages.DE]: () => `LGD-Modell`,
        [UserLanguages.ES]: () => `Modelo LGD`,
        [UserLanguages.FR]: () => `Modèle LGD`,
    },
    [LabelTextMessageTypes.CCF_MODEL]: {
        [UserLanguages.EN]: () => `CCF Model`,
        [UserLanguages.DE]: () => `CCF-Modell`,
        [UserLanguages.ES]: () => `Modelo CCF`,
        [UserLanguages.FR]: () => `Modèle CCF`,
    },
    [LabelTextMessageTypes.ANY_MODEL]: {
        [UserLanguages.EN]: () => `Any Model`,
        [UserLanguages.DE]: () => `Beliebiges Modell`,
        [UserLanguages.ES]: () => `Otros modelos`,
        [UserLanguages.FR]: () => `N'importe quel modèle`,
    },
    [LabelTextMessageTypes.ORIG_COLUMN_NAME]: {
        [UserLanguages.EN]: () => `Original Column Name`,
        [UserLanguages.DE]: () => `Originaler Spaltenname`,
        [UserLanguages.ES]: () => `Nombre de columna original`,
        [UserLanguages.FR]: () => `Nom de colonne d'origine`,
    },
    [LabelTextMessageTypes.MAPPED_COLUMN_NAME]: {
        [UserLanguages.EN]: () => `Mapped Column Name`,
        [UserLanguages.DE]: () => `Zugeordneter Spaltenname`,
        [UserLanguages.ES]: () => `Nombre de columna asignado`,
        [UserLanguages.FR]: () => `Nom de colonne mappée`,
    },
    [LabelTextMessageTypes.COLUMN_TYPE]: {
        [UserLanguages.EN]: () => `Column Type`,
        [UserLanguages.DE]: () => `Spaltentyp`,
        [UserLanguages.ES]: () => `Tipo de columna`,
        [UserLanguages.FR]: () => `Type de colonne`,
    },
    [LabelTextMessageTypes.MACHINE_LEARNER_COMPARISON]: {
        [UserLanguages.EN]: () => `Machine Learner Comparison`,
        [UserLanguages.DE]: () => `Maschinelles Lernen Vergleich`,
        [UserLanguages.ES]: () => `Comparación de Aprendizaje Automático`,
        [UserLanguages.FR]: () => `Comparaison des Algorithmes d'Apprentissage Automatique`,
    },
    [LabelTextMessageTypes.BEST_OBSERVED_GINI]: {
        [UserLanguages.EN]: () => `Best Observed Gini Coefficient`,
        [UserLanguages.DE]: () => `Bester beobachteter Gini-Koeffizient`,
        [UserLanguages.ES]: () => `Mejor Coeficiente Gini Observado`,
        [UserLanguages.FR]: () => `Meilleur Coefficient de Gini Observé`,
    },
    [LabelTextMessageTypes.DEGREES_OF_FREEDOM]: {
        [UserLanguages.EN]: () => `Degrees of Freedom`,
        [UserLanguages.DE]: () => `Freiheitsgrade`,
        [UserLanguages.ES]: () => `Grados de Libertad`,
        [UserLanguages.FR]: () => `Degrés de liberté`,
    },
    [LabelTextMessageTypes.GINI_COEFFICIENT]: {
        [UserLanguages.EN]: () => `Gini Coefficient`,
        [UserLanguages.DE]: () => `Gini-Koeffizient`,
        [UserLanguages.ES]: () => `Coeficiente Gini`,
        [UserLanguages.FR]: () => `Coefficient de Gini`,
    },
    [LabelTextMessageTypes.MODEL_PERFORMANCE_VS_COMPLEXITY]: {
        [UserLanguages.EN]: () => `Model Performance vs. Complexity`,
        [UserLanguages.DE]: () => `Modellleistung vs. Komplexität`,
        [UserLanguages.ES]: () => `Rendimiento del modelo vs. Complejidad`,
        [UserLanguages.FR]: () => `Performance du modèle vs. Complexité`,
    },
    [LabelTextMessageTypes.PROGRESS]: {
        [UserLanguages.EN]: () => `Progress`,
        [UserLanguages.DE]: () => `Fortschritt`,
        [UserLanguages.ES]: () => `Progreso`,
        [UserLanguages.FR]: () => `Progrès`,
    },
    [LabelTextMessageTypes.DISTRIBUTION]: {
        [UserLanguages.EN]: () => `Distribution`,
        [UserLanguages.DE]: () => `Verteilung`,
        [UserLanguages.ES]: () => `Distribución`,
        [UserLanguages.FR]: () => `Distribution`,
    },
    [LabelTextMessageTypes.ROC_CURVE]: {
        [UserLanguages.EN]: () => `ROC Curve`,
        [UserLanguages.DE]: () => `ROC-Kurve`,
        [UserLanguages.ES]: () => `Curva ROC`,
        [UserLanguages.FR]: () => `Courbe ROC`,
    },
    [LabelTextMessageTypes.TRUE_POSITIVE_RATE]: {
        [UserLanguages.EN]: () => `True Positive Rate`,
        [UserLanguages.DE]: () => `Richtig-Positiv-Rate`,
        [UserLanguages.ES]: () => `Tasa de Verdaderos Positivos`,
        [UserLanguages.FR]: () => `Taux de Vrais Positifs`,
    },
    [LabelTextMessageTypes.FALSE_POSITIVE_RATE]: {
        [UserLanguages.EN]: () => `False Positive Rate`,
        [UserLanguages.DE]: () => `Falsch-Positiv-Rate`,
        [UserLanguages.ES]: () => `Tasa de Falsos Positivos`,
        [UserLanguages.FR]: () => `Taux de Faux Positifs`,
    },
    [LabelTextMessageTypes.OCCURRENCE_DENSITY]: {
        [UserLanguages.EN]: () => `Occurrence Density`,
        [UserLanguages.DE]: () => `Auftretensdichte`,
        [UserLanguages.ES]: () => `Densidad de ocurrencia`,
        [UserLanguages.FR]: () => `Densité d'occurrence`,
    },
    [LabelTextMessageTypes.CONDITIONAL_PD]: {
        [UserLanguages.EN]: () => `Conditional PD`,
        [UserLanguages.DE]: () => `Bedingte PD`,
        [UserLanguages.ES]: () => `PD Condicional`,
        [UserLanguages.FR]: () => `PD Conditionnelle`,
    },
    [LabelTextMessageTypes.OCCURRENCE_RATE]: {
        [UserLanguages.EN]: () => `Occurrence Rate`,
        [UserLanguages.DE]: () => `Vorkommensrate`,
        [UserLanguages.ES]: () => `Tasa de ocurrencia`,
        [UserLanguages.FR]: () => `Taux d'occurrence`,
    },
    [LabelTextMessageTypes.PROBABILITY_SLASH_RATE]: {
        [UserLanguages.EN]: () => `Probability / Rate`,
        [UserLanguages.DE]: () => `Wahrscheinlichkeit / Rate`,
        [UserLanguages.ES]: () => `Probabilidad / Tasa`,
        [UserLanguages.FR]: () => `Probabilité / Taux`,
    },
    [LabelTextMessageTypes.PROBABILITY_SLASH_DENSITY]: {
        [UserLanguages.EN]: () => `Probability / Density`,
        [UserLanguages.DE]: () => `Wahrscheinlichkeit / Dichte`,
        [UserLanguages.ES]: () => `Probabilidad / Densidad`,
        [UserLanguages.FR]: () => `Probabilité / Densité`,
    },
    [LabelTextMessageTypes.PD_PER_CATEGORY]: {
        [UserLanguages.EN]: () => `PD per Category`,
        [UserLanguages.DE]: () => `PD pro Kategorie`,
        [UserLanguages.ES]: () => `PD por Categoría`,
        [UserLanguages.FR]: () => `PD par Catégorie`,
    },
    [LabelTextMessageTypes.RDS_VARIABLE]: {
        [UserLanguages.EN]: () => `RDS Variable`,
        [UserLanguages.DE]: () => `RDS-Variable`,
        [UserLanguages.ES]: () => `Variable RDS`,
        [UserLanguages.FR]: () => `Variable RDS`,
    },
    [LabelTextMessageTypes.TARGET_VAR_BINARY]: {
        [UserLanguages.EN]: () => `Binary Target`,
        [UserLanguages.DE]: () => `Binäres Ziel`,
        [UserLanguages.ES]: () => `Objetivo Binario`,
        [UserLanguages.FR]: () => `Cible Binaire`,
    },
    [LabelTextMessageTypes.TARGET_VAR_MULTI_CATEGORICAL]: {
        [UserLanguages.EN]: () => `Multi-Categorical Target`,
        [UserLanguages.DE]: () => `Mehrkategorielles Ziel`,
        [UserLanguages.ES]: () => `Objetivo Multi-Categórico`,
        [UserLanguages.FR]: () => `Cible Multi-catégorielle`,
    },
    [LabelTextMessageTypes.TARGET_VAR_NON_NEGATIVE]: {
        [UserLanguages.EN]: () => `Non-Negative Target`,
        [UserLanguages.DE]: () => `Nicht-Negatives Ziel`,
        [UserLanguages.ES]: () => `Objetivo No Negativo`,
        [UserLanguages.FR]: () => `Cible Non-Négative`,
    },
    [LabelTextMessageTypes.TARGET_VAR_UNBOUNDED]: {
        [UserLanguages.EN]: () => `Unbounded Target`,
        [UserLanguages.DE]: () => `Unbeschränktes Ziel`,
        [UserLanguages.ES]: () => `Objetivo Ilimitado`,
        [UserLanguages.FR]: () => `Cible Non Bornée`,
    },
    [LabelTextMessageTypes.INPUT_VAR_NUMERICAL]: {
        [UserLanguages.EN]: () => `Numerical Input`,
        [UserLanguages.DE]: () => `Numerische Eingabe`,
        [UserLanguages.ES]: () => `Entrada Numérica`,
        [UserLanguages.FR]: () => `Entrée Numérique`,
    },
    [LabelTextMessageTypes.INPUT_VAR_CATEGORICAL]: {
        [UserLanguages.EN]: () => `Categorical Input`,
        [UserLanguages.DE]: () => `Kategoriale Eingabe`,
        [UserLanguages.ES]: () => `Entrada Categórica`,
        [UserLanguages.FR]: () => `Entrée Catégorique`,
    },
    [LabelTextMessageTypes.LP_CREATE_OR_LOAD_CAPTION]: {
        [UserLanguages.EN]: () => `Create or Load Model`,
        [UserLanguages.DE]: () => `Modell erstellen oder laden`,
        [UserLanguages.ES]: () => `Crear o cargar modelo`,
        [UserLanguages.FR]: () => `Créer ou charger le modèle`,
    },
    [LabelTextMessageTypes.LP_CREATE_OR_LOAD_EXPLANATION]: {
        [UserLanguages.EN]: () => `You can either create a new model (PD, LGD, or CCF) from scratch or ` +
            `continue working on an existing model. Please indicate your choice by clicking on the respective item ` +
            `below.`,
        [UserLanguages.DE]: () => `Sie können entweder ein neues Modell (PD, LGD oder CCF) von Grund auf ` +
            `erstellen oder an einem vorhandenen Modell weiterarbeiten. Bitte geben Sie Ihre Wahl an, indem Sie auf ` +
            `den entsprechenden Artikel unten klicken.`,
        [UserLanguages.ES]: () => `Puede crear un nuevo modelo (PD, LGD o CCF) desde cero o continuar ` +
            `trabajando en un modelo existente. Indique su elección haciendo clic en el elemento correspondiente a ` +
            `continuación.`,
        [UserLanguages.FR]: () => `Vous pouvez soit créer un nouveau modèle (PD, LGD ou CCF) à partir de ` +
            `zéro, soit continuer à travailler sur un modèle existant. Veuillez indiquer votre choix en cliquant sur ` +
            `l'article correspondant ci-dessous.`,
    },
    [LabelTextMessageTypes.LP_CREATE_PD_MODEL]: {
        [UserLanguages.EN]: () => `Create PD Model`,
        [UserLanguages.DE]: () => `PD-Modell erstellen`,
        [UserLanguages.ES]: () => `Crear modelo de PD`,
        [UserLanguages.FR]: () => `Créer un modèle PD`,
    },
    [LabelTextMessageTypes.LP_CREATE_LGD_MODEL]: {
        [UserLanguages.EN]: () => `Create LGD Model`,
        [UserLanguages.DE]: () => `LGD-Modell erstellen`,
        [UserLanguages.ES]: () => `Crear modelo de LGD`,
        [UserLanguages.FR]: () => `Créer un modèle LGD`,
    },
    [LabelTextMessageTypes.LP_CREATE_CCF_MODEL]: {
        [UserLanguages.EN]: () => `Create CCF Model`,
        [UserLanguages.DE]: () => `CCF-Modell erstellen`,
        [UserLanguages.ES]: () => `Crear modelo de CCF`,
        [UserLanguages.FR]: () => `Créer un modèle CCF`,
    },
    [LabelTextMessageTypes.LP_LOAD_MODEL]: {
        [UserLanguages.EN]: () => `Load Model`,
        [UserLanguages.DE]: () => `Modell laden`,
        [UserLanguages.ES]: () => `Cargar modelo`,
        [UserLanguages.FR]: () => `Charger le modèle`,
    },
    [LabelTextMessageTypes.LP_CREATE_PD_MODEL_EXPLANATION]: {
        [UserLanguages.EN]: () => `This option creates a PD model from scratch based on historic default ` +
            `observations to be uploaded by the user.`,
        [UserLanguages.DE]: () => `Diese Option erstellt ein PD-Modell von Grund auf basierend auf ` +
            `historischen Ausfallbeobachtungen, die vom Benutzer hochgeladen werden sollen.`,
        [UserLanguages.ES]: () => `Esta opción crea un modelo de PD desde cero basado en observaciones ` +
            `históricas de incumplimientos que el usuario debe cargar.`,
        [UserLanguages.FR]: () => `Cette option permet de créer un modèle PD à partir de zéro, basé sur des ` +
            `observations historiques de défauts à télécharger par l'utilisateur.`,
    },
    [LabelTextMessageTypes.LP_CREATE_LGD_MODEL_EXPLANATION]: {
        [UserLanguages.EN]: () => `This option creates an LGD model from scratch based on historic default ` +
            `observations to be uploaded by the user.`,
        [UserLanguages.DE]: () => `Diese Option erstellt ein LGD-Modell von Grund auf basierend auf ` +
            `historischen Ausfallbeobachtungen, die vom Benutzer hochgeladen werden sollen.`,
        [UserLanguages.ES]: () => `Esta opción crea un modelo de LGD desde cero basado en observaciones ` +
            `históricas de incumplimientos que el usuario debe cargar.`,
        [UserLanguages.FR]: () => `Cette option permet de créer un modèle LGD à partir de zéro, basé sur des ` +
            `observations historiques de défauts à télécharger par l'utilisateur.`,
    },
    [LabelTextMessageTypes.LP_CREATE_CCF_MODEL_EXPLANATION]: {
        [UserLanguages.EN]: () => `This option creates a CCF model from scratch based on historic default ` +
            `observations to be uploaded by the user.`,
        [UserLanguages.DE]: () => `Diese Option erstellt ein CCF-Modell von Grund auf basierend auf ` +
            `historischen Ausfallbeobachtungen, die vom Benutzer hochgeladen werden sollen.`,
        [UserLanguages.ES]: () => `Esta opción crea un modelo de CCF desde cero basado en observaciones ` +
            `históricas de incumplimientos que el usuario debe cargar.`,
        [UserLanguages.FR]: () => `Cette option permet de créer un modèle CCF à partir de zéro, basé sur des ` +
            `observations historiques de défauts à télécharger par l'utilisateur.`,
    },
    [LabelTextMessageTypes.LP_LOAD_MODEL_EXPLANATION]: {
        [UserLanguages.EN]: () => `This option loads an existing model allowing to review and/or continue the ` +
            `model building process.`,
        [UserLanguages.DE]: () => `Diese Option lädt ein vorhandenes Modell, um den Modellbildungsprozess zu ` +
            `überprüfen und fortzusetzen.`,
        [UserLanguages.ES]: () => `Esta opción carga un modelo existente que permite revisar y/o continuar el ` +
            `proceso de construcción del modelo.`,
        [UserLanguages.FR]: () => `Cette option charge un modèle existant permettant de revoir et/ou de ` +
            `poursuivre le processus de construction du modèle.`,
    },
    [LabelTextMessageTypes.FU_FILE_UPLOAD_CAPTION]: {
        [UserLanguages.EN]: () => `Upload Files`,
        [UserLanguages.DE]: () => `Dateien hochladen`,
        [UserLanguages.ES]: () => `Cargar archivos`,
        [UserLanguages.FR]: () => `Télécharger des fichiers`,
    },
    [LabelTextMessageTypes.FU_FILE_UPLOAD_EXPLANATION]: {
        [UserLanguages.EN]: () => `You can upload one or more files containing the data that shall be used in ` +
            `the model construction by dragging the files in the area below. Various file formats are supported.`,
        [UserLanguages.DE]: () => `Sie können eine oder mehrere Dateien hochladen, die die Daten enthalten, ` +
            `die für den Modellaufbau verwendet werden sollen, indem Sie die Dateien in den untenstehenden Bereich ` +
            `ziehen. Verschiedene Dateiformate werden unterstützt.`,
        [UserLanguages.ES]: () => `Puede cargar uno o más archivos que contengan los datos que se utilizarán ` +
            `en la construcción del modelo arrastrando los archivos al área a continuación. Se admiten varios ` +
            `formatos de archivo.`,
        [UserLanguages.FR]: () => `Vous pouvez télécharger un ou plusieurs fichiers contenant les données qui ` +
            `seront utilisées dans la construction du modèle en faisant glisser les fichiers dans la zone ` +
            `ci-dessous. Divers formats de fichier sont pris en charge.`,
    },
    [LabelTextMessageTypes.RC_RDS_CONSTRUCTION_CAPTION]: {
        [UserLanguages.EN]: () => `RDS Construction`,
        [UserLanguages.DE]: () => `RDS-Konstruktion`,
        [UserLanguages.ES]: () => `Construcción de RDS`,
        [UserLanguages.FR]: () => `Construction de RDS`,
    },
    [LabelTextMessageTypes.RC_RDS_CONSTRUCTION_EXPLANATION]: {
        [UserLanguages.EN]: () => `To construct the reference data set (RDS) underlying the model building, ` +
            `please select one (binary) dependent variable and various explaining variables (categorical or ` +
            `numerical).`,
        [UserLanguages.DE]: () => `Um den Referenzdatensatz (RDS) zu erstellen, der dem Modellaufbau zugrunde ` +
            `liegt, wählen Sie bitte eine (binäre) abhängige Variable und verschiedene erklärende Variablen ` +
            `(kategorisch oder numerisch) aus.`,
        [UserLanguages.ES]: () => `Para construir el conjunto de datos de referencia (RDS) que subyace en la ` +
            `construcción del modelo, seleccione una variable dependiente (binaria) y varias variables explicativas ` +
            `(categóricas o numéricas).`,
        [UserLanguages.FR]: () => `Pour construire l'ensemble de données de référence (RDS) sur lequel ` +
            `repose la construction du modèle, veuillez sélectionner une variable dépendante (binaire) et diverses ` +
            `variables explicatives (catégorielles ou numériques).`,
    },
    [LabelTextMessageTypes.UA_UNIVARIATE_ANALYSIS_CAPTION]: {
        [UserLanguages.EN]: () => `Univariate Analysis`,
        [UserLanguages.DE]: () => `Univariate Analyse`,
        [UserLanguages.ES]: () => `Análisis Univariado`,
        [UserLanguages.FR]: () => `Analyse Univariable`,
    },
    [LabelTextMessageTypes.UA_UNIVARIATE_ANALYSIS_EXPLANATION]: {
        [UserLanguages.EN]: () => `Univariate analysis are conducted for all explaining variables selected ` +
            `in the RDS. You may navigate back to the previous slide to change variable selection if desired.`,
        [UserLanguages.DE]: () => `Für alle ausgewählten erklärenden Variablen im RDS wird eine univariate ` +
            `Analyse durchgeführt. Sie können zur vorherigen Folie zurückkehren, um die Variablenauswahl zu ändern, ` +
            `wenn gewünscht.`,
        [UserLanguages.ES]: () => `Se lleva a cabo un análisis univariado para todas las variables ` +
            `explicativas seleccionadas en el RDS. Puede regresar a la diapositiva anterior para cambiar la ` +
            `selección de variables si lo desea.`,
        [UserLanguages.FR]: () => `Une analyse univariable est réalisée pour toutes les variables ` +
            `explicatives sélectionnées dans le RDS. Vous pouvez revenir à la diapositive précédente pour modifier ` +
            `la sélection des variables si vous le souhaitez.`,
    },
    [LabelTextMessageTypes.MA_MULTIVARIATE_ANALYSIS_CAPTION]: {
        [UserLanguages.EN]: () => `Multivariate Analysis`,
        [UserLanguages.DE]: () => `Multivariate Analyse`,
        [UserLanguages.ES]: () => `Análisis Multivariado`,
        [UserLanguages.FR]: () => `Analyse Multivariée`,
    },
    [LabelTextMessageTypes.MA_MULTIVARIATE_ANALYSIS_EXPLANATION]: {
        [UserLanguages.EN]: () => `The multivariate analyses are automatically performed and cross-validated ` +
            `on the constructed reference data set (RDS). Please adjust the hyperparameters of the machine learners ` +
            `as desired.`,
        [UserLanguages.DE]: () => `Die multivariaten Analysen werden automatisch auf dem konstruierten ` +
            `Referenzdatensatz (RDS) durchgeführt und validiert. Bitte passen Sie die Hyperparameter der ` +
            `Maschinenlernverfahren nach Bedarf an.`,
        [UserLanguages.ES]: () => `Los análisis multivariados se realizan automáticamente y se validan ` +
            `cruzadamente en el conjunto de datos de referencia construido (RDS). Ajuste los hiperparámetros de los ` +
            `algoritmos de aprendizaje automático según sea necesario.`,
        [UserLanguages.FR]: () => `Les analyses multivariées sont automatiquement réalisées et validées ` +
            `croisées  sur l'ensemble de données de référence construit (RDS). Veuillez ajuster les hyperparamètres ` +
            `des algorithmes d'apprentissage automatique selon vos besoins.`,
    },
    [LabelTextMessageTypes.MS_MODEL_SELECTION_CAPTION]: {
        [UserLanguages.EN]: () => `Model Selection`,
        [UserLanguages.DE]: () => `Modelauswahl`,
        [UserLanguages.ES]: () => `Selección de modelo`,
        [UserLanguages.FR]: () => `Sélection de modèle`,
    },
    [LabelTextMessageTypes.MS_MODEL_SELECTION_EXPLANATION]: {
        [UserLanguages.EN]: () => `Here you can load a previously created risk model to continue working on ` +
            `it. Please click on the load button next to the model in the table below that you would like to select.`,
        [UserLanguages.DE]: () => `Hier können Sie ein zuvor erstelltes Risikomodell laden, um daran ` +
            `weiterzuarbeiten. Bitte klicken Sie auf den Ladebutton neben dem Modell in der Tabelle unten, das Sie ` +
            `auswählen möchten.`,
        [UserLanguages.ES]: () => `Aquí puedes cargar un modelo de riesgo previamente creado para seguir ` +
            `trabajando en él. Por favor, haz clic en el botón de carga junto al modelo en la tabla de abajo que ` +
            `deseas seleccionar.`,
        [UserLanguages.FR]: () => `Ici, vous pouvez charger un modèle de risque précédemment créé pour ` +
            `continuer à travailler dessus. Veuillez cliquer sur le bouton de chargement à côté du modèle dans le ` +
            `tableau ci-dessous que vous souhaitez sélectionner.`,
    },
}