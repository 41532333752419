import {
    CallbackBooleanParam,
    CallbackNoParams,
    KeyUndefinedStringValues, KeyValidity,
    RegisteredComponent
} from "../ui_general/GeneralInterfaces";
import {ValidationSchemes} from "../Settings";


/**
 * This class stores and validates the state information of input controls. Whenever an input element receives an update
 * in its value, it forwards the new value to this controller, where the input is stored and validated according to the
 * specified validation schemes. The result of the validation is passed back to the calling input element to trigger
 * feedback indications to the user. This controller also serves to govern mutual exclusion logics and allows to access
 * the value of registered input elements from outside the input element. Input elements that are supposed to be
 * controlled by this class need to register themselves with a unique identifier.
 */
export class InputStateControl {
    /** Lists all registered components */
    private registered_components: Array<RegisteredComponent>;
    /** Callback that is invoked whenever a value of a registered component has changed */
    private global_validity_update_callback: undefined | CallbackBooleanParam;
    /** Callback that is invoked whenever the validity assessment of a registered component has changed */
    private global_value_update_callback: undefined | CallbackNoParams;


    /**
     * Constructor. Initializes the list of registered components as empty list.
     */
    constructor() {
        this.registered_components = [];
        this.global_validity_update_callback = undefined;
        this.global_value_update_callback = undefined;
    }


    /**
     * Registers a new input component.
     * @param validation_schemes The schemes that shall be applied in the validation.
     * @param key A fixed key that allows identifying the component. The ID could also be used, however, the ID is
     * dynamic and may change. Throws exception, if key is specified which is already registered.
     * @param validity_callback An optional callback function that is called whenever the validity assessment of the
     * registered component has changed.
     * @returns The id of the newly registered component.
     */
    registerComponent(key?: string,
                      validation_schemes?: Array<ValidationSchemes>,
                      validity_callback?: CallbackBooleanParam): number {
        if (key !== undefined && this.registered_components.find(comp => comp.key === key)) {
            throw new Error('InputStateControl.registerComponent: Key to be registered already registered (' +
                key  + ')');
        }

        const max_id = this.registered_components.reduce(
            (max, comp) => comp.id > max ? comp.id : max,
            0
        );

        const new_component: RegisteredComponent = {
            id: max_id + 1,
            valid: undefined,
            validation_schemes: validation_schemes ? validation_schemes : [],
            key: key,
            value: undefined,
            validity_callback: validity_callback
        }
        this.registered_components.push(new_component);

        this.invokeGlobalCallbacks(true, true);

        return new_component.id;
    }


    /**
     * Removes the component with the given ID from the list of registered components. Throws exception if id does not
     * exist in list of registered components.
     * @param id The ID of the component to be removed.
     */
    deregisterComponent(id: number) {
        let registered_components_new = this.registered_components.filter(
            comp => comp.id !== id
        );
        if (registered_components_new.length !== this.registered_components.length) {
            this.registered_components = registered_components_new;
            this.invokeGlobalCallbacks(true, true);
            return;
        }

        throw new Error('InputStateControl.deregisterComponent: No such id registered: ' + id);
    }


    /**
     * Browses the list of registered components and returns a record of key value pairs, with key being given as the
     * key provided during registration and value being the current value of the registered input component as
     * provided by the component with the respective update value function call.
     * @returns Record of key value pairs holding the current values of the registered components.
     */
    getKeyValueRecord(): KeyUndefinedStringValues {
        let key_values: KeyUndefinedStringValues = {}

        this.registered_components.forEach(comp => {
            if (comp.key) key_values[comp.key] = comp.value
        })

        return key_values;
    }


    /**
     * Returns the value stored for a given key. If the key is not in the list of registered components, undefined is
     * returned.
     * @param key The key to search for in the list of registered components.
     * @returns The value stored for the given key, if found, otherwise undefined.
     */
    getValueForKey(key: string): string | File | undefined {
        let res = undefined;
        this.registered_components.forEach(comp => {
            if (comp.key === key) res = comp.value;
        })
        return res;
    }


    /**
     * Returns the value stored for a given key. If the key is not in the list of registered components, undefined is
     * returned. Also, it checks that value is of type string. If that is not the case, undefined is returned.
     * @param key The key to search for in the list of registered components.
     * @returns The value stored for the given key, if found, otherwise undefined.
     */
    getStringValueForKey(key: string): string | undefined {
        let res = undefined;
        this.registered_components.forEach(comp => {
            if (comp.key === key && typeof comp.value === "string") res = comp.value;
        })
        return res;
    }


    /**
     * Browses the list of registered components and returns a record of key validity pairs, with key being given as the
     * key provided during registration and valid being the current validity of the registered input component as
     * assessed in the validation check procedure.
     * @returns Record of key validity pairs holding the current validation assessment result of all components
     * registered with a key.
     */
    getKeyValidityRecord(): KeyValidity {
        let key_validity: KeyValidity = {}

        this.registered_components.forEach(comp => {
            if (comp.key) key_validity[comp.key] = comp.valid
        })

        return key_validity;
    }


    /**
     * Returns the valid setting stored for a given key. If the key is not in the list of registered components,
     * undefined is returned.
     * @param key The key to search for in the list of registered components.
     * @returns The validity stored for the given key, if found, otherwise undefined.
     */
    getValidityForKey(key: string): boolean | undefined {
        let res = undefined;
        this.registered_components.forEach(comp => {
            if (comp.key === key) res = comp.valid
        })
        return res;
    }


    /**
     * Set the global value update callback, which is called whenever the value of any registered component has changed.
     * @param callback Callback function without parameters
     */
    setGlobalValueUpdateCallback(callback: CallbackNoParams) {
        this.global_value_update_callback = callback;
    }


    /**
     * Set the global validity update callback, which is called whenever the validity of any registered component has
     * changed.
     * @param callback Callback function without parameters
     */
    setGlobalValidityUpdateCallback(callback: CallbackBooleanParam) {
        this.global_validity_update_callback = callback;
    }


    /**
     * Updates the value of a registered component identified by id. It automatically checks whether the updated value
     * is in line with the registered validation schemes. The validity flag is set accordingly. In case of changes of
     * the validity flag, the corresponding callbacks are called (for the specific component as well as the global
     * callback). Also, the global value update callback is called, if the value has changed.
     * @param id ID of the component the value of which shall be updated.
     * @param value New value updating the previous setting.
     */
    updateValue(id: number, value: string | File | undefined) {
        const registered_component = this.registered_components.find(
            comp => comp.id === id
        );
        if (!registered_component) {
            throw new Error('InputStateControl.updateValue: ID not found in list of registered components: '
                + id);
        }

        const valid_new = this.checkValidity(id, value);
        const valid_changed = valid_new !== registered_component.valid;
        const value_changed = value !== registered_component.value;

        registered_component.value = value;
        registered_component.valid = valid_new;

        if (valid_changed && registered_component.validity_callback) registered_component.validity_callback(valid_new);
        if (value_changed && this.global_value_update_callback) this.global_value_update_callback();
        if (valid_changed && this.global_validity_update_callback) this.global_validity_update_callback(this.isValidOverall());
    }


    /**
     * Checks the validity of the provided value in regard to the validation schemes registered with the given
     * component id. An exception is thrown if the component cannot be found with the given id.
     * @param id ID of the component
     * @param value Value to be checked
     * @returns True, if the given value is in line with the validation schemes, false otherwise.
     */
    checkValidity(id: number, value: string | File | undefined): boolean {
        const registered_component = this.registered_components.find(
            comp => comp.id === id
        );
        if (!registered_component) {
            throw new Error('InputStateControl.validate: ID not found in list of registered components: ' + id);
        }

        let valid = true;
        registered_component.validation_schemes.forEach(scheme => {
            if (scheme === ValidationSchemes.REQUIRED) {
                if (!value) valid = false;
            }

            if (scheme === ValidationSchemes.NUMBER) {
                let nr = Number(value);
                if (isNaN(nr)) valid = false;
            }

            if (scheme === ValidationSchemes.INTEGER) {
                let nr = Number(value);
                if (isNaN(nr)) {
                    valid = false;
                } else {
                    if (nr !== Math.round(nr)) valid = false;
                }
            }

            if (scheme.toUpperCase().substring(0,4) === 'MIN_') {
                let nr = Number(value);
                if (isNaN(nr)) valid = false;
                let threshold = Number(scheme.substring(4));
                if (!isNaN(threshold) && nr < threshold) valid = false;
            }

            if (scheme.toUpperCase().substring(0,4) === 'MAX_') {
                let nr = Number(value);
                if (isNaN(nr)) valid = false;
                let threshold = Number(scheme.substring(4));
                if (!isNaN(threshold) && nr > threshold) valid = false;
            }

            if (scheme.toUpperCase().substring(0,10) === 'MINLENGTH_') {
                let threshold = Number(scheme.substring(10));
                if (!isNaN(threshold) && typeof(value) === 'string' && value && value.length < threshold) valid = false;
            }

            if (scheme.toUpperCase().substring(0,10) === 'MAXLENGTH_') {
                let threshold = Number(scheme.substring(10));
                if (!isNaN(threshold) && typeof(value) === 'string' && value && value.length > threshold) valid = false;
            }

            if (scheme === ValidationSchemes.DATE) {
                if (typeof(value) === 'string') {
                    let parsed_date = Date.parse(value);
                    if (isNaN(parsed_date) || parsed_date < 0) valid = false;
                } else valid = false;
            }
        })

        return valid;
    }


    /**
     * Loops over all registered components and returns true, if all components are valid, false otherwise. Also returns
     * true, if there are no registered components.
     * @returns False, if there is an invalid component, true otherwise.
     */
    isValidOverall(): boolean {
        let valid_overall = true;
        this.registered_components.forEach(comp => {
            if (!comp.valid) valid_overall = false;
        });

        return valid_overall;
    }


    /**
     * Invokes the global value and / or validity callback if defined.
     * @param invoke_value_callback If true, the global value update callback is invoked (if defined).
     * @param invoke_validity_callback If true, the global validity update callback is invoked (if defined).
     */
    invokeGlobalCallbacks(invoke_value_callback: boolean, invoke_validity_callback: boolean) {
        if (invoke_value_callback && this.global_value_update_callback) this.global_value_update_callback();
        if (invoke_validity_callback && this.global_validity_update_callback) this.global_validity_update_callback(
            this.isValidOverall()
        );
    }

}