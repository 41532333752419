import React from 'react';
import Button from 'react-bootstrap/Button';
import {CallbackNoParams} from "../ui_general/GeneralInterfaces";


/**
* Definition of RBSButton Types
*/
export enum RBSButtonVariants {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    OUTLINE_PRIMARY = 'outline-primary',
    OUTLINE_SECONDARY = 'outline-secondary',
    LINK = 'link',
}


/**
* Definition of RBSButton Sizes
*/
export enum RBSButtonSizes {
    SMALL = 'sm',
    LARGE = 'lg',
}


/**
 * Defines the properties of the React-Bootstrap Button component wrapper.
 */
interface RBSButtonProps {
    /** Label of the button. */
    label: string;
    /** Callback function for click on button. */
    onClick?: CallbackNoParams;
    /** Defines button variant */
    variant?: RBSButtonVariants;
    /** Specifies on-hover-over tool-tip */
    title?: string;
    /** Indicates whether the button is disabled */
    disabled?: boolean;
    /** Indicates the size of the button. If omitted, standard size is assumed. */
    size?: RBSButtonSizes;
    /** Additional style properties of the component. */
    extraStyle?: React.CSSProperties;
}


/**
 * Defines the state of the React-Bootstrap Button component wrapper.
 */
interface RBSButtonState {
}


/**
 * This class is a wrapper of the React-Bootstrap Button component.
 */
export class RBSButton extends React.Component<RBSButtonProps, RBSButtonState> {

    /**
     * Constructor. Initializes states.
     * @param props React properties passed from the calling instance.
     */
    constructor(props: RBSButtonProps) {
        super(props);

        this.state = {
        };
    }


    /**
     * This routine is called from React whenever the component needs to be re-rendered. It renders the component on the
     * basis of the current state and properties.
     */
    render() {
        return (
            <Button
                variant={this.props.variant ? this.props.variant : 'primary'}
                onClick={(event) => {
                    if (this.props.onClick) this.props.onClick();
                    event.stopPropagation();
                }}
                title={this.props.title}
                size={this.props.size}
                disabled={this.props.disabled}
                style={this.props.extraStyle}
            >
                {this.props.label}
            </Button>
        );
    }
}
