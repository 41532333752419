/**
 * This module provides utility functions in relation to IT security, such as authorization and authentication.
 * @module
 */

import {jwtDecode} from "jwt-decode";
import {
    CallbackFeedbackMessageProcessing,
    FeedbackMessage,
    JwtPayloadExtended,
    TokenPayload, UserAccessPrivilege,
    UUID
} from "../ui_general/GeneralInterfaces";
import {
    ApiEndPoints,
    BackendURLPaths, BuiltInPermissionNames,
    FeedbackMessageTypes,
    HttpMethods,
    LabelTextMessageTypes,
    TokenTypes
} from "../Settings";
import {FetchControl} from "./FetchControl";
import {getLabelTextMessage, getUserLanguage} from "./GeneralUtilities";


/**
 * This function creates and returns a hex string representation of a UUID.
 * @param uuidBytes Byte array of UUID to be represented as a string.
 * @returns The hex string representation of the UUID.
 */
export function uuidStringify(uuidBytes: Uint8Array): UUID {
    return Array.from(
        uuidBytes,
            byte => ('0' + (byte & 0xFF).toString(16)).slice(-2)
    ).join(
        ''
    ).replace(
        /^(.{8})(.{4})(.{4})(.{4})(.{12})$/,
        "$1-$2-$3-$4-$5"
    )
}


/**
 * This function parses a string representation of a UUID of version 8 and returns the UUID as byte array.
 * If the parsing is unsuccessful or the given UUID is not of version 8 and variant 8, an exception is thrown.
 * @param uuid String representation of UUID to be parsed
 * @returns The created UUID byte array
 */
export function uuidParse(uuid: UUID): Uint8Array {
    const hexString = uuid.replace(/-/g, ""); // Remove dashes from the UUID string
    let uuidBytes: Array<number> = [];
    for (let c = 0; c < hexString.length; c += 2) uuidBytes.push(parseInt(hexString.substring(c, c + 2), 16));

    // Check the length of the byte array
    if (uuidBytes.length !== 16) throw new Error("Invalid UUID string");

    // Check the version
    const version = parseInt(uuid.slice(14, 15), 16);
    if (version !== 8) new Error("UUID has not version 8");

    // Check the variant bits
    const variant = (uuidBytes[8] & 0xc0) >> 4;
    if (variant !== 8) new Error("UUID has not variant 8");

    return Uint8Array.from(uuidBytes);
  }


/**
 * Convenience function for calling uuidParse and catching any exception. If an exception is caught, i.e. if the
 * given string representation does not reflect a version 8 UUID with variant 8, false is returned, otherwise true.
 * @param uuid String representation of UUID to be parsed
 * @returns True, if given string representation reflects a version 8 variant 8 UUID, otherwise false.
 */
export function uuidValidate(uuid: UUID): boolean {
    try {
        uuidParse(uuid);
        return true;
    } catch (error) {
        return false;
    }
}


/**
 * Decodes the given token but does not validate the token. The function performs certain type and value checks on the
 * expected claims though. If the token cannot be decoded or any check fails, undefined is returned. The expected claims
 * are converted to the expected types and returned as an TokenPayload object. Strings are escaped using encodeURI.
 * @param token Token to be decoded
 * @returns Object of type TokenPayload holding the expected claims converted to the expected types.
 */
export function decodeToken(token: string): TokenPayload | undefined {
    try {
        const decoded_token_payload_raw = jwtDecode<JwtPayloadExtended>(token);

        if (decoded_token_payload_raw.iss !== process.env.REACT_APP_BACKEND_URL + BackendURLPaths.ADMIN) return undefined;
        if (!decoded_token_payload_raw.sub || !uuidValidate(decoded_token_payload_raw.sub)) return undefined;
        if (!decoded_token_payload_raw.aud ||
            (typeof decoded_token_payload_raw === 'object' && decoded_token_payload_raw.aud.length === 0 &&
            decoded_token_payload_raw.typ !== TokenTypes.XSRF)) return undefined;
        if (decoded_token_payload_raw.jti && !uuidValidate(decoded_token_payload_raw.jti)) return undefined;
        if (!decoded_token_payload_raw.typ || typeof decoded_token_payload_raw.typ !== 'string' ||
            !Object.values(TokenTypes).includes(decoded_token_payload_raw.typ)) return undefined;
        if (!decoded_token_payload_raw.nonce) return undefined;

        let decoded_token_payload: TokenPayload = {
            sub: decoded_token_payload_raw.sub,
            typ: encodeURI(decoded_token_payload_raw.typ) as TokenTypes,
            aud: typeof decoded_token_payload_raw.aud === 'string' ?
                [encodeURI(decoded_token_payload_raw.aud)] :
                decoded_token_payload_raw.aud.map(aud => encodeURI(aud)),
            nonce: encodeURI(decoded_token_payload_raw.nonce)
        };

        if (decoded_token_payload_raw.jti) decoded_token_payload.jti = encodeURI(decoded_token_payload_raw.jti);
        if (decoded_token_payload_raw.name) decoded_token_payload.name = encodeURI(decoded_token_payload_raw.name);
        if (decoded_token_payload_raw.email) decoded_token_payload.email = encodeURI(decoded_token_payload_raw.email);
        if (decoded_token_payload_raw.exp) {
            decoded_token_payload.exp_date = new Date(decoded_token_payload_raw.exp * 1000);
        }

        return decoded_token_payload;
    } catch (error) {
        return undefined;
    }
}


/**
 * This function deletes the cookie with the given name.
 * @param cookie_name Name of cookie to be deleted.
 */
export function removeCookie(cookie_name: string) {
    document.cookie = cookie_name + '=deleted;expires=' + new Date('10 Jan 1970 00:00:00 UTC').toUTCString();
}


/**
 * Saves a cookie with given name and value in the browser. The maximum age is set as provided by the respective
 * parameter, if the parameter max_age_in_hours is specified. Also, the expiration date is set, if provided. If neither
 * the maximum age nor the expiration is provided, the function sets no validity time period. In that case, the cookie
 * is valid only for the current browser session (if the user has not explicitly changed this browser behavior). The
 * value of the cookie is escaped using encodeURI.
 * @param cookie_name Name of cookie to be saved
 * @param value Value of the cookie to be saved
 * @param max_age_in_hours Maximum age is set according to the given maximal age. Specified in hours.
 * @param expiration_date Expiration date.
 */
export function saveCookie(cookie_name: string,
                           value: string,
                           max_age_in_hours?: number,
                           expiration_date?: Date) {
    document.cookie = cookie_name + '=' + encodeURI(value)
        + (max_age_in_hours !== undefined ? ';max-age=max-age-in-seconds=' + (max_age_in_hours * 3600).toString() : '')
        + (expiration_date !== undefined ? ';expires=' + expiration_date.toUTCString() : '')
        + ';samesite=' + (process.env.REACT_APP_DEBUGGING ? 'none': 'strict')
        + ';path=/'
        + ';secure';
}


/**
 * Saves a given token string as cookie in the browser. Prior to saving the token, the token is decoded. If this is
 * not possible no routine quits without saving the cookie. Moreover, it is checked whether the token is a base-token
 * or a cred-token. If this is not the case the routine quits without saving. The validity time span of the token is
 * set according to the lifetimes defined in Settings. For cred-tokens, however, no lifetime is specified, thus leading
 * to the deletion of the cookie once the browser session ends (if the user has not explicitly overruled this browser
 * behavior). No validation of the token is performed.
 * @param cookie_name Name of the cookie to be saved.
 * @param token Token to be saved as a cookie
 */
export function saveTokenAsCookie(cookie_name: string, token: string) {
    const decoded_token_payload = decodeToken(token);
    if (!decoded_token_payload) return;

    if (![TokenTypes.ADMIN_BASE,
        TokenTypes.BASE,
        TokenTypes.OTP_GENERATOR_RESET,
        TokenTypes.PASSWORD_RESET,
        TokenTypes.SIGNUP
    ].includes(decoded_token_payload.typ)) return;

    let expiration_date = decoded_token_payload.exp_date;
    // Credential tokens shall not live longer than current browser session. Therefore, no expiration date and no
    // maximal age is specified for them.
    if ([TokenTypes.OTP_GENERATOR_RESET,
        TokenTypes.PASSWORD_RESET,
        TokenTypes.SIGNUP
    ].includes(decoded_token_payload.typ)) expiration_date = undefined;

    saveCookie(cookie_name, token, undefined, expiration_date)
}

/**
 * Returns the name of the base token. If in debugging mode, it is base-token. In production, it is __Host-base-token.
 * The prefix __Host- enables additional security measures in the browser, however, in debugging mode when running
 * on localhost over http, it is not possible to write this token due to these additional security measures. Therefore,
 * the prefix is not used in debugging mode but only in production.
 * @returns The name of the base token.
 */
export function getBaseTokenName() {
    return process.env.REACT_APP_DEBUGGING ? "base-token" : "__Host-base-token";
}


/**
 * Returns the name of the cred-token. If in debugging mode, it is cred-token. In production, it is __Host-cred-token.
 * The prefix __Host- enables additional security measures in the browser, however, in debugging mode when running
 * on localhost over http, it is not possible to write this token due to these additional security measures. Therefore,
 * the prefix is not used in debugging mode but only in production.
 * @returns The name of the cred-token.
 */
export function getCredTokenName() {
    return process.env.REACT_APP_DEBUGGING ? "cred-token" : "__Host-cred-token";
}


/**
 * Convenience function for writing a base token into the browser calling saveTokenAsCookie with the standard cookie
 * name for base tokens. Also, it is checked whether the given token is indeed marked as a base token. If not, the
 * routine quits without saving the cookie. No validation of the token is performed.
 * @param token Token to be saved.
 */
export function saveBaseTokenAsCookie(token: string) {
    const decoded_token_payload = decodeToken(token);
    if (!decoded_token_payload) return;

    if (![TokenTypes.ADMIN_BASE,
        TokenTypes.BASE
    ].includes(decoded_token_payload.typ)) return;

    removeTokenNonce(true);
    saveTokenAsCookie(getBaseTokenName(), token);
}


/**
 * Convenience function for writing a base token into the browser calling saveTokenAsCookie with the standard cookie
 * name for cred-tokens. Also, it is checked whether the given token is indeed marked as a cred-token. If not, the
 * routine quits without saving the cookie. No validation of the token is performed.
 * @param token Token to be saved.
 */
export function saveCredTokenAsCookie(token: string) {
    const decoded_token_payload = decodeToken(token);
    if (!decoded_token_payload) return;

    if (![TokenTypes.OTP_GENERATOR_RESET,
        TokenTypes.PASSWORD_RESET,
        TokenTypes.SIGNUP
    ].includes(decoded_token_payload.typ)) return;

    removeTokenNonce(false);
    saveTokenAsCookie(getCredTokenName(), token);
}


/**
 * Reads the cookie with the given name. If no cookie with the given name is found, undefined is returned. The cookie
 * is encoded using encodeURI.
 * @param cookie_name The name of the cookie to be returned
 * @returns The value of the cookie encoded using encodeURI, if the cookie is found, undefined otherwise.
 */
export function getCookie(cookie_name: string) {
    const cookie_value = document.cookie.split(
        ";"
    ).find(
        row => row.trim().startsWith(cookie_name + "=")
    )?.split(
        "="
    )[1];

    return cookie_value ? encodeURI(cookie_value) : undefined;
}


/**
 * Convenience function for reading the base token from the browser's cookie storage.
 * @returns The token stored under the standard base-token's cookie name. If not found, undefined is returned.
 */
export function getBaseTokenCookie(): string | undefined {
    return getCookie(getBaseTokenName());
}


/**
 * Convenience function for reading the cred-token from the browser's cookie storage.
 * @returns The token stored under the standard cred-token's cookie name. If not found, undefined is returned.
 */
export function getCredTokenCookie(): string | undefined {
    return getCookie(getCredTokenName());
}


/**
 * Removes the specified token nonce from the browser's session storage.
 * @param is_base_token Indicates the type of the nonce. If true, the base token nonce will be removed, otherwise the
 * cred-token nonce.
 */
export function removeTokenNonce(is_base_token: boolean) {
    sessionStorage.removeItem(is_base_token ? "BaseTokenNonce" : "CredTokenNonce");
}


/**
 * This function returns the token nonce of either the base token or the cred-token depending on the setting of the
 * switch is_base_token. First, it is checked whether the respective token nonce is already in the browser's session
 * storage. If that is the case, the nonce is returned. If not, the underlying token is read from the browser's
 * cookie storage. If not found, undefined is returned. If found, the token is decoded. If not possible, the function
 * returns undefined. Finally, it saves the extracted nonce to the browser's cookie storage and returns the nonce. The
 * nonce is encoded using encodeURI.
 * @param is_base_token Switch indicating whether the nonce of the base token of the cred-token shall be returned.
 * @returns The nonce of either base token or the cred-token as requested through the switch is_base_token, or
 * undefined, if not found.
 * */
export function getTokenNonce(is_base_token: boolean): string | undefined {
    let token_nonce = sessionStorage.getItem(is_base_token ? "BaseTokenNonce" : "CredTokenNonce");
    if (token_nonce) return encodeURI(token_nonce);

    const token = is_base_token ? getBaseTokenCookie() : getCredTokenCookie();
    if (!token) return undefined;

    const decoded_token_payload = decodeToken(token);
    if (!decoded_token_payload) return undefined;

    token_nonce = encodeURI(decoded_token_payload.nonce);
    sessionStorage.setItem(is_base_token ? "BaseTokenNonce" : "CredTokenNonce", token_nonce)

    return token_nonce;
}


/**
 * Convenience function for retrieving the nonce of the base token. Calls getTokenNonce with is_base_token = true.
 * @returns The nonce of the base token, or undefined, if not found.
 */
export function getBaseTokenNonce(): string | undefined {
    return getTokenNonce(true);
}


/**
 * Convenience function for retrieving the nonce of the cred-token. Calls getTokenNonce with is_base_token = false.
 * @returns The nonce of the cred-token, or undefined, if not found.
 */
export function getCredTokenNonce(): string | undefined {
    return getTokenNonce(false);
}


/**
 * Retrieves the XSRF-Token from the browser's local storage, if found. If not found, undefined is returned.
 * @returns The XSRF token, if found, otherwise undefined. The token is encoded using encodeURI.
 */
export function getXSRFToken(): string | undefined {
    let xsrf_token = localStorage.getItem("xsrf_token");
    if (!xsrf_token) return undefined;
    return encodeURI(xsrf_token);
}


/**
 * Stores the given XSRF token in the browser's local storage. The token is encoded using encodeURI.
 * @param xsrf_token The XSRF token to be stored.
 */
export function saveXSRFToken(xsrf_token: string) {
    localStorage.setItem("xsrf_token", encodeURI(xsrf_token));
}


/**
 * Removes the XSRF token from the browser's local storage.
 */
export function removeXSRFToken() {
    localStorage.removeItem("xsrf_token");
}


/**
 * Saves the provided username in the browser's local storage. The username is encoded using encodeURI.
 * @param user_name The username to be stored.
 */
export function saveUserName(user_name: string) {
    localStorage.setItem("user_name", encodeURI(user_name));
}


/**
 * Retrieves the username from the browser's local storage, if found. If not found, undefined is returned.
 * @returns The username, if found, otherwise undefined. The username is encoded using encodeURI.
 */
export function getUserName(): string | undefined {
    const user_name = localStorage.getItem("user_name");
    if (!user_name) return undefined;
    return encodeURI(user_name);
}


/**
 * Saves the current timestamp as last login timestamp in the browser's local storage.
 */
export function saveLastLoginTimestamp() {
    const current_timestamp = new Date();
    localStorage.setItem("last_login_timestamp", current_timestamp.getTime().toString());
}


/**
 * Retrieves the timestamp of the last login from the browser's local storage as a Date object.
 * @returns The Date object holding the timestamp of the last login, if any, otherwise undefined.
 */
export function getLastLoginTimestamp(): Date | undefined {
    const last_login_timestamp_str = localStorage.getItem("last_login_timestamp");
    if (!last_login_timestamp_str) return undefined;
    const last_login_timestamp = Number(last_login_timestamp_str);
    if (!last_login_timestamp || isNaN(last_login_timestamp)) return undefined;
    return new Date(last_login_timestamp);
}


/**
 * Saves the current timestamp as first login timestamp in the browser's local storage, if no such timestamp exists so
 * far.
 */
export function saveFirstLoginTimestamp() {
    if (getFirstLoginTimestamp() === undefined) {
        const current_timestamp = new Date();
        localStorage.setItem("first_login_timestamp", current_timestamp.getTime().toString());
    }
}


/**
 * Retrieves the timestamp of the first login from the browser's local storage as a Date object.
 * @returns The Date object holding the timestamp of the first login, if any, otherwise undefined.
 */
export function getFirstLoginTimestamp(): Date | undefined {
    const first_login_timestamp_str = localStorage.getItem("first_login_timestamp");
    if (!first_login_timestamp_str) return undefined;
    const first_login_timestamp = Number(first_login_timestamp_str);
    if (!first_login_timestamp || isNaN(first_login_timestamp)) return undefined;
    return new Date(first_login_timestamp);
}


/**
 * Connects the API backend servers and delivers the provided login credentials attempting to log in. If login is
 * successful, the server returns a xsrf token, which is then stored in the browser's local storage, as well as the
 * username and login timestamps. Moreover, the routine invokes the feedback message processing callback with a success
 * message. If login is not successful, the latter callback is invoked with the feedback message received from the
 * server.
 * @param fetch_control Fetch controller to establish the connection to the API server
 * @param processFeedbackMessage Callback for processing login success or failure feedback message
 * @param user_name Username to be used for login
 * @param password Password to be used for login
 * @param otp MFA one time password to be used for login
 */
export function login(fetch_control: FetchControl,
                      processFeedbackMessage: CallbackFeedbackMessageProcessing,
                      user_name: string,
                      password: string,
                      otp: number) {
    fetch_control.requestFetchPromise(
        ApiEndPoints.LOGIN,
        HttpMethods.POST,
        {
            'user_name': user_name,
            'password': password,
            'otp': otp}
    ).then(xsrf_token => {
        saveXSRFToken(xsrf_token);
        saveUserName(user_name);
        saveLastLoginTimestamp();
        saveFirstLoginTimestamp();
        const feedback_message: FeedbackMessage = {
            message_type: FeedbackMessageTypes.SUCCESS,
            message_text: getLabelTextMessage(
                LabelTextMessageTypes.LOGIN_SUCCESSFUL,
                getUserLanguage()
            )
        }
        processFeedbackMessage(feedback_message);
    }).catch((feedback_message: FeedbackMessage) => {
        processFeedbackMessage(feedback_message);
    })
}


/**
 * Checks whether a user is currently logged in. If that is the case, the name of the logged-in user is returned.
 * Otherwise, undefined is returned.
 * @returns The name of the logged-in user, if any, or undefined otherwise.
 */
export function getLoggedInUserName(): string | undefined {
    const user_name = getUserName();
    if (!user_name) return undefined;

    const xsrf_token = getXSRFToken();
    if (!xsrf_token) return undefined;

    const decoded_token_payload = decodeToken(xsrf_token);
    if (!decoded_token_payload) return undefined;

    const current_time = new Date();
    if (!decoded_token_payload.exp_date || decoded_token_payload.exp_date < current_time) return undefined;

    return user_name;
}


/**
 * This routine checks whether the user has the given competence. If that is the case, it returns true, otherwise
 * false. This check is performed against the provided user_access_privilege parameter. If the user has
 * Administrator-Rights as a competence, the user is always granted all access privileges.
 * @param user_access_privilege Object of type UserAccessPrivilege holding the user competences to check against.
 * @param competence String naming the competence to check.
 * @returns True if user has this competence.
 */
export function checkUserCompetence(user_access_privilege: UserAccessPrivilege | undefined,
                                    competence: BuiltInPermissionNames): boolean {
    if (!competence) return true;
    if (!user_access_privilege) return false;

    if (user_access_privilege.competences.includes(BuiltInPermissionNames.AdministratorRights)) return true;

    return user_access_privilege.competences.includes(competence);
}


/**
 * This routine checks whether the user has the given tag permission. If that is the case, it returns true, otherwise
 * false. This check is performed against the provided user_access_privilege parameter. If the user has
 * Administrator-Rights as a competence, the user is always granted all access privileges.
 * @param user_access_privilege Object of type UserAccessPrivilege holding the user tag permissions to check against.
 * @param tag_permission String naming the tag permission to check.
 * @returns True if user has this tag permission.
 */
export function checkUserTagPermission(user_access_privilege: UserAccessPrivilege | undefined,
                                       tag_permission: BuiltInPermissionNames): boolean {
    if (!tag_permission) return true;
    if (!user_access_privilege) return false;

    if (user_access_privilege.competences.includes(BuiltInPermissionNames.AdministratorRights)) return true;

    return user_access_privilege.tag_permissions.includes(tag_permission);
}


/**
 * This routine checks whether the user has the given competence / permission as a competence and as a tag permission.
 * If that is the case, it returns true, otherwise false. This check is performed against the provided
 * user_access_privilege parameter. If the user has Administrator-Rights as a competence, the user is always granted
 * all access privileges.
 * @param user_access_privilege Object of type UserAccessPrivilege holding the user competences and tag permissions to
 * check against.
 * @param competence_permission String naming the competence / tag permission to check.
 * @returns True if user has this permission as a competence and as a tag permission.
 */
export function checkUserCompetenceAndTagPermission(user_access_privilege: UserAccessPrivilege | undefined,
                                                    competence_permission: BuiltInPermissionNames): boolean {
    if (!competence_permission) return true;
    if (!user_access_privilege) return false;

    if (user_access_privilege.competences.includes(BuiltInPermissionNames.AdministratorRights)) return true;

    return user_access_privilege.competences.includes(competence_permission)
        && user_access_privilege.tag_permissions.includes(competence_permission);
}


/**
 * This routine checks whether the user is the owner of the currently active model. If that is the case, it
 * returns true, otherwise false. This check is performed against the provided user_access_privilege parameter.
 * @param user_access_privilege Object of type UserAccessPrivilege holding the ownership information to check against.
 * @returns: True if user owns the currently active model, if any.
 */
export function checkUserOwnsModel(user_access_privilege: UserAccessPrivilege | undefined): boolean {
    if (!user_access_privilege) return false;

    return !!user_access_privilege.is_model_owner;
}

