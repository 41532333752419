import React from 'react';
import {
    BodyParameters,
    CallbackAnyParam,
    FeedbackMessage,
    FetchRequest,
    QueryParameters, StandardChoiceInt, StandardChoiceStr,
    UUID
} from "./GeneralInterfaces";
import {FetchControl} from "../utils/FetchControl";
import {
    ApiEndPoints,
    FeedbackMessageTypes,
    HttpMethods,
    StandardChoiceCategoriesInt,
    StandardChoiceCategoriesStr
} from "../Settings";
import {getRequest_get_standard_choices_int} from "../../api/standard_choices_int";
import {getRequest_get_standard_choices_str} from "../../api/standard_choices_str";


/**
 * This interface defines the properties of the class [[`BaseComponent`]]. All classes deriving from this class must
 * provide properties that extend this interface.
 */
export interface BaseComponentProps {
    /** Callback function to trigger the display of user feedback messages on screen. */
    displayMessage: (feedback_message: FeedbackMessage) => void;
}


/**
 * This interface defines the state of the class [[`BaseComponent`]]. All classes deriving from this class must
 * use a state interface that derives from this interface.
 */
export interface BaseComponentState {
    /** Indicates whether there are pending fetch requests */
    pending_fetch_requests: boolean;
    /** Holds standard choices of type int */
    standard_choices_int: Record<StandardChoiceCategoriesInt, Array<StandardChoiceInt>> | undefined;
    /** Holds standard choices of type string */
    standard_choices_str: Record<StandardChoiceCategoriesStr, Array<StandardChoiceStr>> | undefined;
}


/**
 * This constant holds the initialization for all state attributes of the class [[`BaseComponent`]]. It can be used in
 * the child classes for initializing the state.
 */
export const BaseComponentStateInitial: BaseComponentState = {
    /** Initialization for pending fetch request indicator */
    pending_fetch_requests: false,
    /** Initialization for standard choices of type int */
    standard_choices_str: undefined,
    /** Initialization for standard choices of type string */
    standard_choices_int: undefined,
}


/**
 * This class is the base class for all React components in the frontend that need to communicate with the server. Its
 * main purpose is to provide infrastructure for the client-server communication as well as for displaying user
 * feedback messages.
 */
export class BaseComponent<PropsInterface extends BaseComponentProps, StateInterface extends BaseComponentState>
    extends React.Component<PropsInterface, StateInterface> {

    /**
     * Holds the local fetch controller of this React component.
     * @private
     */
    private readonly fetch_control: FetchControl;


    /**
     * Constructor. Initializes the local fetch controller.
     * @param props React properties passed from the calling instance.
     */
    constructor(props: PropsInterface) {
        super(props);

        this.fetch_control = new FetchControl();
    }


    /**
     * This function is called by React automatically directly before the component is unmounted from the DOM tree.
     * It cancels all pending fetch requests as their responses will no longer be processed due to the removal of this
     * component.
     */
    componentWillUnmount() {
        this.fetch_control.cancelAllPendingFetchRequests();
    }


    /**
     * Getter function for providing the base component's fetch controller.
     * @returns The fetch controller of this component.
     */
    getFetchControl(): FetchControl {
        return this.fetch_control;
    }


    /**
     * Sets the state variable pending_fetch_requests according to whether or not there are currently pending fetch
     * requests.
     */
    updatePendingFetchRequestState() {
        this.setState({pending_fetch_requests: this.fetch_control.getNumberPendingFetchRequests() > 0})
    }


    /**
     * Convenience function for issuing an error message that is to be displayed on screen.
     * @param message Message text that is to be displayed on screen.
     */
    displayError(message: string) {
        let feedback_message: FeedbackMessage = {
            message_type: FeedbackMessageTypes.ERROR,
            message_text: message
        }
        this.props.displayMessage(feedback_message);
    }


    /**
     * Convenience function for issuing a warning message that is to be displayed on screen.
     * @param message Message text that is to be displayed on screen.
     */
    displayWarning(message: string) {
        let feedback_message: FeedbackMessage = {
            message_type: FeedbackMessageTypes.WARNING,
            message_text: message
        }
        this.props.displayMessage(feedback_message);
    }


    /**
     * Convenience function for issuing a user info message that is to be displayed on screen.
     * @param message Message text that is to be displayed on screen.
     */
    displayInfo(message: string) {
        let feedback_message: FeedbackMessage = {
            message_type: FeedbackMessageTypes.INFO,
            message_text: message
        }
        this.props.displayMessage(feedback_message);
    }


    /**
     * Convenience function for issuing a user success message that is to be displayed on screen.
     * @param message Message text that is to be displayed on screen.
     */
    displaySuccess(message: string) {
        let feedback_message: FeedbackMessage = {
            message_type: FeedbackMessageTypes.SUCCESS,
            message_text: message
        }
        this.props.displayMessage(feedback_message);
    }


    /**
     * This routine cancels all pending fetch promises in the promise cache of the local fetch controller.
     */
    cancelAllCachedPromises() {
        this.fetch_control.cancelAllPendingFetchRequests();
    }

    /**
     * Convenience function for calling requestFetchPromise of the local fetch controller.
     * @param api_endpoint End-point address of rest API, for instance: /api/login
     * @param query_parameters Optional query-string parameters to be included in request URL, for instance:
     * {model_id: 1}
     * @param rollback_point_id Identifier of the rollback point of the requested data. If specified, the UUID of the
     * rollback point is added to the query string. This is a convenience functionality. Alternatively, the rollback
     * point can also be provided directly in the query_parameters parameter. If not specified or undefined,
     * no rollback point is not added to the query string.
     * @param method HTTP request method to be used in restful API call, for instance: "GET"
     * @param body Optional parameters to be included in the request body of API call, for instance: {selected: true}
     * @param file Optional file to be uploaded along with the restful API call.
     * @returns Returns a Promise object that can be used to process the server response after it has been received.
     * */
    requestFetchPromise(api_endpoint: ApiEndPoints,
                        method: HttpMethods,
                        query_parameters: QueryParameters,
                        rollback_point_id?: UUID,
                        body?: BodyParameters,
                        file?: File): Promise<any> {
        return this.fetch_control.requestFetchPromise(
            api_endpoint,
            method,
            query_parameters,
            rollback_point_id,
            body,
            file)
    }


    /**
     * Convenience function for calling requestFetchPromise adding, however, a processing callback that is called
     * after successfully receiving the server response for handling its processing. Also, exceptions are automatically
     * passed to the component's displayMessage function. The parameters necessary for triggering the api-call through
     * the routine requestFetchPromise are conveniently embedded here in a FetchRequest object passed as parameter.
     *
     * @param fetch_request Holds the parameters necessary for triggering the api call
     * @param processing_callback Callback for processing the successful server response
     */
    processFetchRequest(fetch_request: FetchRequest, processing_callback: CallbackAnyParam) {
        this.requestFetchPromise(
            fetch_request.api_endpoint,
            fetch_request.method,
            fetch_request.query_parameters,
            fetch_request.rollback_point_id,
            fetch_request.body,
            fetch_request.file
        ).then(value => {
            this.updatePendingFetchRequestState();
            processing_callback(value)
        }).catch(feedback_message => {
            this.updatePendingFetchRequestState();
            this.props.displayMessage(feedback_message);
        })
        this.updatePendingFetchRequestState();
    }


    /**
     * Triggers load requests to receive the indicated standard choices (of type int) from the server. The obtained
     * results are stored in the state variable standard_choices_int.
     * @param categories An array if standard choice categories to be loaded from the server.
     */
    load_standard_choices_int(categories: Array<StandardChoiceCategoriesInt>) {
        categories.forEach(cat => {
            let empty_obj: Record<string, any> = {}
            Object.keys(StandardChoiceCategoriesInt).forEach(k => {empty_obj[k] = undefined })

            this.processFetchRequest(
                getRequest_get_standard_choices_int(cat),
                (data) => {
                        this.setState((state) => {
                            let new_standard_choices_int: Record<StandardChoiceCategoriesInt, Array<StandardChoiceInt>> =
                                state.standard_choices_int ? state.standard_choices_int : empty_obj;
                            new_standard_choices_int[cat] = data;
                            return({standard_choices_int: new_standard_choices_int})
                        })
                })
        })
    }


    /**
     * Triggers load requests to receive the indicated standard choices (of type string) from the server. The obtained
     * results are stored in the state variable standard_choices_str.
     * @param categories An array if standard choice categories to be loaded from the server.
     */
    load_standard_choices_str(categories: Array<StandardChoiceCategoriesStr>) {
        categories.forEach(cat => {
            let empty_obj: Record<string, any> = {}
            Object.keys(StandardChoiceCategoriesStr).forEach(k => {empty_obj[k] = undefined })

            this.processFetchRequest(
                getRequest_get_standard_choices_str(cat),
                (data) => {
                        this.setState((state) => {
                            let new_standard_choices_str: Record<StandardChoiceCategoriesStr, Array<StandardChoiceStr>> =
                                state.standard_choices_str ? state.standard_choices_str : empty_obj;
                            new_standard_choices_str[cat] = data;
                            return({standard_choices_str: new_standard_choices_str})
                        })
                })
        })
    }

}
