import React from 'react';


import './MainFrame.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import {MdLogin, MdPersonAdd} from "react-icons/md";
import {Route, Routes} from "react-router-dom";


import {
    BaseComponent,
    BaseComponentProps,
    BaseComponentState,
    BaseComponentStateInitial
} from "./base/ui_general/BaseComponent";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {
    getLabelTextMessage,
    getQueryString,
    getURLPath,
    getUserLanguage,
    saveBaseTokenFromUrlAsCookie,
    setUserLanguage
} from "./base/utils/GeneralUtilities";
import {FeedbackMessage, FeedbackMessageDispatch, UserAccessPrivilege, UUID} from "./base/ui_general/GeneralInterfaces";
import {boundMethod} from "autobind-decorator";
import {getBaseTokenCookie, getLoggedInUserName, getUserName, login} from "./base/utils/Security";
import {RBSModal} from "./base/rbs_components/RBSModal";
import {
    FeedbackMessageDisplayTimes,
    FeedbackMessageTypes,
    FrontendURLPaths,
    LabelTextMessageTypes,
    TextfieldInputTypes,
    UserLanguages,
    ValidationSchemes
} from "./base/Settings";
import {RBSTextField} from "./base/rbs_components/RBSTextField";
import {InputStateControl} from "./base/utils/InputStateControl";
import {ModelSelectionOverView} from "./model_selection/ModelSelectionOverView";
import {LandingPageOverView} from "./landing_page/LandingPageOverView";
import {FileUploadOverView} from "./file_upload/FileUploadOverView";
import {RDSConstructionOverView} from "./rds_construction/RDSConstructionOverView";
import {UnivariateAnalysisOverView} from "./univariate_analysis/UnivariateAnalysisOverView";
import {MultivariateAnalysisOverView} from "./mulitvariate_analysis/MultivariateAnalysisOverView";


/**
 * The properties of this Main Frame require a navigate function from the react-router-dom to allow navigating
 * on the site.
 */
interface MainFrameProps extends BaseComponentProps {
    /** Allows navigating within the react-router-dom without reloading the website. */
    navigate: NavigateFunction;
}


/**
 * The state of the Main Frame holds a series of local variables relevant for steering user interaction.
 */
interface MainFrameState extends BaseComponentState {
    /** Holds the access privileges of the logged-in user */
    user_access_privilege: UserAccessPrivilege | undefined;
    /** Boolean indicating whether the login modal shall be displayed on screen */
    show_login_modal: boolean;
    /** Indicates whether the login button of the login modal is enabled or disabled */
    login_modal_valid: boolean;
    /** Indicates the preferred user language. This state variable reflects the user language setting in the local
     * memory. All textual user feedback shall be provided in this indicated language. When changing this language
     * setting, all system specific texts displayed on screen shall be adapted. */
    user_language: UserLanguages;
    /** This list holds feedback messages that are to be displayed on screen */
    message_dispatch_list: Array<FeedbackMessageDispatch>;
//    derived_url_extension: string;
}


export class MainFrame extends BaseComponent<MainFrameProps, MainFrameState> {
    /** Holds the input state controller for the user login */
    private readonly loginInputStateControl: InputStateControl;


    /**
     * Initializes the state and the input state controller for the login.
     * @param props React properties of this component
     */
    constructor(props: MainFrameProps) {
        super(props);

        this.state = {
            ...BaseComponentStateInitial,
            user_access_privilege: undefined,
            show_login_modal: getURLPath() === FrontendURLPaths.LOGIN && !getLoggedInUserName(),
            user_language: getUserLanguage(),
            login_modal_valid: false,
            message_dispatch_list: []
        };

        this.loginInputStateControl = new InputStateControl();
    }


    /**
     * Is called from React after this component has been created, rendered and mounted to the DOM tree. Extracts the
     * base token from the browser's URL field (if any) and saves it as cookie in the browser's cookie storage. Also,
     * sets the callback to receive validity updates of the data entered into the login form.
     */
    componentDidMount() {
        saveBaseTokenFromUrlAsCookie();
        this.loginInputStateControl.setGlobalValidityUpdateCallback(this.loginModalValidityUpdate);
    }


    /**
     * Is called from React immediately before component will be destroyed and unmounted from the DOM tree.
     */
    componentWillUnmount() {
    }


    /**
     * This function removes all feedback message from the feedback message dispatch list which are older than the
     * corresponding indicated display-until timestamp.
     */
    removeExpiredFeedbackMessages() {
        const current_time = new Date();
        if (this.state.message_dispatch_list.filter(
            msg => msg.display_until_timeStamp < current_time
        ).length > 0) {
            this.setState(state => {
                return {
                    message_dispatch_list: state.message_dispatch_list.filter(
                        msg => msg.display_until_timeStamp >= current_time
                    )
                }}
            )
        }
    }

    /**
     * This function is used as a callback function distributed everywhere in the system to allow the display of user
     * feedback on screen. It adds the provided feedback message to the feedback message dispatch list.
     * @param feedback_message Feedback message to be displayed on screen.
     */
    @boundMethod
    displayMessage(feedback_message: FeedbackMessage) {
        const current_time = new Date();
        const type_key = Object.keys(FeedbackMessageDisplayTimes).find(key => {
            return FeedbackMessageTypes[key as keyof typeof FeedbackMessageTypes] === feedback_message.message_type
        })
        if (type_key) {
            const display_time = FeedbackMessageDisplayTimes[
                type_key as keyof typeof FeedbackMessageDisplayTimes];

            this.setState(state => {
                return {
                    message_dispatch_list: [
                        ...state.message_dispatch_list,
                        {
                            ref: 'Feedback-Message [' + current_time + ']: ' + feedback_message.message_text,
                            feedback_message: feedback_message,
                            dispatch_timestamp: current_time,
                            display_time: display_time,
                            display_until_timeStamp: new Date(current_time.getTime() + 1000 * display_time)
                        }]
                }
            })
        }
    }


    /**
     * Callback function passed to all views. Allows receiving model id and rollback point id resolved by individual
     * view from query string parameters and possibly further API calls for resolution purposes.
     * @param risk_model_id UUID of the currently selected model
     * @param rollback_point_id UUID of the currently selected rollback point
     */
    @boundMethod
    provideModelIDs(risk_model_id: UUID | undefined, rollback_point_id: UUID | undefined) {

    }


    /**
     * Callback for receiving validity state of the login modal.
     * @param valid If true, the login modal contains valid data to trigger a login attempt.
     */
    @boundMethod
    loginModalValidityUpdate(valid: boolean) {
        this.setState({
            login_modal_valid: valid
        })
    }


    /**
     * Renders the login modal querying the user credentials for login.
     * @returns An React.JSX.Element providing the login modal.
     */
    renderLoginModal(): React.JSX.Element {
        return <RBSModal
            header_title={getLabelTextMessage(LabelTextMessageTypes.LOGIN_HEADER, this.state.user_language)}
            show={this.state.show_login_modal}
            primary_button_label={getLabelTextMessage(
                LabelTextMessageTypes.LOGIN_BUTTON_LABEL,
                this.state.user_language
            )}
            primary_button_disabled={!this.state.login_modal_valid}
            onClickPrimary={() => {
                const user_name = this.loginInputStateControl.getStringValueForKey(
                    'user_name'
                );
                const password = this.loginInputStateControl.getStringValueForKey(
                    'password'
                );
                const otp = this.loginInputStateControl.getStringValueForKey(
                    'otp'
                );

                try {
                    if (user_name && password && otp) login(
                        this.getFetchControl(),
                        this.displayMessage,
                        user_name,
                        password,
                        Number(otp)
                    );
                }
                catch(error) {
                    this.displayMessage(error as FeedbackMessage);
                }

                this.setState({show_login_modal: false})
            }}
            onClose={() => this.setState({show_login_modal: false})}
            static_modal={true}
        >
            <RBSTextField
                label={'Username'}
                input_key={'user_name'}
                prefill={getUserName()}
                input_state_control={this.loginInputStateControl}
                validation_schemes={[ValidationSchemes.REQUIRED]}
            />

            <RBSTextField
                label={'Password'}
                input_key={'password'}
                input_state_control={this.loginInputStateControl}
                validation_schemes={[ValidationSchemes.REQUIRED]}
                type={TextfieldInputTypes.PASSWORD}
            />

            <RBSTextField
                label={'MFA One Time Password'}
                input_key={'otp'}
                input_state_control={this.loginInputStateControl}
                validation_schemes={[
                    ValidationSchemes.REQUIRED,
                    ValidationSchemes.INTEGER,
                    ValidationSchemes.NON_NEGATIVE,
                    ValidationSchemes.MIN_LENGTH_6,
                    ValidationSchemes.MAX_LENGTH_6
                ]}
                type={TextfieldInputTypes.NUMBER}
            />

        </RBSModal>;
    }


    /**
     * Renders a toast container holding toasts for each feedback message in the feedback message dispatch list.
     * @returns An React.JSX.Element providing the toast container holding the feedback messages.
     */
    renderFeedbackMessageToasts(): React.JSX.Element {
        const current_time = new Date();

        return (
            <ToastContainer
                className="p-3"
                position='bottom-center'
                style={{ zIndex: 1 }}
            >
                {this.state.message_dispatch_list.map(msg_disp => (
                    <Toast
                        key={msg_disp.ref}
                        onClose={() => {
                            this.setState(state => {
                                return {
                                    message_dispatch_list: state.message_dispatch_list.filter(
                                        msg => msg.ref !== msg_disp.ref
                                    )
                                }
                            })
                        }}
                        show={true}
                        delay={msg_disp.display_time * 1000}
                        autohide
                        bg={msg_disp.feedback_message.message_type === FeedbackMessageTypes.ERROR ? 'danger' :
                            (msg_disp.feedback_message.message_type === FeedbackMessageTypes.WARNING ? 'warning' :
                                (msg_disp.feedback_message.message_type === FeedbackMessageTypes.SUCCESS ? 'success' :
                                'info'))}
                    >
                        <Toast.Header>
                            <strong className="me-auto">{msg_disp.feedback_message.message_type}</strong>
                            <small>
                                {
                                    current_time.getTime() - msg_disp.dispatch_timestamp.getTime() < 1000 ? 'Now' :
                                        Math.round(
                                            (current_time.getTime() - msg_disp.dispatch_timestamp.getTime()) / 1000
                                        ) + ' seconds ago'
                                }
                            </small>
                        </Toast.Header>
                        <Toast.Body className={'text-white'}>{msg_disp.feedback_message.message_text}</Toast.Body>
                    </Toast>
                ))}
            </ToastContainer>
        )
    }


    /**
     * Renders the header bar of the main frame.
     * @returns An React.JSX.Element providing the header bar.
     */
    renderHeaderBar(): React.JSX.Element {
        const base_token_cookie_available = getBaseTokenCookie() !== undefined;
        const logged_in_user_name = getLoggedInUserName();

        return (
            <Navbar style={{backgroundImage: "linear-gradient(to right, white, darkblue)"}}>
                <Container fluid>
                    <Navbar.Brand href="https://www.d-fine.com/">
                        <img
                            alt=""
                            src="/d-fine_logo-blue_RGB.svg"
                            width="100"
                            height="30"
                            className="d-inline-block align-top"
                        /> {'     '}
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="navbarScroll" />

                    <Navbar.Text style={{position: "relative", top: 9}}>
                        <h4> CRS Model Builder </h4>
                    </Navbar.Text>


                    <Navbar.Collapse id="navbarScroll" style={{paddingTop: 12, paddingLeft: 20}}>

                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link href="#action1" onClick={() => {
                                this.props.navigate(FrontendURLPaths.LANDING_PAGE, { replace: false });
                            }}>
                                {getLabelTextMessage(LabelTextMessageTypes.HOME, this.state.user_language)}
                            </Nav.Link>
                            <NavDropdown
                                title={getLabelTextMessage(LabelTextMessageTypes.LANGUAGE, this.state.user_language)}
                                id="navbarScrollingDropdown"
                            >
                                {/* English */}
                                <NavDropdown.Item href="#action3" onClick={() => {
                                    setUserLanguage(UserLanguages.EN);
                                    this.setState({user_language: getUserLanguage()})
                                }
                                }>
                                    {getLabelTextMessage(LabelTextMessageTypes.ENGLISH, this.state.user_language)}
                                </NavDropdown.Item>

                                {/* German */}
                                <NavDropdown.Item href="#action3" onClick={() => {
                                    setUserLanguage(UserLanguages.DE);
                                    this.setState({user_language: getUserLanguage()})
                                }
                                }>
                                    {getLabelTextMessage(LabelTextMessageTypes.GERMAN, this.state.user_language)}
                                </NavDropdown.Item>

                                {/* Spanish */}
                                <NavDropdown.Item href="#action3" onClick={() => {
                                    setUserLanguage(UserLanguages.ES);
                                    this.setState({user_language: getUserLanguage()})
                                }
                                }>
                                    {getLabelTextMessage(LabelTextMessageTypes.SPANISH, this.state.user_language)}
                                </NavDropdown.Item>

                                {/* French */}
                                <NavDropdown.Item href="#action3" onClick={() => {
                                    setUserLanguage(UserLanguages.FR);
                                    this.setState({user_language: getUserLanguage()})
                                }
                                }>
                                    {getLabelTextMessage(LabelTextMessageTypes.FRENCH, this.state.user_language)}
                                </NavDropdown.Item>

                            </NavDropdown>
                            <Nav.Link href="#action2">
                                {getLabelTextMessage(LabelTextMessageTypes.CONTACTS, this.state.user_language)}
                            </Nav.Link>
                        </Nav>

                        {base_token_cookie_available ?
                            (logged_in_user_name ?
                                    <span className={'text-white'}> {"Logged in as: " + logged_in_user_name} </span> :
                                    <Nav.Link
                                        href="#action1"
                                        onClick={() => {this.setState({show_login_modal: true})}}
                                        style={{paddingLeft: 10, color: 'white'}}
                                    >
                                        <MdLogin size={24}/>
                                        {getLabelTextMessage(LabelTextMessageTypes.LOGIN, this.state.user_language)}
                                    </Nav.Link>
                            ) :
                            <Nav.Link
                                href="#action1"
                                style={{paddingLeft: 10, color: 'white'}}
                            >
                                <MdPersonAdd size={24}/>
                                Sign Up
                            </Nav.Link>
                        }
                    </Navbar.Collapse>

                </Container>
            </Navbar>
        )
    }


    renderScreenRoutes(): React.JSX.Element {
        const query_string = getQueryString();

        return (
            <Routes>
                <Route path={FrontendURLPaths.MODEL_SELECTION}
                       element={<ModelSelectionOverView
                           key={"ModelSelectionOverView" + query_string}
                           navigate={this.props.navigate}
                           provideModelIDs={this.provideModelIDs}
                           displayMessage={this.displayMessage}
                           user_access_privilege={this.state.user_access_privilege}
                           user_language={this.state.user_language}
                       />}
                />
                <Route path={FrontendURLPaths.LANDING_PAGE}
                       element={<LandingPageOverView
                           key={"LandingPageOverView" + query_string}
                           navigate={this.props.navigate}
                           provideModelIDs={this.provideModelIDs}
                           displayMessage={this.displayMessage}
                           user_access_privilege={this.state.user_access_privilege}
                           user_language={this.state.user_language}
                       />}
                />
                <Route path={FrontendURLPaths.LOGIN}
                       element={<LandingPageOverView
                           key={"LandingPageOverView_OpenLoginDialog" + query_string}
                           navigate={this.props.navigate}
                           provideModelIDs={this.provideModelIDs}
                           displayMessage={this.displayMessage}
                           user_access_privilege={this.state.user_access_privilege}
                           user_language={this.state.user_language}
                       />}
                />
                <Route path={FrontendURLPaths.FILE_UPLOAD}
                       element={<FileUploadOverView
                           key={"FileUploadOverView" + query_string}
                           navigate={this.props.navigate}
                           provideModelIDs={this.provideModelIDs}
                           displayMessage={this.displayMessage}
                           user_access_privilege={this.state.user_access_privilege}
                           user_language={this.state.user_language}
                       />}
                />
                <Route path={FrontendURLPaths.RDS_CONSTRUCTION}
                       element={<RDSConstructionOverView
                           key={"RDSConstructionOverView" + query_string}
                           navigate={this.props.navigate}
                           provideModelIDs={this.provideModelIDs}
                           displayMessage={this.displayMessage}
                           user_access_privilege={this.state.user_access_privilege}
                           user_language={this.state.user_language}
                       />}
                />
                <Route path={FrontendURLPaths.UNIVARIATE_ANALYSIS}
                       element={<UnivariateAnalysisOverView
                           key={"UnivariateAnalysisOverView" + query_string}
                           navigate={this.props.navigate}
                           provideModelIDs={this.provideModelIDs}
                           displayMessage={this.displayMessage}
                           user_access_privilege={this.state.user_access_privilege}
                           user_language={this.state.user_language}
                       />}
                />
                <Route path={FrontendURLPaths.MULTIVARIATE_ANALYSIS}
                       element={<MultivariateAnalysisOverView
                           key={"MultivariateAnalysisOverView" + query_string}
                           navigate={this.props.navigate}
                           provideModelIDs={this.provideModelIDs}
                           displayMessage={this.displayMessage}
                           user_access_privilege={this.state.user_access_privilege}
                           user_language={this.state.user_language}
                       />}
                />
            </Routes>
        );
    }



    /**
     * Renders the main frame.
     * @returns An React.JSX.Element providing the main frame.
     */
    render() {
        const logged_in_user_name = getLoggedInUserName();
        this.removeExpiredFeedbackMessages();

        return (
            <React.Fragment>
                {this.renderHeaderBar()}

                {this.renderLoginModal()}

                {this.renderFeedbackMessageToasts()}

                <Container fluid>
                    {logged_in_user_name ? this.renderScreenRoutes() : undefined}
                </Container>
            </React.Fragment>
        );
    }
}
