/**
 * This module provides functions in relation to calling API routes on the backend server and processing the incoming
 * responses. The functions in this module refer to the API endpoint ApiEndPoints.RISK_MODEL_FILE_UPLOAD.
 * @module
 */

import {FetchRequest, UUID} from "../base/ui_general/GeneralInterfaces";
import {ApiEndPoints, HttpMethods} from "../base/Settings";


/**
 * Creates a FetchRequest object populated with the parameters necessary for triggering the upload of a file into
 * the specified risk model.
 * @param name User defined name of the file upload - not necessarily the file name. Could be something like
 * 'main data file'.
 * @param description Description of the file upload.
 * @param risk_model_id UUID of the risk model into which the file shall be uploaded
 * @param file The file to upload
 * @returns A FetchRequest object containing the generated fetch request.*/
export function getRequest_upload_file_to_risk_model(name: string,
                                                     description: string,
                                                     risk_model_id: UUID,
                                                     file: File): FetchRequest {
    return {
        api_endpoint: ApiEndPoints.RISK_MODEL_FILE_UPLOAD,
        method: HttpMethods.PUT,
        query_parameters: {
            'risk_model_id': risk_model_id,
            'name': name,
            'description': description
        },
        file: file
    }
}


/**
 * Creates a FetchRequest object populated with the parameters necessary for querying the file uploads related to the
 * specified risk model on the server.
 * @param risk_model_id UUID of the risk model to query. */
export function getRequest_get_risk_model_file_uploads(risk_model_id: UUID): FetchRequest {
    return {
        api_endpoint: ApiEndPoints.RISK_MODEL_FILE_UPLOAD,
        method: HttpMethods.GET,
        query_parameters: {
            'risk_model_id': risk_model_id,
        },
    }
}
