/**
 * This module provides functions in relation to calling API routes on the backend server and processing the incoming
 * responses. The functions in this module refer to the API endpoint ApiEndPoints.RISK_MODEL_RDS_DEFINITION.
 * @module
 */

import {FetchRequest, QueryParameters, UUID} from "../base/ui_general/GeneralInterfaces";
import {ApiEndPoints, HttpMethods, InputVariableTypes, TargetVariableTypes} from "../base/Settings";


/**
 * Creates a FetchRequest object populated with the parameters necessary for querying the rds definitions related to the
 * specified risk model on the server.
 * @param risk_model_id UUID of the risk model to query.
 * @returns A FetchRequest object containing the generated fetch request.*/
export function getRequest_get_rds_definitions(risk_model_id: UUID): FetchRequest {
    return {
        api_endpoint: ApiEndPoints.RISK_MODEL_RDS_DEFINITION,
        method: HttpMethods.GET,
        query_parameters: {
            'risk_model_id': risk_model_id,
        },
    }
}


/**
 * Creates a FetchRequest object populated with the parameters necessary for creating a new rds definition related to
 * the specified file column mapping in the risk model on the server. *
 * @param file_column_mapping_id UUID of the file column mapping the new rds definition shall refer to.
 * @param input_variable_type The variable type (in case of input variable) of the new rds definition entry.
 * @param target_variable_type The variable type (in case of target variable) of the new rds definition entry.
 * @returns A FetchRequest object containing the generated fetch request.*/
export function getRequest_create_rds_definition(file_column_mapping_id: UUID,
                                                 input_variable_type?: InputVariableTypes,
                                                 target_variable_type?: TargetVariableTypes): FetchRequest {
    let query_parameters: QueryParameters = {
        'file_column_mapping_id': file_column_mapping_id,
    }
    if (input_variable_type) query_parameters = {...query_parameters, 'input_variable_type': input_variable_type}
    if (target_variable_type) query_parameters = {...query_parameters, 'target_variable_type': target_variable_type}

    return {
        api_endpoint: ApiEndPoints.RISK_MODEL_RDS_DEFINITION,
        method: HttpMethods.PUT,
        query_parameters: query_parameters,
    }
}


/**
 * Creates a FetchRequest object populated with the parameters necessary for updating an existing rds definition
 * in the risk model on the server. *
 * @param rds_definition_id UUID of the existing rds definition to be updated.
 * @param input_variable_type The variable type (in case of input variable) of the new rds definition entry.
 * @param target_variable_type The variable type (in case of target variable) of the new rds definition entry.
 * @returns A FetchRequest object containing the generated fetch request.*/
export function getRequest_update_rds_definition(rds_definition_id: UUID,
                                                 input_variable_type?: InputVariableTypes,
                                                 target_variable_type?: TargetVariableTypes): FetchRequest {
    let query_parameters: QueryParameters = {
        'rds_definition_id': rds_definition_id,
    }
    if (input_variable_type) query_parameters = {...query_parameters, 'input_variable_type': input_variable_type}
    if (target_variable_type) query_parameters = {...query_parameters, 'target_variable_type': target_variable_type}

    return {
        api_endpoint: ApiEndPoints.RISK_MODEL_RDS_DEFINITION,
        method: HttpMethods.POST,
        query_parameters: query_parameters,
    }
}