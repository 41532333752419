import React from 'react';
import Dropzone from "react-dropzone";
import {CallbackFileArrayProcessing} from "../ui_general/GeneralInterfaces";


/**
 * Defines the properties of the File-Drop-Zone component.
 */
interface FileDropZoneProps {
    /** Callback function invoked when files are dropped on the drop-zone. */
    onDrop?: CallbackFileArrayProcessing;
    /** The drop-zone's children to be included in the drop-zone's body */
    children?: React.ReactNode;
}


/**
 * Defines the state of the File-Drop-Zone component.
 */
interface FileDropZoneState {
}


/**
 * This class is a wrapper of the File-Drop-Zone component, which allows to upload files either via drag-and-drop or
 * by browsing to the desired file in a pop-up dialog.
 */
export class FileDropZone extends React.Component<FileDropZoneProps, FileDropZoneState> {

    /**
     * Constructor. Initializes states.
     * @param props React properties passed from the calling instance.
     */
    constructor(props: FileDropZoneProps) {
        super(props);

        this.state = {
        };
    }


    /**
     * This routine is called from React whenever the component needs to be re-rendered. It renders the component on the
     * basis of the current state and properties.
     */
    render() {
        return (
            <Dropzone
                onDrop={this.props.onDrop}
            >
                {({getRootProps, getInputProps}) => (
                    <div  {...getRootProps()}>
                        <input {...getInputProps()} />
                        {this.props.children}
                    </div>
                )}
            </Dropzone>
        );
    }
}
