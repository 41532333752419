/**
 * This module provides functions in relation to calling API routes on the backend server and processing the incoming
 * responses. The functions in this module refer to the API endpoint ApiEndPoints.RISK_MODEL_FILE_COLUMN_MAPPING.
 * @module
 */

import {FetchRequest, UUID} from "../base/ui_general/GeneralInterfaces";
import {ApiEndPoints, HttpMethods} from "../base/Settings";


/**
 * Creates a FetchRequest object populated with the parameters necessary for querying the file column mappings of the
 * specified risk model on the server.
 * @param risk_model_id UUID of the risk model to query.
 * @returns A FetchRequest object containing the generated fetch request.*/
export function getRequest_get_risk_model_file_column_mapping(risk_model_id: UUID): FetchRequest {
    return {
        api_endpoint: ApiEndPoints.RISK_MODEL_FILE_COLUMN_MAPPING,
        method: HttpMethods.GET,
        query_parameters: {
            'risk_model_id': risk_model_id,
        },
    }
}