/**
 * This module provides functions in relation to calling API routes on the backend server and processing the incoming
 * responses. The functions in this module refer to the API endpoint ApiEndPoints.STANDARD_CHOICES_STR.
 * @module
 */

import {ApiEndPoints, HttpMethods, StandardChoiceCategoriesStr} from "../base/Settings";
import {FetchRequest} from "../base/ui_general/GeneralInterfaces";


/**
 * Creates a FetchRequest object populated with the parameters necessary for receiving the standard choices of type str
 * available on the server in the specified category of choices.
 * @param enum_name Name of the enumerator holding the standard choices.
 * @returns A FetchRequest object containing the generated fetch request.*/
export function getRequest_get_standard_choices_str(enum_name: StandardChoiceCategoriesStr): FetchRequest {
    return {
        api_endpoint: ApiEndPoints.STANDARD_CHOICES_STR,
        method: HttpMethods.GET,
        query_parameters: {
            'enum_name': enum_name
        },
    }
}